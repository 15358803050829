<template>
  <div>
    <van-empty
      class="custom-image"
      :image="imageUrl"
      :description="$t('noData')"
    />
  </div>
</template>

<script>
/*
 *@Date: 2022-09-30 16:14:19
 *@Description: 模块描述
 */
export default {
  name: '',
  props: {
    imageUrl: {
      Type: String,
      default: require('@/assets/yacht_nodata.svg'),
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/.van-empty__image {
  width: 200px;
  height: 100%;
  margin-left: 42px;
}
/deep/.van-empty__description {
  margin-top: 0;
}
</style>
