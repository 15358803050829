import { getNewsList, getTopNewsList, getNewsDetail } from '@/api';
import { success, fail, failMsg } from '@/libs/util';
const state = {
  newsList: {},
  newsTopList: [],
  newsDetail: {},
};

const actions = {
  async getNewsList({ commit }, context) {
    try {
      let data = await getNewsList(context);
      commit('GETNEWSLIST', data.data);
    } catch (error) {
      failMsg(error);
      return Promise.reject();
    }
  },
  async getTopNewsList({ commit }, context) {
    try {
      let data = await getTopNewsList(context);
      commit('GETTOPNEWSLIST', data.data);
    } catch (error) {
      failMsg(error);
      return Promise.reject();
    }
  },
  async getNewsDetail({ commit }, context) {
    try {
      let data = await getNewsDetail(context);
      commit('GETNEWSDETAIL', data.data);
    } catch (error) {
      failMsg(error);
      return Promise.reject();
    }
  },
};

const mutations = {
  GETNEWSLIST(state, params) {
    state.newsList = params;
  },
  GETTOPNEWSLIST(state, params) {
    state.newsTopList = params;
  },
  GETNEWSDETAIL(state, params) {
    state.newsDetail = params;
  },
};

export default {
  state,
  actions,
  mutations,
};
