import { addYachtsOrder, yachtsDetail, calcYachtOrder } from '@/api';
import { success, fail, failMsg } from '@/libs/util';
import { Toast } from 'vant';
const state = {
  payInfo: {},
  // 游艇详情
  yachtsDetail: {},
  // 菜单数据
  yachtsMenu: [],
  // 游艇玩具
  yachtsToys: [],
  // 水上运动
  waterSports: [],
  // 计算游艇金额
  calcYachtOrderTotal: {},
};

const actions = {
  // 创建游艇订单
  async addYachtsOrder({ commit }, context) {
    try {
      Toast.loading({ duration: 0 });
      let data = await addYachtsOrder(context);
      commit('ADDYACHTSORDER', data);
      if (data.result) {
        location.href = data.data;
        Toast.clear();
        return Promise.resolve();
      }
    } catch (error) {
      failMsg(error);
      return new Promise.reject();
    }
  },
  // 根据productId获取游艇的详情
  async getYachtsDetail({ commit }, context) {
    let data = await yachtsDetail(context);
    // 菜单
    let result = data.data.cateringComboPackages;

    // 游艇玩具
    let result2 = data.data.specialServicePackages.filter(
      (item) => item.specialServiceType == 2
    );

    // 水上运动
    let result1 = data.data.specialServicePackages.filter(
      (item) => item.specialServiceType == 1
    );
    commit(
      'getYachtsToys',
      result2.map((item) => {
        return {
          ...item,
          checked: false,
          number: item.defaultNumber ? item.defaultNumber : 1,
        };
      })
    );
    commit(
      'getYachtsMenu',
      result.map((item) => {
        return {
          ...item,
          checked: false,
          number: item.minNumber ? item.minNumber : 1,
        };
      })
    );
    commit(
      'getWaterSports',
      result1.map((item) => {
        return {
          ...item,
          checked: false,
          number: item.lockNumber
            ? item.defaultNumber
            : item.minNumber
            ? item.minNumber
            : 1,
        };
      })
    );
    commit('getYachtsDetailById', data);
  },
  // 计算游艇金额
  async calcYachtOrderTotal({ commit }, context) {
    try {
      let data = await calcYachtOrder(context);
      commit('CALCYACHTORDER', data.data);
    } catch (error) {
      // console.log(error);
    }
  },
};

const mutations = {
  ADDYACHTSORDER(state, params) {
    state.payInfo = params;
  },
  // 修改state里的游艇详情信息数据
  getYachtsDetailById(state, params) {
    state.yachtsDetail = params;
  },
  getYachtsMenu(state, params) {
    state.yachtsMenu = params;
  },
  getYachtsToys(state, params) {
    state.yachtsToys = params;
  },
  getWaterSports(state, params) {
    state.waterSports = params;
  },
  CALCYACHTORDER(state, params) {
    state.calcYachtOrderTotal = params;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
