/* eslint-disable no-unused-vars */
import { rentalYachtsList } from "@/api/index"
import { Toast } from "vant"
const state = {
    yacthsList: [],
    userOrderInfo: JSON.parse(localStorage.getItem("userOrderInfo")) || {},
}

const actions = {
    // 获取整租游艇列表
    async getRentalYachtsList({ commit }, context) {
        Toast.loading({ forbidClick: true })
        let data = await rentalYachtsList(context)

        // 提取media 组成一个url数组 --- 用于图片预览
        data.data.forEach(item => {
            item.mediaArr = []
            if (item.media instanceof Array) {
                item.media.forEach(citem => {
                    if (citem.mediaType === 1) {
                        item.mediaArr.push(citem.mediaUrl)
                    }
                })
            }
        })
        Toast.clear()
        commit("GETRENTALYACHTSLIST", data.data)
    },
}

const mutations = {
    // 修改state里的整租游艇列表数据
    GETRENTALYACHTSLIST(state, params) {
        state.yacthsList = params
        // console.log(state.yacthsList);
    },
    // 修改state里的用户信息数据
    getUserOrderInfo(state, params) {
        state.userOrderInfo = params
        localStorage.setItem("userOrderInfo", JSON.stringify(params))
    },
    // 页面销毁时移除本地存储数据
    removeUserInfo(state) {
        // localStorage.removeItem('userOrderInfo');
        state.userOrderInfo = {}
    },
}

const getters = {}

export default {
    state,
    actions,
    mutations,
    getters,
}
