import { check_Mail, checkAllNull, check_Number } from "@/libs/form"
import Empty from "@/components/Empty"
import areaCode from "@/components/myPick"
import { mapState } from "vuex"
import { ImagePreview } from "vant"
const cardList = {
    name: "swiper-example-fade-effect",
    title: "Fade effect",
    data() {
        return {
            msgShow: false,
            swiperOption: {
                spaceBetween: 30,
                effect: "fade",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    bulletActiveClass: "my-bullet-active",
                    bulletClass: "my-bullet", //需设置.my-bullet样式
                },
                lazy: {
                    loadPrevNext: false,
                },
            },
            show: {
                areaCode: false,
            },
            name: "",
            phone: "",
            email: "",
            adult: "",
            child: "",
            qz: "",
            product: {},
        }
    },
    components: {
        Empty,
        areaCode,
    },
    methods: {
        bookShow(product) {
            this.product = product
            this.msgShow = true
        },
        close() {
            this.name = ""
            this.phone = ""
            this.email = ""
            this.adult = ""
            this.child = ""
            this.qz = ""
        },
        goOrder() {
            let { name, phone, email, adult, child, qz } = this
            // 验证用户名
            if (name == "" || name.length > 64) {
                return this.$toast(this.$t("form.nameCheck"))
            }
            // 验证手机号码
            if (
                phone == "" ||
                phone.length + qz.length > 20 ||
                phone.length + qz.length < 6 ||
                !check_Number(phone)
            ) {
                return this.$toast(this.$t("form.phoneCheck"))
            }
            if (qz == "") {
                return this.$toast(this.$t("form.qzCheck"))
            }
            if (!(check_Mail(email) || !checkAllNull(email))) {
                return this.$toast(this.$t("form.emailCheck"))
            }
            if (!check_Number(adult) || !checkAllNull(adult)) {
                return this.$toast(this.$t("form.adultsCheck"))
            }
            if (!check_Number(child)) {
                if (child) {
                    return this.$toast(this.$t("form.childrenCheck"))
                }
            }
            if (+adult + +child > +this.product.maxPassenger) {
                return this.$toast(this.$t("form.peoplesCheck"))
            }
            if (adult == 0) {
                return this.$toast(this.$t("form.mustAdultCheck"))
            }
            // 本地存储用户信息
            this.$store.commit("getUserOrderInfo", {
                ...this.$store.state.home.userOrderInfo,
                name,
                phone,
                email,
                adult,
                child: child ? child : 0,
                qz,
                ...this.product,
            })
            ;(this.name = ""),
                (this.phone = ""),
                (this.email = ""),
                (this.adult = ""),
                (this.child = ""),
                (this.qz = ""),
                // 跳转路由
                this.$router.push(
                    `/${this.$i18n.locale === "zh-EN" ? "english" : "chinese"}/bookOrder`
                )
        },
        // 国际代码弹窗
        showAreaCode() {
            // console.log(1);
            this.$set(this.show, "areaCode", true)
        },
        // 国际代码关闭
        closeAreaCode() {
            this.$set(this.show, "areaCode", false)
        },
        areaCodeConfirm(value) {
            this.qz = value.phone_code
        },
        showImage(arr, index) {
            ImagePreview({
                images: arr,
                startPosition: index,
            })
        },
        imgError(e) {
            e.target.setAttribute("src", require("@/assets/lazy-img.png"))
            e.target.setAttribute("lazy", "error")
        },
    },
    computed: {
        ...mapState({
            yacthsList: state => state.home.yacthsList,
        }),
    },
    mounted() {},
}
export default cardList
