<template>
    <div>
        <div class="bg">
            <div>
                <p>豪华游艇之旅</p>
                <p>
                    租用豪华游艇游览阿拉伯海岸线不需要巨额预算。现在，你可以与家人和朋友一起见证迪拜的所有奇迹，只有梦想才能留下的难忘记忆
                </p>
            </div>
        </div>
        <headLine>每日游艇之旅</headLine>
        <yachtSelect />
        <cardList></cardList>
        <headLine>常见问题解答</headLine>
        <questionList></questionList>
    </div>
</template>

<script>
import yachtSelect from "./yachtSelect/yachtSelect"
import cardList from "./cardList/cardList.vue"
import headLine from "@/components/headLine/headLine.vue"
import questionList from "@/components/questionList/questionList.vue"
import { inserSeoElement } from "@/libs/util"
export default {
    data() {
        return {
            showPopover: [
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
            ],
            indexMsg: "",
        }
    },
    components: { yachtSelect, cardList, headLine, questionList },
    methods: {},
    mounted() {
        //明天的时间
        let tomorrow = new Date()
        tomorrow.setTime(tomorrow.getTime() + 24 * 60 * 60 * 1000)
        this.$store.dispatch("getShareYachtsList", {
            rentDate: this.dayjs(tomorrow).format("YYYY-MM-DD"),
        })
        inserSeoElement(
            this.$t("headSeo.shareTitle"),
            this.$t("headSeo.shareKey"),
            this.$t("headSeo.shareDec")
        )
    },
}
</script>

<style lang="less" scoped>
.bg {
    background-image: url(./images/bg.png);
    background-size: cover;
    height: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > div {
        width: 360px;
        margin-right: 20px;

        & > p:first-child {
            font-family: SourceHanSansCN-Bold;
            font-size: 36px;
            color: #284c77;
            font-weight: bold;
            text-align: center;
        }

        & > p:last-child {
            width: 352px;
            height: 54px;
            font-size: 16px;
            color: #284c77;
            margin-top: 16px;
            line-height: 26px;
        }
    }
}
</style>
