import Home from '@/views/Home';
import yachtTours from '@/views/yachtTours';
import bookOrder from '@/views/bookOrder';
import superYachts from '@/views/superYachts';
import waterSports from '@/views/waterSports';
import news from '@/views/news';
const contact = () => import('@/views/contactUs');
const team = () => import('@/views/myTeam');
// 英文语言路由表
export default [
  {
    path: '/english/home',
    component: Home,
  },
  {
    path: '/english/yachtTours',
    component: yachtTours,
  },
  {
    path: '/english/superYachts',
    component: superYachts,
  },
  // {
  //   path: '/english/waterSports',
  //   component: waterSports,
  // },
  {
    path: '/english/bookOrder',
    component: bookOrder,
  },
  {
    path: '/english/news',
    component: news,
    children: [
      {
        path: 'detail',
        component: () => import('@/views/news/English/newDetail'),
        meta: { detail: true },
      },
    ],
  },
  {
    path: '/english/contact',
    component: contact,
  },
  {
    path: '/english/team',
    component: team,
  },
];
