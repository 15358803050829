import Vue from 'vue';
import Vuex from 'vuex';

import menu from './menu/menu';
import home from './home/home';
import yachtTours from './yachtTours/yachtTours';
import order from './order/order';
import teach from './teach/teach';
import SuperYachtsOrder from './SuperYachtsOrder/SuperYachtsOrder';
import ContactUs from './ContactUs';
import user from './user/user';
import baseConfig from './baseConfig/baseConfig';
import news from './news/news';
import teams from './teams/teams';
import receptionSave from './receptionSave/receptionSave';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    menu,
    home,
    yachtTours,
    order,
    teach,
    SuperYachtsOrder,
    ContactUs,
    user,
    baseConfig,
    news,
    teams,
    receptionSave,
  },
});
