import requestMock from './requestMock.js';
import request from './request';

// 整租游艇列表
export const rentalYachtsList = (data) =>
  request({ data, method: 'post', url: '/yachts/rentalYachtsList' });

// 散拼游艇列表
export const shareYachtsList = (data) =>
  request({ data, method: 'post', url: '/yachts/shareYachtsList' });

// 游艇详情
export const yachtsDetail = (data) =>
  request({ data, method: 'post', url: '/yachts/yachtsDetail' });

// 超级游艇列表
export const superYachtsList = (data) =>
  request({ data, method: 'post', url: '/yachts/superYachtsList' });

// 创建游艇订单
export const addYachtsOrder = (data) =>
  request({ data, method: 'post', url: '/yachts/addYachtsOrder' });

// 创建超级游艇订单
export const addSuperYachtsOrder = (data) =>
  request({ data, method: 'post', url: '/yachts/addSuperYachtsOrder' });

// 创建教学订单
export const addTeachOrder = (data) =>
  request({ data, method: 'post', url: '/teach/addTeachOrder' });

// 教学列表
export const teachList = (data) =>
  request({ data, method: 'post', url: '/teach/list' });

// 游艇订单金额计算
export const calcYachtOrder = (data) =>
  request({ data, method: 'post', url: '/yachts/calcYachtOrder' });

// 教学订单金额计算
export const calcTeachOrder = (data) =>
  request({ data, method: 'post', url: '/teach/calcTeachOrder' });

// 联系我们留言
export const contactUSform = (data) =>
  request({ data, method: 'post', url: '/contact/create' });

// 获取基础配置信息
export const getBaseConfig = (data) =>
  request({ data, method: 'post', url: '/basic/config' });

// 获取新闻资讯列表
export const getNewsList = (data) =>
  request({ data, method: 'post', url: '/news/list' });

// 获取新闻置顶资讯列表
export const getTopNewsList = (data) =>
  request({ data, method: 'post', url: '/news/topList' });

// 获取新闻资讯详情
export const getNewsDetail = (data) =>
  request({ data, method: 'post', url: '/news/detail' });

// 获取公司团队列表
export const getTeamList = (data) =>
  request({ data, method: 'post', url: '/company/list' });

// 对访问数量进行统计接口
export const receptionSave = (data) =>
  request({ data, method: 'post', url: '/reception/save' });
