<template>
    <div>
        <van-popup v-model="show" position="bottom" @click-overlay="unShow" :close-on-click-overlay="false"  >
            <van-datetime-picker
                v-model="currentDate"
                :type="dateType"
                :title="title"
                @confirm="confirm"
                @cancel="cancel"
                :confirm-button-text="text.confirm"
                :cancel-button-text="text.cancel"
                :min-date="minDate"
            />
        </van-popup>
    </div>
</template>

<script>

    export default {
        props:{
            show:{
                type:Boolean,
                default:false
            },
            title:{
                type:String,
                default:'title'
            },
            dateType:{
                type:String,
                default:'date'
            },
            confirmText:{
                type:String,
                default:'OK'
            },
            cancelText:{
                type:String,
                default:'BACK'
            },
            minDate:{
                
                default:()=>new Date
            }
        },
        data(){
            return{
                currentDate:new Date()
            }
        },  
        methods:{
            confirm(value){
                this.$emit('confirm',value)
                this.unShow()
            },
            cancel(){
                this.unShow()
            },
            unShow(){
                this.$emit('unShow')
            },
        },
        computed:{
            text(){
                return{
                    confirm:this.$t('form.ok'),
                    cancel:this.$t('form.back')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
/deep/.van-popup{
  overflow-y: visible;
}
/deep/.van-datetime-picker{
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}
/deep/.van-picker__title{
    position: absolute;
    left: 0;
    background-color: #fff;
    text-align: center;
    top: -8px;
    min-width: 100vw;
    z-index: 2;
    line-height: 15vw;
}
/deep/.van-picker__cancel{
    flex: 1;
    background-color: #020B3B;
	color: #ffcc69;
    font-weight: bold;
}
/deep/.van-picker__confirm{
    flex: 1;
    background-color: #ffcc69;
    color: #333;
    font-weight: bold;
}
</style>