import { render, staticRenderFns } from "./sportList.vue?vue&type=template&id=6f82e168&scoped=true&"
import script from "./sportList.vue?vue&type=script&lang=js&"
export * from "./sportList.vue?vue&type=script&lang=js&"
import style0 from "./sportList.vue?vue&type=style&index=0&id=6f82e168&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f82e168",
  null
  
)

export default component.exports