<template>
    <div id="my-footer">
        <div class="footer-top">
            <div class="logo">
                <img src="./images/logo.png" alt="" />
            </div>
            <div class="menu-list">
                <ul>
                    <li>SERVICES</li>
                    <router-link to="/english/home" tag="li">YACHT CHARTER</router-link>
                    <!-- <router-link to="/english/yachtTours" tag="li">YACHT TOURS</router-link> -->
                    <li @click="openUrl">TOURS</li>
                    <router-link to="/english/superYachts" tag="li">SUPER YACHTS</router-link>
                    <!--<router-link to="/english/waterSports" tag="li">WATERSPORTS</router-link>-->
                </ul>
                <ul>
                    <li>HELP</li>
                    <li @click="aboutShow">ABOUT US</li>
                    <router-link to="/english/contact" tag="li">CONTACT</router-link>
                    <router-link to="/english/news" tag="li">NEWS</router-link>
                </ul>
                <ul>
                    <li>SOCILA</li>
                    <li @click="beforeRun(1, configInfo.faceBookLink)">
                        <img src="./images/m-logo1.png" alt="" />
                    </li>
                    <li @click="beforeRun(2, configInfo.instagramLink)">
                        <img src="./images/m-logo2.png" alt="" />
                    </li>
                    <li @click="beforeRun(3, configInfo.linkedinLink)">
                        <img src="./images/m-logo3.png" alt="" />
                    </li>
                    <li @click="beforeRun(4, configInfo.tripadvisorLink)">
                        <img src="./images/m-logo4.png" alt="" />
                    </li>
                    <li @click="beforeRun(5, configInfo.whatsappLink)" class="whatsApp">
                        <img src="./images/simple-whatsapp.png" alt="" />
                    </li>
                </ul>
                <ul>
                    <li>
                        <p>OFFICE HOURS</p>
                        <p>{{ configInfo.officeTime }} (365 DAYS)</p>
                    </li>
                    <li>
                        <p>PHONE</p>
                        <p>{{ configInfo.serviceMobileList[0] }}</p>
                    </li>
                    <li>
                        <p>EMAIL</p>
                        <p>{{ configInfo.serviceEmail }}</p>
                    </li>
                </ul>
            </div>
            <div class="footer-text">
                <p>{{ configInfo.officeAddress }}</p>
                <p>
                    <img class="footerImgLogo" src="../images/white_icon.png" /> Sky
                    {{ configInfo.copyrightInfo }}
                </p>
            </div>
        </div>
        <div class="footer-bootom">
            <div class="title">OUR PARTNERS</div>
            <div class="img-list">
                <div class="top-img">
                    <img src="./images/logo1.png" alt="" />
                    <img src="./images/logo2.png" alt="" />
                    <img src="./images/logo3.png" alt="" />
                </div>
                <div class="bottom-img">
                    <img src="./images/logo4.png" alt="" />
                    <img src="./images/logo5.png" alt="" />
                    <img src="./images/logo6.png" alt="" />
                    <img src="./images/logo7.png" alt="" />
                </div>
            </div>
            <div class="p-img">
                <div>
                    <img src="./images/logo8.png" alt="" />
                </div>
                <div>
                    <img src="./images/logo9.png" alt="" />
                </div>
            </div>
        </div>
        <div class="aside">
            <a href="javascript:;" @click="beforeRun(5, configInfo.whatsappLink)"
                ><img src="@/assets/aside_icon/aside_icon6.png" alt="" class="phone" />
                <p>Whatsapp</p></a
            >
            <a href="javascript:;" @click="beforeRun(1, configInfo.faceBookLink)"
                ><img src="@/assets/aside_icon/aside_icon1.png" alt="" class="facebook" />
                <p>Facebook</p>
            </a>

            <a href="javascript:;" @click="beforeRun(2, configInfo.instagramLink)"
                ><img src="@/assets/aside_icon/aside_icon2.png" alt="" class="creamer" />
                <p>Instagram</p>
            </a>

            <a href="javascript:;" @click="beforeRun(3, configInfo.linkedinLink)"
                ><img src="@/assets/aside_icon/aside_icon3.png" alt="" class="inline" />
                <p>Linkedin</p>
            </a>

            <a href="javascript:;" @click="beforeRun(4, configInfo.tripadvisorLink)"
                ><img src="@/assets/aside_icon/aside_icon4.png" alt="" class="recorder" />
                <p>Tripadvisor</p>
            </a>

            <a href="javascript:;" @click="showWechat(6)"
                ><img src="@/assets/aside_icon/aside_icon5.png" alt="" class="wechat" />
                <p>Wechat</p>
            </a>
        </div>
        <van-popup v-model="show">
            <div class="wechart">
                <div class="close">
                    <img src="../images/icon_x.png" alt="" @click="show = false" />
                </div>
                <div class="wechart-top" v-show="showItem">
                    <div class="logo">
                        <img src="@/assets/Header-Logo.png" alt="" />
                    </div>
                    <div class="QR-code">
                        <van-image :src="configInfo.weChatQRCode"> </van-image>
                    </div>
                    <div class="text">
                        <p>Use wechat to scan the QR code to contact the account</p>
                        <p>manager or click the button to copy wechat id</p>
                        <p>contact the account manager</p>
                    </div>
                    <div class="btn">
                        <button
                            v-clipboard:copy="configInfo.weChat"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                        >
                            COPY WECHAT ID
                        </button>
                    </div>
                </div>
                <div class="wechart-bottom" v-show="!showItem">
                    <h2>Add Account Manager Steps:</h2>
                    <p>1.Click on the top-right corner to add contacts</p>
                    <div class="steps-one">
                        <img src="./images/steps-en.png" alt="" />
                    </div>
                    <p>2.Paste in the input box to search and add contacts</p>
                    <div class="steps-two">
                        <img src="./images/steps2-en.png" alt="" />
                    </div>
                    <button @click="goWechat">GO TO WECHAT NOW</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            showItem: true,
            message: "13600074164",
        }
    },
    computed: {
        configInfo() {
            return this.$store.state.baseConfig.baseConfigInfo
        },
    },
    watch: {
        show() {
            setTimeout(() => {
                this.showItem = true
            }, 500)
        },
    },
    methods: {
        // 侧边栏和底部跳转之前执行的函数
        beforeRun(receptionType, receptionUrl) {
            this.$store.dispatch("visitsNum", {
                source: this.$store.state.receptionSave.orderSourceId
                    ? this.$store.state.receptionSave.orderSourceI
                    : "",
                receptionType,
                receptionUrl,
            })
        },
        aboutShow() {
            this.$store.commit("ABOUT")
        },
        showWechat(receptionType) {
            this.show = true
            this.$store.dispatch("visitsNumWechat", {
                source: this.$store.state.receptionSave.orderSourceId
                    ? this.$store.state.receptionSave.orderSourceId
                    : "",
                receptionType,
                receptionUrl: this.configInfo.weChat,
            })
        },
        onCopy(e) {
            this.$dialog
                .alert({
                    message: `Wechat ID :${e.text} has been copied to the paste board. Please follow the following steps to add an account manager`,
                    theme: "round-button",
                    confirmButtonColor: "#FFCC69",
                    confirmButtonText: this.$t("form.ok"),
                    className: "my-copy-btn",
                })
                .then(() => {
                    this.showItem = false
                })
        },
        onError(e) {
            this.$dialog.alert({
                message: `Failed to copy texts`,
                theme: "round-button",
                confirmButtonColor: "#FFCC69",
                confirmButtonText: this.$t("form.ok"),
                className: "my-copy-btn",
            })
        },
        goWechat() {
            window.location.href = "weixin://"
        },
        openUrl() {
            window.open("https://skywalkertours.ae/")
        },
    },
}
</script>

<style lang="less" scoped>
.whatsApp {
    img {
        width: 25px;
        object-fit: contain;
    }
}
.footer-top {
    background-color: #020b3b;
    padding-bottom: 20px;
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    img {
        width: 103px;
    }
}
.menu-list {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
    ul {
        li {
            font-family: Arial-BoldMT;
            font-size: 18px;
            color: #ffffff;
            height: 50px;
        }
        & > li:first-child {
            font-size: 22px;
        }
    }
    & > ul:nth-child(1) {
        margin-left: 20px;
    }
    & > ul:nth-child(2) {
        // margin-left: 84px;
        width: 96px;
    }
    & > ul:nth-child(3) {
        // margin-left: 70px;
        width: 84px;
        li {
            margin: 0 auto;
        }
        img {
            display: block;
            margin: 0 auto;
        }
        & > li:nth-child(1) {
        }
        & > li:nth-child(2) {
            img {
                width: 12px;
            }
        }
        & > li:nth-child(3) {
            img {
                width: 22px;
            }
        }
        & > li:nth-child(4) {
            img {
                width: 22px;
            }
        }
        & > li:nth-child(5) {
            img {
                width: 26px;
            }
        }
        & > li:nth-child(6) {
            img {
                width: 24px;
            }
        }
    }
    & > ul:nth-child(4) {
        height: auto;
        margin-right: 20px;
        & > li:nth-child(2) {
            margin: 30px 0;
        }
        li {
            height: auto;
            & > p > :nth-child(1) {
                font-family: Arial-BoldMT;
                font-size: 22px;
            }
            & > p:last-child {
                font-size: 18px;
                font-family: ArialMT;
                margin-top: 10px;
                color: #ffffff;
                opacity: 0.6;
            }
        }
    }
}
.footer-text {
    margin-top: 20px;
    p {
        text-align: center;
    }
    & > p:nth-child(1) {
        font-family: ArialMT;
        font-size: 14px;
        color: #ffffff;
        opacity: 0.6;
    }
    & > p:nth-child(2) {
        font-family: ArialMT;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin-top: 6px;
    }
}
.footer-bootom {
    background-color: #ffcc69;
    height: 170px;
    position: relative;
    .title {
        font-family: Arial-BoldMT;
        font-size: 22px;
        color: #020b3b;
        text-align: center;
        padding-top: 18px;
        font-weight: bold;
    }
    .top-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 22px;
        & > img:nth-child(1) {
            width: 84px;
        }
        & > img:nth-child(2) {
            width: 93px;
            display: block;
            margin: 0 20px 0 34px;
        }
        & > img:nth-child(3) {
            width: 108px;
        }
    }
    .bottom-img {
        margin-top: 18px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        & > img:nth-child(1) {
            width: 43px;
        }
        & > img:nth-child(2) {
            width: 61px;
            margin: 0 13px 0 21px;
        }
        & > img:nth-child(3) {
            width: 64px;
            margin-right: 10px;
        }
        & > img:nth-child(4) {
            width: 83px;
        }
    }
    .p-img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        & > div:nth-child(1) {
            display: flex;
            justify-content: flex-end;
            img {
                width: 50px;
            }
        }
        & > div:nth-child(2) {
            img {
                width: 64px;
            }
        }
    }
}
.footerImgLogo {
    width: 15px;
    height: 15px;
}
.aside {
    position: fixed;
    bottom: 200px;
    right: 0;
    z-index: 99;
    width: 96px;
    height: 470px;
    padding: 26px 0;
    background-color: #ffcc69;
    border-radius: 48px;
    border: solid 2px #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    p {
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        color: #020b3b;
        margin-top: 10px;
    }
    img {
        height: 30px;
        width: auto;
    }
}
.wechart {
    width: 577px;
    .close {
        position: absolute;
        top: 0;
        right: 22px;
        width: 22px;
        padding: 22px;
        img {
            width: 22px;
        }
    }
    .wechart-top {
        background-color: #020b3b;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 700px;
        .logo {
            width: 240px;
            margin-top: 60px;
            img {
                width: 100%;
            }
        }
        .QR-code {
            width: 30vw;
            margin-top: 40px;
            img {
                width: 100%;
            }
            /deep/.van-image {
                width: 100%;
                min-height: 30vw;
            }
        }
        .text {
            font-family: ArialMT;
            font-size: 20px;
            line-height: 17px;
            color: #ffffff;
            text-align: center;
            line-height: 30px;
            margin-top: 50px;
        }
        .btn {
            button {
                width: 224px;
                background-color: #ffcc69;
                border: none;
                outline: none;
                height: 60px;
                line-height: 60px;
                margin-top: 36px;
                color: #020b3b;
                font-size: 20px;
                font-weight: bold;
                font-family: Arial, Helvetica, sans-serif;
            }
        }
    }
    .wechart-bottom {
        padding-left: 50px;
        background-color: #020b3b;
        height: 700px;
        h2 {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
            padding: 50px 0 34px 0;
        }
        p {
            font-family: Arial;
            font-size: 20px;
            color: #ffffff;
            line-height: 30px;
        }
        .steps-one {
            width: 113px;
            padding-left: 20px;
            margin-top: 15px;
            margin-bottom: 30px;
            img {
                width: 100%;
            }
        }
        .steps-two {
            width: 370px;
            margin-left: 20px;
            margin-top: 30px;
            img {
                width: 100%;
            }
        }
        button {
            width: 252px;
            height: 56px;
            background-color: #ffcc69;
            display: block;
            font-family: Arial;
            font-weight: bold;
            font-size: 20px;
            color: #020b3b;
            border: none;
            outline: none;
            margin: 0 auto;
            margin-top: 100px;
            transform: translateX(-25px);
        }
    }
}
</style>
