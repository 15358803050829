<template>
    <div>
        <template v-if="!$route.meta.detail">
            <div class="bg">
                <p>News & Events</p>
            </div>
            <ul class="news-list">
                <li
                    v-for="(item, index) in list"
                    @click="$router.push(`/english/news/detail?newsId=${item.id}`)"
                    :key="index"
                >
                    <div class="left">
                        <img :src="item.imageUrl" alt="" />
                    </div>
                    <div class="right">
                        <div>
                            <div class="title">{{ item.title }}</div>
                            <p>{{ item.summary }}</p>
                        </div>
                        <div class="time">
                            {{ dayjs(item.updateDate).format("DD MMMM YYYY") }}
                        </div>
                    </div>
                </li>
            </ul>
            <div class="btn" @click="loadMore" v-if="!(this.total <= this.list.length)">
                Read More
            </div>
        </template>
        <router-view></router-view>
    </div>
</template>

<script>
import { inserSeoElement } from "@/libs/util"
export default {
    data() {
        return {
            list: [],
            current: 1,
            size: 3,
            total: 0,
        }
    },
    methods: {
        loadMore() {
            this.initNewsList({ current: (this.current += 1), size: this.size })
        },
        async initNewsList(data) {
            await this.$store.dispatch("getNewsList", data)
            this.list = [...this.list, ...this.$store.state.news.newsList.records]
            this.total = this.$store.state.news.newsList.total
        },
    },
    async created() {
        await this.initNewsList({ current: this.current, size: this.size })
    },
    mounted() {
        inserSeoElement(
            this.$t("headSeo.newsTitle"),
            this.$t("headSeo.newsKey"),
            this.$t("headSeo.newsDec")
        )
    },
}
</script>

<style lang="less" scoped>
.bg {
    background-image: url(./images/bg.png);
    background-size: cover;
    height: 384px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-family: Bintang;
        font-size: 60px;
        color: #ffffff;
    }
}

.news-list {
    margin: 0 20px;
    margin-bottom: 20px;

    li {
        display: flex;
        margin-top: 26px;

        .left {
            min-width: 280px;
            margin: 10px 20px 0 0;
            flex: 0;

            img {
                width: 280px;
                height: 200px;
                object-fit: cover;
                display: block;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                font-family: Arial-BoldMT;
                font-size: 28px;
                color: #333333;
                width: 410px;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            p {
                font-family: ArialMT;
                font-size: 22px;
                color: #333333;
                line-height: 26px;
                margin: 10px 0 16px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }

            .time {
                font-family: ArialMT;
                font-size: 20px;
                color: #666666;
            }
        }
    }
}

.btn {
    width: 200px;
    height: 60px;
    background-color: #ffcc69;
    font-family: ArialMT;
    font-size: 22px;
    color: #333333;
    text-align: center;
    line-height: 60px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 90px;
}
</style>
