const video = {
    data(){
        return{
            playStatus:false,
        }
    },
    methods:{
        playVideo(id){
            function checkIsFullScreen(){
                var isFullScreen = document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen;
                return isFullScreen == undefined ? false : isFullScreen;
            }
            let video = this.$refs[id][0]
            video.requestFullscreen()
            video.addEventListener('fullscreenchange',()=>{           
                if (checkIsFullScreen()) {
                    console.log("进入全屏");
                } else {
                    video.load()
                }
            })  
        }

    }

}

export default video