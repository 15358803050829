import {contactUSform} from '@/api'
import { success, fail, failMsg } from '@/libs/util';
const state = {

}

const actions = {
    async sendContactMsg({commit},context){
        try {
            let data = await contactUSform(context)      
            success()
        } catch (error) {
            failMsg(error)
        }
    }
}

const mutations = {

}

export default {
    state,actions,mutations
}