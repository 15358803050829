<template>
  <div>
    <div class="select-list">
      <div @click="showDate">
        <p>
          {{
            dateOptions.value === ''
              ? '日期'
              : dayjs(dateOptions.value).format('YYYY/MM/DD')
          }}
        </p>
        <p></p>
      </div>
      <div @click="showTime">
        <p>
          {{ timeOptions.value === '' ? '租赁时间' : timeOptions.value }}
        </p>
        <p></p>
      </div>
      <div @click="showHours">
        <p>
          {{ hoursOptions.value === '' ? '租赁时长' : hoursOptions.value }}
        </p>
        <p></p>
      </div>
      <div @click="showPerson">
        <p>
          {{
            personOptions.value === '' ? '人数' : personOptions.value + ' 人'
          }}
        </p>
        <p></p>
      </div>
    </div>
    <diyPick
      :list="personOptions.personList"
      :show="personOptions.show"
      :title="personOptions.title"
      :columns="personColumns"
      @unShow="unShow"
      @confirm="personConfirm"
    />
    <diyPick
      :list="timeOptions.list"
      :show="timeOptions.show"
      :title="timeOptions.title"
      :columns="timeColumns"
      @unShow="unShow"
      @confirm="timeConfirm"
    />
    <diyPick
      :list="hoursOptions.list"
      :show="hoursOptions.show"
      :title="hoursOptions.title"
      :columns="hoursColumns"
      @unShow="unShow"
      @confirm="hourConfirm"
    />
    <diyDateTime
      :title="dateOptions.title"
      @unShow="unShow"
      :show="dateOptions.show"
      @confirm="dateConfirm"
    />
  </div>
</template>

<script>
import diyPick from '@/components/diyPick/diyPick.vue';
import diyDateTime from '@/components/diyDateTime/diyDateTime.vue';
export default {
  data() {
    return {
      currentDate: new Date(),
      // PERSON
      personOptions: {
        title: '请选择您的宾客人数',
        personList: {
          人: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
          ],
        },
        show: false,
        value: '',
      },

      // TIME
      timeOptions: {
        title: '请选择您的租赁时间',
        list: {
          上午: [6, 7, 8, 9, 10, 11, 12],
          下午: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },
        show: false,
        value: '',
      },

      // HOURS
      hoursOptions: {
        title: '请选择您的租赁时长',
        list: {
          小时: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        show: false,
        value: '',
      },

      //DATE
      dateOptions: {
        title: '请选择您的日期',
        show: false,
        value: '',
        date: new Date(),
      },
    };
  },
  methods: {
    // 控件显示操作
    showPerson() {
      this.personOptions.show = true;
    },
    showDate() {
      this.dateOptions.show = true;
    },
    showHours() {
      this.hoursOptions.show = true;
    },
    showTime() {
      this.timeOptions.show = true;
    },
    // 关闭pick 和 time 控件
    unShow() {
      this.personOptions.show = false;
      this.timeOptions.show = false;
      this.hoursOptions.show = false;
      this.dateOptions.show = false;
    },
    // 控件确定操作
    personConfirm(value) {
      // let text = value[0] + ' ' + value[1];
      this.$set(this.personOptions, 'value', value[0]);
      // 本地存储
      this.setLocValue({ person: value[0] });
      // 筛选游艇列表
      let params = {
        passengerNum: value[0],
      };
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.timeOptions.value) {
        if (this.timeOptions.value.indexOf('上午') != -1) {
          params['beginTime'] = `${
            Number(this.timeOptions.value.replace(/[^\d]/g, '')) > 9
              ? this.timeOptions.value.replace(/[^\d]/g, '')
              : '0' + this.timeOptions.value.replace(/[^\d]/g, '')
          }:00`;
        }
        if (this.timeOptions.value.indexOf('下午') != -1) {
          params['beginTime'] = `${
            Number(this.timeOptions.value.replace(/[^\d]/g, '')) + 12
          }:00`;
        }
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    timeConfirm(value) {
      let text = value[1] + '' + value[0] + '点';
      this.$set(this.timeOptions, 'value', text);
      this.setLocValue({
        time: value[0],
        showTime:
          text.indexOf('上午') != -1 ? value[0] + 'AM' : value[0] + 'PM',
      });
      console.log(Number(this.timeOptions.value.replace(/[^\d]/g, '')));
      // 筛选游艇列表
      let params = {};
      if (this.timeOptions.value.indexOf('上午') != -1) {
        params['beginTime'] = `${
          Number(this.timeOptions.value.replace(/[^\d]/g, '')) > 9
            ? this.timeOptions.value.replace(/[^\d]/g, '')
            : '0' + this.timeOptions.value.replace(/[^\d]/g, '')
        }:00`;
      }
      if (this.timeOptions.value.indexOf('下午') != -1) {
        params['beginTime'] = `${
          Number(this.timeOptions.value.replace(/[^\d]/g, '')) + 12
        }:00`;
      }
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    hourConfirm(value) {
      let text = value[0] + ' ' + value[1];
      this.$set(this.hoursOptions, 'value', text);
      this.setLocValue({ hour: value[0] });
      // 筛选游艇列表
      let params = {};
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.timeOptions.value) {
        if (this.timeOptions.value.indexOf('上午') != -1) {
          params['beginTime'] = `${
            Number(this.timeOptions.value.replace(/[^\d]/g, '')) > 9
              ? this.timeOptions.value.replace(/[^\d]/g, '')
              : '0' + this.timeOptions.value.replace(/[^\d]/g, '')
          }:00`;
        }
        if (this.timeOptions.value.indexOf('下午') != -1) {
          params['beginTime'] = `${
            Number(this.timeOptions.value.replace(/[^\d]/g, '')) + 12
          }:00`;
        }
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    dateConfirm(value) {
      this.$set(this.dateOptions, 'value', value);
      this.setLocValue({ date: this.dayjs(value).format('YYYY/MM/DD') });
      // 筛选游艇列表
      let params = {
        rentDate: this.dayjs(value).format('YYYY-MM-DD'),
      };
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      if (this.timeOptions.value) {
        if (this.timeOptions.value.indexOf('上午') != -1) {
          params['beginTime'] = `${
            Number(this.timeOptions.value.replace(/[^\d]/g, '')) > 9
              ? this.timeOptions.value.replace(/[^\d]/g, '')
              : '0' + this.timeOptions.value.replace(/[^\d]/g, '')
          }:00`;
        }
        if (this.timeOptions.value.indexOf('下午') != -1) {
          params['beginTime'] = `${
            Number(this.timeOptions.value.replace(/[^\d]/g, '')) + 12
          }:00`;
        }
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    getRentalYachtsList() {
      let passengerNum =
        this.personOptions.value === '' ? 1 : this.personOptions.value;
      let rentDate =
        this.dateOptions.value === ''
          ? this.dayjs(new Date(rentDate)).format('YYYY-MM-DD')
          : this.dateOptions.value;
    },
    // 本地存储筛选信息
    setLocValue(params) {
      // 本地存储用户信息
      this.$store.commit('getUserOrderInfo', {
        ...this.$store.state.home.userOrderInfo,
        ...params,
      });
    },
  },
  created() {},
  components: {
    diyPick,
    diyDateTime,
  },
  computed: {
    personColumns() {
      let a = [
        {
          values: this.personOptions.personList['人'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.personOptions.personList),
          className: 'column1',
        },
      ];
      return a;
    },
    timeColumns() {
      let a = [
        {
          values: this.timeOptions.list['上午'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.timeOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
    hoursColumns() {
      let a = [
        {
          values: this.hoursOptions.list['小时'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.hoursOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
  },
  mounted() {
    let passengerNum =
      this.personOptions.value === '' ? 1 : this.personOptions.value;
    //明天的时间
    let tomorrow = new Date();
    tomorrow.setTime(tomorrow.getTime() + 24 * 60 * 60 * 1000);
    this.dateOptions.value = this.dayjs(tomorrow).format('YYYY-MM-DD');
    this.setLocValue({ date: this.dayjs(tomorrow).format('YYYY-MM-DD') });
    this.$store.dispatch('getRentalYachtsList', {
      rentDate: this.dayjs(tomorrow).format('YYYY-MM-DD'),
    });
  },
};
</script>

<style lang="less" scoped>
.select-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  padding: 0 40px;
  border-bottom: 1px solid #cccccc;

  & > div {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 82px;

    & > p:first-child {
      font-family: ArialMT;
      font-size: 22px;
      color: #666666;
    }

    & > p:last-child {
      width: 12px;
      height: 12px;
      border-bottom: 3px solid #666;
      border-right: 3px solid #666;
      transform: rotate(45deg);
      // margin-top: 6px;
      margin-left: 12px;
    }
  }
  // & > div:nth-child(1) {
  //   width: 120px;
  // }
  // & > div:nth-child(2) {
  //   width: 150px;
  // }
  // & > div:nth-child(3) {
  //   width: 140px;
  // }
  // & > div:nth-child(4) {
  //   width: 200px;
  // }
}
</style>
