<template>
    <div v-if="list.length > 0" class="card-list">
        <div class="card-item" v-for="(item, index) in list" :key="index">
            <div class="card-bg">
                <!-- <img :src="item.mediaUrl" alt="" /> -->
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide class="slide" v-for="(citem, cindex) in item.mediaArr" :key="cindex">
                        <div class="box">
                            <van-image :src="citem" @click="showImage(item.mediaArr, cindex)">
                                <template v-slot:error>fail to load</template>
                            </van-image>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                </swiper>
            </div>
            <div class="description">
                <p class="title">
                    <span>
                        <span>
                            <img src="../images/morning.png" v-if="item.timeType === 0" />
                            <img src="../images/sunrise.png" v-else-if="item.timeType === 1" />
                            <img src="../images/cloudy.png" v-else-if="item.timeType === 2" />
                            <img src="../images/sunset.png" v-else-if="item.timeType === 3" />
                            <img src="../images/moon.png" v-else-if="item.timeType === 4" />
                        </span>
                        {{
                            item.timeType === 0
                                ? "Morning"
                                : item.timeType === 1
                                ? "Early Risers"
                                : item.timeType === 2
                                ? "Afternoon"
                                : item.timeType === 3
                                ? "Sunset"
                                : "Night Lights"
                        }}
                    </span>
                    <span> <img src="../images/clockIon.png" alt="" />{{ item.startTime }}-{{ item.endTime }} </span>
                </p>
                <p>
                    <span> <span>Adult:</span> AED {{ item.adultPrice }}</span>
                    <span> <span>Child:</span> AED {{ item.childPrice }}</span>
                </p>
                <p>
                    <span v-html="item.remark">
                        <!-- <span>Includes:</span>
            Tour guide, assorted sandwiches, chips, fresh fruit & freshly baked
            muffins -->
                    </span>
                </p>
            </div>
            <div class="bool-btn" @click="showPoup(item)">BOOK</div>
        </div>
        <van-popup @close="back" v-model="poup" class="poup">
            <div class="container">
                <div class="title">YACHT TOUR BOOKING</div>
                <div class="form">
                    <div class="cel">
                        <div class="name">TOUR DATE</div>
                        <div class="input">
                            <input type="text" readonly :value="date" />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">TOUR TIME</div>
                        <div class="input">
                            <input type="text" :value="`${startTime}-${endTime}`" readonly />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">ADULTS</div>
                        <div class="input">
                            <input
                                type="text"
                                v-number
                                v-model="adults"
                                oninput="if(value.length > 4) value = value.slice(0,4)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">CHILDREN</div>
                        <div class="input">
                            <input
                                type="text"
                                v-number
                                v-model="children"
                                oninput="if(value.length > 4) value = value.slice(0,4)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">NAME</div>
                        <div class="input">
                            <input type="text" v-model="name" />
                        </div>
                    </div>
                    <div class="cel phone">
                        <div class="name">PHONE</div>
                        <div class="input phoneCode">
                            <div class="phone-box">
                                <input type="text" :value="qz ? '+ ' + qz : ''" readonly @click="showAreaCode" />
                                <i class="arrow-down" @click="showAreaCode"><van-icon name="arrow-down" /></i>
                            </div>
                            <input
                                type="text"
                                v-model="phone"
                                oninput="if(value.length > 15) value = value.slice(0,15)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">E-MAIL</div>
                        <div class="input">
                            <input type="text" v-model="email" />
                        </div>
                    </div>
                    <div class="total">
                        <p>TOTAL (inc. 5% VAT)</p>
                        <p>
                            <input type="text" readonly v-model="total" />
                        </p>
                    </div>
                </div>
                <div class="btn">
                    <div @click="back">BACK</div>
                    <div @click="commit">OK</div>
                </div>
            </div>
        </van-popup>
        <areaCode :show="show.areaCode" @unShow="closeAreaCode" @confirm="areaCodeConfirm" />
        <!-- <diyDateTime
      :title="dateOptions.title"
      @unShow="unShow"
      :show="dateOptions.show"
      @confirm="dateConfirm"
    />
    <diyPick
      :list="timeOptions.list"
      :show="timeOptions.show"
      :title="timeOptions.title"
      :columns="timeColumns"
      @unShow="unShow"
      @confirm="timeConfirm"
    /> -->
    </div>
    <Empty v-else />
</template>

<script>
import cardList from "@/mixin/yachtTours/cardList.js"
export default {
    mixins: [cardList],
    data() {
        return {
            //DATE
            dateOptions: {
                title: "Please select your date",
                show: false,
                value: "",
                date: new Date(),
            },
            // TIME
            timeOptions: {
                title: "Please select your Charter Time",
                list: {
                    AM: [6, 7, 8, 9, 10, 11, 12],
                    PM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                },
                show: false,
                value: "",
            },
        }
    },
    computed: {
        timeColumns() {
            let a = [
                {
                    values: this.timeOptions.list["AM"],
                    className: "column2",
                    defaultIndex: 2,
                },
                {
                    values: Object.keys(this.timeOptions.list),
                    className: "column1",
                },
            ]
            return a
        },
    },
}
</script>

<style lang="less" scoped>
.card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 20px;
    margin-top: 16px;

    .card-item {
        width: 345px;
        height: 460px;
        padding-bottom: 10px;
        overflow: hidden;
        position: relative;
        .card-bg {
            height: 220px;
            .swiper {
                height: 220px;
                .box {
                    height: 100%;
                    .van-image {
                        height: 100%;
                        width: 100%;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        margin-bottom: 23px;
        position: relative;
    }
}

.description {
    background-color: #ffcc69;
    padding: 0 10px;
    padding-bottom: 10px;
    height: calc(100% - 220px);
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        & > span:first-child {
            font-family: Bintang;
            font-size: 26px;
            color: #020b3b;
            display: flex;
            align-items: center;

            & > span {
                margin-right: 10px;
            }

            img {
                width: 20px;
                height: auto;
            }
        }

        & > span:last-child {
            display: flex;
            align-items: center;
            font-family: Arial-BoldMT;
            font-size: 20px;
            color: #020b3b;
            font-weight: bold;
        }

        img {
            width: 17px;
            display: block;
            margin-right: 4px;
        }
    }

    & > p:nth-child(2) {
        display: flex;
        align-items: center;
        flex-direction: column;

        & > span {
            font-family: Arial-BoldMT;
            font-size: 20px;
            color: #020b3b;
            font-weight: normal;

            span {
                font-weight: bold;
            }
        }
    }

    & > p:nth-child(3) {
        margin-top: 14px;

        & > span {
            font-family: Arial-BoldMT;
            font-size: 16px;
            color: #020b3b;
            font-weight: normal;
            display: block;

            span {
                font-weight: bold;
            }
        }
    }
}

.bool-btn {
    height: 53px;
    background-color: #020b3b;
    color: #ffcc69;
    text-align: center;
    line-height: 53px;
    font-family: Arial-BoldMT;
    font-size: 28px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.price {
    position: absolute;
    background-color: #ffcc69;
    right: 0;
    top: 20px;
    z-index: 10;
    width: 120px;
    height: 64px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & > p {
        text-align: center;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
    }

    & > p:last-child {
        font-size: 18px;
        font-weight: bold;
    }
}

.poup {
    .container {
        width: 670px;

        .title {
            height: 100px;
            text-align: center;
            line-height: 100px;
            font-family: Arial-BoldMT;
            font-size: 36px;
            color: #333333;
            background-color: #ffcc69;
        }

        .form {
            background-color: #fff;
            padding: 0 40px 0 55px;
            padding-top: 46px;

            .cel {
                display: flex;
                margin-bottom: 24px;

                .name {
                    width: 150px;
                    min-width: 140px;
                    text-align: right;
                    padding-right: 10px;
                    box-sizing: border-box;
                    line-height: 50px;
                    font-family: Arial-BoldMT;
                    font-size: 24px;
                    color: #333333;
                    margin-right: 20px;
                }

                .input {
                    flex: 1;
                    display: flex;
                }

                input {
                    height: 50px;
                    line-height: 50px;
                    border: solid 2px #cccccc;
                    flex: 1;
                    padding-left: 10px;
                }
            }

            .phone {
                .input {
                    input:first-child {
                        width: 150px;
                        flex: 0;
                    }

                    input:last-child {
                        flex: 1;
                        width: 238px;
                    }
                }
                .phoneCode {
                    position: relative;
                    .arrow-down {
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding-right: 8px;
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        height: 100%;
                        border: 2px solid #cccccc;
                        border-left: none;
                    }
                }
                .phone-box {
                    position: relative;
                    display: flex;
                    width: 150px;
                    justify-content: flex-end;
                    margin-right: 16px;
                }
            }
        }

        .total {
            & > p:nth-child(1) {
                font-family: Arial-BoldMT;
                font-size: 24px;
                color: #333333;
                margin-bottom: 14px;
            }

            input {
                width: 100%;
                height: 50px;
                border: solid 2px #cccccc;
                padding-left: 10px;
            }
        }

        .btn {
            display: flex;
            margin-top: 60px;

            div {
                flex: 1;
                height: 100px;
                outline: none;
                border: none;
                font-family: Arial-BoldMT;
                font-size: 36px;
                line-height: 100px;
                display: block;
                text-align: center;
            }

            & > div:first-child {
                background-color: #020b3b;
                color: #ffcc69;
            }

            & > div:last-child {
                color: #020b3b;
                background-color: #ffcc69;
            }
        }
    }
}
.swiper-pagination {
    display: flex;
    justify-content: center;

    /deep/.my-bullet {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        display: block;
        margin: 0 5px;
    }

    /deep/.my-bullet-active {
        background-color: #ffcc69;
    }
}
</style>
