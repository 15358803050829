<template>
    <div>
        <div class="about-us">
            <span class="version" v-if="mode !== 'production'"
                >{{ $t("version") }}:{{ appVersion }}</span
            >
            <span @click="aboutShow">{{ $t("header.aboutUs") }}</span>
            <span
                >{{ $t("header.callUs")
                }}{{ $store.state.baseConfig.baseConfigInfo.serviceMobileList[0] }}({{
                    $store.state.baseConfig.baseConfigInfo.officeTime
                }})</span
            >
        </div>
        <div class="container-h">
            <div class="logo">
                <img src="./images/Header-Logo.png" alt="" />
            </div>
            <div class="menu">
                <div>
                    <img src="./images/US.png" @click="showLang" v-if="$i18n.locale === 'zh-EN'" />
                    <img
                        src="./images/chinese.png"
                        @click="showLang"
                        v-else-if="$i18n.locale === 'zh-CH'"
                    />
                </div>
                <div @click="changeShow">
                    <img src="./images/meun.png" alt="" />
                </div>
            </div>
        </div>
        <van-popup v-model="show" position="right" @click-overlay="changeShow">
            <div class="menu-list">
                <ul>
                    <li
                        :class="{
                            active: currentLang + item.path == $store.state.menu.currentPath,
                        }"
                        v-for="(item, index) in navName"
                        :key="index"
                        @click="goto(index)"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </van-popup>
        <van-popup v-model="langShow" position="bottom" @click-overlay="hiddenLang">
            <van-picker
                :title="$i18n.locale == 'zh-EN' ? 'Please select your Language' : '请选择您的语言'"
                show-toolbar
                :columns="columns"
                @confirm="langConfirm"
                @cancel="hiddenLang"
                :confirm-button-text="$t('form.ok')"
                :cancel-button-text="$t('form.back')"
                :default-index="$i18n.locale == 'zh-EN' ? 0 : 1"
            />
        </van-popup>
    </div>
</template>

<script>
import LangPick from "@/components/LangPick/index.vue"
import { setLocal } from "@/libs/storage"
import { mapState } from "vuex"
import config from "@/api/config"

export default {
    data() {
        return {
            langArr: ["zh-EN", "zh-CH"],
            m: ["english", "chinese"],
            appVersion: config.appVersion,
            mode: config.mode,
        }
    },
    methods: {
        changeShow() {
            this.$store.dispatch("menuShow")
        },
        goto(index) {
            switch (index) {
                case 0:
                    this.$router.push({
                        path: `/${this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]}/home`,
                    })
                    break
                case 1:
                    // this.$router.push({
                    //     path: `/${this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]}/yachtTours`,
                    // })
                    window.open("https://skywalkertours.ae/")
                    break
                case 2:
                    this.$router.push({
                        path: `/${
                            this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]
                        }/superYachts`,
                    })
                    break
                // case 3:
                //     this.$router.push({
                //         path: `/${
                //             this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]
                //         }/waterSports`,
                //     })
                //     break
                case 3:
                    this.$router.push({
                        path: `/${this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]}/news`,
                    })
                    break
                case 4:
                    this.$router.push({
                        path: `/${this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]}/contact`,
                    })
                    break
                case 5:
                    this.$router.push({
                        path: `/${this.$i18n.locale == "zh-EN" ? this.m[0] : this.m[1]}/team`,
                    })
            }
            this.changeShow()
        },
        aboutShow() {
            this.$store.commit("ABOUT")
        },
        showLang() {
            this.$store.commit("SHOWLANG")
        },
        hiddenLang() {
            this.$store.commit("HIDDENLANG")
        },
        langConfirm(value) {
            let index = this.columns.findIndex(item => {
                return item == value
            })
            this.hiddenLang()
            let pushPath = this.$route.path.split("/")[2]
            setTimeout(() => {
                this.$i18n.locale = this.langArr[index]
                setLocal("langType", this.langArr[index])
                let path = `/${this.m[index]}/${pushPath}`
                this.$router.push({ path })
            }, 500)
        },
    },
    computed: {
        // 侧边栏显示
        show: {
            get() {
                return this.$store.state.menu.show
            },
            set() {},
        },
        ...mapState({
            langShow: state => state.menu.langShow,
        }),
        columns() {
            return [this.$t("header.englist"), this.$t("header.chinese")]
        },
        // 用于拼接侧边栏的路由
        currentLang() {
            if (this.$i18n.locale == "zh-CH") {
                return "/chinese"
            } else if (this.$i18n.locale == "zh-EN") {
                return "/english"
            }
        },
        // 侧边栏路由
        navName() {
            return [
                {
                    path: "/home",
                    name: this.$t("navList.home"),
                },
                {
                    path: "/yachtTours",
                    name: this.$t("navList.yachtTours"),
                },
                {
                    path: "/superYachts",
                    name: this.$t("navList.superYachts"),
                },
                // {
                //     path: "/waterSports",
                //     name: this.$t("navList.waterSports"),
                // },
                {
                    path: "/news",
                    name: this.$t("navList.news"),
                },
                {
                    path: "/contact",
                    name: this.$t("navList.contact"),
                },
                {
                    path: "/team",
                    name: this.$t("navList.team"),
                },
            ]
        },
    },
    components: {
        LangPick,
    },
}
</script>

<style lang="less" scoped>
.about-us {
    height: 40px;
    background-color: #ffcc69;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .version {
        color: #1eaf00;
        font-weight: bold;
        font-size: 20px;
    }

    span {
        font-family: ArialMT;
        font-size: 22px;
        line-height: 24px;
        color: #020b3b;
    }

    & > span:first-child {
        margin-right: 30px;
    }

    & > span:last-child {
        margin-right: 20px;
    }
}

.container-h {
    height: 88px;
    background-color: #020b3b;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        img {
            height: 54px;
            width: auto;
            display: block;
            margin-left: 20px;
        }
    }

    .menu {
        display: flex;
        align-items: center;

        & > div:first-child {
            img {
                width: 40px;
                height: auto;
            }
        }

        & > div:last-child {
            margin: 0 40px 0 33px;

            img {
                width: 48px;
                height: auto;
            }
        }
    }
}

.menu-list {
    width: 426px;
    height: 100vh;

    ul {
        padding-top: 200px;

        li {
            text-align: center;
            font-family: Arial-BoldMT;
            font-size: 24px;
            line-height: 60px;
            color: #999;
            font-weight: bold;
        }

        .active {
            color: #020b3b;
        }
    }
}

/deep/.van-picker {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

/deep/.van-picker__columns {
    .van-ellipsis {
        position: relative;
        transform: translateX(5vw);
        width: 20vw;
        text-align: left;
        padding-left: 2vw;
        overflow: visible;
    }
    .van-ellipsis::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(-6vw);
        content: "";
        width: 5.33vw;
        height: 5.33vw;
        background-size: cover;
    }
    .van-picker-column__item:nth-child(1) .van-ellipsis::before {
        background-image: url(./images/US.png);
    }
    .van-picker-column__item:nth-child(2) .van-ellipsis::before {
        background-image: url(./images/chinese.png);
    }
}

/deep/.van-picker__title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 15vw;
    text-align: center;
    line-height: 10vw;
    min-width: 100vw;
    margin: auto;
    background: #fff;
    z-index: 100;
}

/deep/.van-picker__cancel {
    flex: 1;
    background-color: #020b3b;
    color: #ffcc69;
    font-weight: bold;
}

/deep/.van-picker__confirm {
    flex: 1;
    background-color: #ffcc69;
    color: #333;
    font-weight: bold;
}

/deep/.van-picker__toolbar {
    height: 13vw;
}
</style>
