<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="slide" v-for="(item, index) in videos" :key="index">
        <video
          src="@/assets/water.mp4"
          ref="videos"
          poster="@/assets/video1.png"
          webkit-playsinline
          playsinline
          x-webkit-airplay="isHtml5UseAirPlay"
          v-show="item.show"
        ></video>
        <img src="@/assets/video1.png" v-if="!item.show" class="cover" />
        <img
          src="@/assets/play.png"
          @click="play(index)"
          v-show="!item.show"
          class="play"
        />
      </swiper-slide>
      <!-- <div
        class="swiper-pagination swiper-pagination-white"
        slot="pagination"
      ></div> -->
    </swiper>
    <headerLine>RELATED COURSES</headerLine>
    <sportList @poupHidden="poupHidden"></sportList>
    <headerLine>SENIOR INSTRUCTOR</headerLine>
    <div class="senior-inspector">
      <ul>
        <li>
          <div class="left">
            <img src="./images/person.png" alt="" />
          </div>
          <div class="right">
            <h2>
              <div class="name">JOHN DOE</div>
              <div class="grade">Senior instructor</div>
            </h2>
            <p>
              "Our watersports cater for first timers to fanatics. So come,
              escape to the water and enjoy. All activities come with a driver
              and our own qualified instructors. Your Experience Is Our
              Passion!!"
            </p>
          </div>
        </li>
      </ul>
    </div>
    <headerLine class="question-headerLine"
      >FREQUENTLY ASKED QUESTIONS</headerLine
    >
    <questionList></questionList>
  </div>
</template>

<script>
import waterIndex from "@/mixin/waterSports/waterIndex.js";
import headerLine from "@/components/headLine/headLine.vue";
import questionList from "@/components/questionList/waterQuestionList.vue";
import sportList from "./sportList/sportList.vue";
export default {
  mixins: [waterIndex],
  components: {
    headerLine,
    questionList,
    sportList,
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.senior-inspector {
  ul {
    li {
      background-color: #020b3b;
      padding: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .left {
        min-width: 160px;
        margin-right: 20px;

        img {
          width: 100%;
        }
      }

      .right {
        h2 {
          display: flex;
          align-content: flex-end;

          .name {
            font-family: Arial-BoldMT;
            font-size: 28px;
            color: #ffffff;
            font-weight: bold;
            margin-right: 16px;
          }

          .grade {
            font-family: ArialMT;
            font-size: 18px;
            color: #ffffff;
            display: flex;
            align-items: flex-end;
          }
        }

        & > p {
          font-family: ArialMT;
          font-size: 20px;
          color: #ffffff;
          margin-top: 20px;
        }
      }
    }
  }
}

/deep/.question-headerLine {
  .headline {
    margin-top: 60px;
  }
}

.video {
  video {
    width: 100%;
    object-fit: fill;
  }
}

.swiper {
  .slide {
    position: relative;
    width: 100%;
    height: 384px;
    video {
      height: 384px;
      width: 100%;
      object-fit: cover;
    }
    .play {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 54px;
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  transform: translateY(-10px);
  /deep/.my-bullet {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    display: block;
    margin: 0 5px;
    bottom: 30px;
  }

  /deep/.my-bullet-active {
    background-color: #ffcc69;
  }
}
</style>
