import { setLocal, setSession, getLocal, getSession } from "@/libs/storage"

const state = {
  cookieTip: getLocal("cookieTip") ? getLocal("cookieTip") : getSession("cookieTip") ? getSession("cookieTip") : false,
}

const mutations = {
  cookieTip(state, status) {
    state.cookieTip = true
    if (status === "REFUSE") {
      setSession("cookieTip", true)
    }
    if (status === "ACCEPT") {
      setLocal("cookieTip", true)
    }
  },
}

const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
