<template>
    <div>
        <English v-if="$i18n.locale == 'zh-EN'" />
        <Chinese v-if="$i18n.locale == 'zh-CH'" />
    </div>
</template>

<script>
import English from "./English"
import Chinese from "./Chinese"
export default {
    data() {
        return{
            
        }
    },

    components: {
        English, Chinese
    },
    mounted(){
        this.$store.dispatch('getSuperYachtsList')
    }
}
</script>


