import { getTeamList } from '@/api';
import { success, fail, failMsg } from '@/libs/util';
const state = {
  teamList: [],
};

const actions = {
  async getTeamList({ commit }, context) {
    try {
      let data = await getTeamList(context);
      commit('GETTEAMLIST', data.data);
    } catch (error) {
      failMsg(error);
      return Promise.reject();
    }
  },
};

const mutations = {
  GETTEAMLIST(state, params) {
    state.teamList = params;
  },
};

export default {
  state,
  actions,
  mutations,
};
