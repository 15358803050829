<template>
    <div>
        <English v-if="$i18n.locale == 'zh-EN'" />
        <Chinese v-if="$i18n.locale == 'zh-CH'" />
        <PayType />
    </div>
</template>

<script>
import English from "./English"
import Chinese from "./Chinese"
import PayType from "./PayType/index.vue"
export default {
    data() {
        return {}
    },
    components: {
        English,
        Chinese,
        PayType,
    },
    mounted() {},
}
</script>

<style lang="less" scoped></style>
