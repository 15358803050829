<template>
    <div>
        <div class="headline">
            <div class="left-a"></div>
            <p>现在添加娱乐项目</p>
            <div class="right-a"></div>
        </div>
        <div class="line">
            <span>您的预订</span>
        </div>
        <div class="form">
            <div class="img">
                <img :src="userInfo.pictureUrl" alt="" />
            </div>
            <div class="container">
                <div class="title">{{ userInfo.productName }}</div>
                <div class="explain">
                    <div>
                        <span>长度</span>
                        <span>{{ userInfo.length }} 英尺</span>
                    </div>
                    <div>
                        <span>容量</span>
                        <span>最多 {{ userInfo.maxPassenger }} 人</span>
                    </div>
                    <div>
                        <span>船员</span>
                        <span>{{ userInfo.crewNum }}+{{ userInfo.captainNum }}</span>
                    </div>
                </div>
                <div class="inputMsg">
                    <div class="date">
                        <div class="qz">出发日期</div>
                        <div>
                            <input
                                type="text"
                                readonly
                                :value="
                                    dayjs(userInfo.date).format('YYYY年MM月DD日') +
                                    '  ' +
                                    this.weekCh[new Date(userInfo.date).getDay()] +
                                    '  ' +
                                    (userInfo.time ? userInfo.time : '')
                                "
                            />
                        </div>
                    </div>
                    <div class="Duration">
                        <div class="qz">持续时间</div>
                        <div>
                            <input
                                type="text"
                                readonly
                                :value="userInfo.hour ? userInfo.hour + '个小时' : '请先选择持续时间'"
                            />
                        </div>
                    </div>
                    <div class="Departure-Point">
                        <div class="qz">出发地点</div>
                        <div>
                            <input type="text" readonly :value="userInfo.berth" />
                        </div>
                    </div>
                    <div class="Adult-Childs">
                        <div class="qz">成人/儿童</div>
                        <div>
                            <input type="text" readonly v-model="userInfo.adult" />
                            <input type="text" readonly v-model="userInfo.child" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="line">
            <span>船上餐饮</span>
        </div>
        <div class="menu">
            <div class="container">
                <div class="title-list">
                    <ul>
                        <li>菜单</li>
                        <li>详情</li>
                        <li>价格</li>
                        <li>数量</li>
                    </ul>
                </div>
                <div class="msg-list">
                    <ul>
                        <li
                            style="border-bottom: 1px solid #ccc"
                            v-for="(item, index) in $store.state.order.yachtsMenu"
                            :key="index"
                        >
                            <div>
                                <van-checkbox
                                    v-model="item.checked"
                                    class="checkbox"
                                    checked-color="#ffcc69"
                                    icon-size="10px"
                                    @change="menusCheckedChange(item.checked, item.number, item.id)"
                                ></van-checkbox>
                                <span class="photo" @click="showPhoto(item.imageUrl)">
                                    <img src="../images/photo.png" alt="" />
                                </span>
                                <span class="name">{{ item.menuName }}</span>
                            </div>
                            <div class="textHidden">{{ item.detailDescribe }}</div>
                            <div>AED {{ item.price }}<span v-if="item.priceType == 1">pp</span></div>
                            <div>
                                <div
                                    class="selectBox"
                                    @click="menusNumChange(item.checked, item.minNumber, item.maxNumber, item.id)"
                                >
                                    <input type="text" readonly v-model="item.number" />
                                    <i class="arrow-down"><van-icon name="arrow-down" /></i>
                                </div>
                                <!-- <select
                  name=""
                  id=""
                  class="select"
                  style="border: 1px solid #ccc"
                  v-model="item.number"
                  @change="menusNumChange(item.checked, item.number, item.id)"
                >
                  <option
                    :value="i + (item.minNumber ? item.minNumber : 1)"
                    v-for="(v, i) in item.minNumber
                      ? item.maxNumber - item.minNumber + 1
                      : item.maxNumber"
                    :key="i"
                  >
                    {{ item.minNumber ? item.minNumber + i : 1 + i }}
                  </option>
                </select> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="line SPORTS-TOYS">
            <span>水上运动和游艇玩具</span>
        </div>
        <div class="menu">
            <div class="container">
                <div class="title-list">
                    <ul>
                        <li>水上运动</li>
                        <li>单位</li>
                        <li>价格</li>
                        <li>数量</li>
                    </ul>
                </div>
                <div class="msg-list">
                    <ul>
                        <li
                            style="border-bottom: 1px solid #ccc"
                            v-for="(item, index) in $store.state.order.waterSports"
                            :key="index"
                        >
                            <div>
                                <van-checkbox
                                    v-model="item.checked"
                                    class="checkbox"
                                    checked-color="#ffcc69"
                                    icon-size="10px"
                                    @change="sportsCheckedChange(item.checked, item.number, item.id)"
                                ></van-checkbox>
                                <span class="photo" @click="showPhoto(item.imageUrl)">
                                    <img src="../images/photo.png" alt="" />
                                </span>
                                <span class="name">{{ item.serviceName }}</span>
                            </div>
                            <div style="padding-left: 0.72rem">{{ item.unit }}</div>
                            <div>AED {{ item.price }}</div>
                            <div>
                                <div
                                    class="selectBox"
                                    v-if="item.lockNumber == 0"
                                    @click="sportsNumChange(item.checked, item.maxNumber, item.id)"
                                >
                                    <input type="text" readonly v-model="item.number" />
                                    <i class="arrow-down"><van-icon name="arrow-down" /></i>
                                </div>
                                <!-- <select
                  name=""
                  id=""
                  class="select"
                  style="border: 1px solid #ccc"
                  v-model="item.number"
                  @change="sportsNumChange(item.checked, item.number, item.id)"
                  v-if="item.lockNumber == 0"
                >
                  <option
                    :value="index + 1"
                    v-for="(item, index) in item.maxNumber"
                    :key="index"
                  >
                    {{ index + 1 }}
                  </option>
                </select> -->
                                <span class="waterSelect" v-else>{{ item.defaultNumber }} 个小时</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="menu WATER-SPORTS">
            <div class="container">
                <div class="title-list">
                    <ul>
                        <li>游艇玩具</li>
                        <li>单位</li>
                        <li>价格</li>
                        <li>描述</li>
                    </ul>
                </div>
                <div class="msg-list">
                    <ul>
                        <li
                            style="border-bottom: 1px solid #ccc"
                            v-for="(item, index) in $store.state.order.yachtsToys"
                            :key="index"
                        >
                            <div>
                                <van-checkbox
                                    v-model="item.checked"
                                    class="checkbox"
                                    checked-color="#ffcc69"
                                    icon-size="10px"
                                    @change="toysCheckedChange(item.checked, item.number, item.id)"
                                ></van-checkbox>
                                <span class="photo" @click="showPhoto(item.imageUrl)">
                                    <img src="../images/photo.png" alt="" />
                                </span>
                                <span class="name">{{ item.serviceName }}</span>
                            </div>
                            <div style="padding-left: 0.72rem">{{ item.unit }}</div>
                            <div>AED {{ item.price }}</div>
                            <div>
                                <!-- <select
                  name=""
                  id=""
                  class="select"
                  style="border: 1px solid #ccc"
                  v-model="item.number"
                  @change="toysNumChange(item.checked, item.number, item.id)"
                  v-if="item.lockNumber == 0"
                >
                  <option :value="index + 1" v-for="(item, index) in item.maxNumber" :key="index">
                    {{ index + 1 }}
                  </option>
                </select> -->
                                <span class="toysSelect toysDetail">{{
                                    item.detailDescribe ? item.detailDescribe : "- - - -"
                                }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="line">
            <span>预订统计</span>
        </div>
        <div class="BOOKING-SUMMARY">
            <div>
                <span class="qz">姓名</span>
                <div>
                    <input type="text" readonly v-model="userInfo.name" />
                </div>
            </div>
            <div class="phone">
                <span class="qz">手机号码</span>
                <div>
                    <input type="text" readonly :value="'+ ' + userInfo.qz" />
                    <input type="text" readonly v-model="userInfo.phone" />
                </div>
            </div>
            <div>
                <span class="qz">电子邮箱</span>
                <div>
                    <input type="text" v-model="userInfo.email" />
                </div>
            </div>
            <div class="Adult-Childs">
                <span class="qz">成人/儿童</span>
                <div>
                    <input type="text" readonly v-model="userInfo.adult" />
                    <input type="text" readonly v-model="userInfo.child" />
                </div>
            </div>
            <div>
                <span class="qz">出发日期</span>
                <div>
                    <input
                        readonly
                        type="text"
                        :value="
                            dayjs(userInfo.date).format('YYYY年MM月DD日') +
                            ' ' +
                            this.weekCh[new Date(userInfo.date).getDay()]
                        "
                    />
                </div>
            </div>
            <div>
                <span class="qz">出发时间</span>
                <div @click="showTime">
                    <input type="text" readonly :value="userInfo.time ? userInfo.time : '请先选择出发时间'" />
                </div>
            </div>
            <div>
                <span class="qz">持续时间</span>
                <div @click="showHours">
                    <input
                        type="text"
                        readonly
                        :value="userInfo.hour ? userInfo.hour + '个小时' : '请先选择持续时间'"
                    />
                </div>
            </div>
            <div>
                <span class="qz">出发位置</span>
                <div>
                    <input readonly type="text" :value="userInfo.berth" />
                </div>
            </div>
        </div>
        <div class="total">
            <ul>
                <li>
                    <span>租赁费用</span>
                    <span>AED {{ payTotal.charter }}</span>
                </li>
                <li>
                    <span>餐饮费用</span>
                    <span>AED {{ payTotal.catering }}</span>
                </li>
                <li>
                    <span>额外费用</span>
                    <span>AED {{ payTotal.extras }}</span>
                </li>
                <li>
                    <span>总额 (含5%增值税)</span>
                    <span>AED {{ payTotal.total }}</span>
                </li>
            </ul>
            <div class="agree">
                <div>
                    <van-checkbox v-model="serviceChecked" shape="square" icon-size="10px" checked-color="#ffcc69">
                        <span class="text"
                            >我已阅读并同意 <a @click.prevent="serviceShow" class="agreeColor">《服务条款》</a></span
                        >
                    </van-checkbox>
                    <van-checkbox v-model="cancellationChecked" shape="square" icon-size="10px" checked-color="#ffcc69">
                        <span class="text"
                            >我已阅读并同意 <a @click.prevent="cancelShow" class="agreeColor">《撤销政策》</a></span
                        >
                    </van-checkbox>
                </div>
            </div>
            <div class="pay">
                <div>安全支付</div>
                <div>
                    <img src="../images/ssl-cards-telr.png" alt="" />
                </div>
            </div>
        </div>
        <div class="bottom-text" @click="payment">
            <img src="../images/iconB.png" alt="" />
            <p>继续结算</p>
        </div>
        <van-image-preview v-model="show" :images="images" />
        <!-- 选择持续时间 -->
        <diyPick
            :list="hoursOptions.list"
            :show="hoursOptions.show"
            :title="hoursOptions.title"
            :columns="hoursColumns"
            @unShow="unShow"
            @confirm="hourConfirm"
        />
        <!-- 选择出发时间 -->
        <diyPick
            :list="timeOptions.list"
            :show="timeOptions.show"
            :title="timeOptions.title"
            :columns="timeColumns"
            @unShow="unShow"
            @confirm="timeConfirm"
        />
        <!-- 餐饮选择套餐数量 -->
        <diyPick
            :list="countMenusOptions.list"
            :show="countMenusOptions.show"
            :title="countMenusOptions.title"
            :columns="countMenusColumns"
            @unShow="unShow"
            @confirm="countMenusConfirm"
        />
        <!-- 水上运动选择套餐数量 -->
        <diyPick
            :list="countSportsOptions.list"
            :show="countSportsOptions.show"
            :title="countSportsOptions.title"
            :columns="countSportsColumns"
            @unShow="unShow"
            @confirm="countSportsConfirm"
        />
        <!-- 服务条款 -->
        <van-popup v-model="serviceDialog"
            ><trems></trems> <img src="../images/close.png" alt="" class="close" @click="closeAgree"
        /></van-popup>
        <!-- 取消政策 -->
        <van-popup v-model="cancelDialog">
            <cancel></cancel>
            <img src="../images/close.png" alt="" class="close" @click="closeAgree" />
        </van-popup>
    </div>
</template>

<script>
import diyPick from "@/components/diyPick/diyPick.vue"
import trems from "../trems.vue"
import cancel from "../cancel.vue"
export default {
    data() {
        return {
            serviceChecked: false,
            cancellationChecked: false,
            userInfo: {},
            images: [],
            show: false,
            cateringComboPackage: [],
            specialServicePackage: [],
            payTotal: {},
            // HOURS
            hoursOptions: {
                title: "请选择你的持续时间",
                list: {
                    小时: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                },
                show: false,
                value: "",
            },
            // TIME
            timeOptions: {
                title: "请选择你的租赁时间",
                list: {
                    上午: [6, 7, 8, 9, 10, 11, 12],
                    下午: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                },
                show: false,
                value: "",
            },
            // quantity
            countMenusOptions: {
                title: "请选择您的餐饮数目",
                list: { 份: [] },
                show: false,
                value: "",
            },
            countSportsOptions: {
                title: "请选择您的水上运动项目数目",
                list: { 份: [] },
                show: false,
                value: "",
            },
            params: {},
            weekCh: ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
            state: true,
            // 用户协议弹窗
            serviceDialog: false,
            // 取消条款弹窗
            cancelDialog: false,
            // 用于改变套餐store中的number
            menusId: "",
            sportsId: "",
        }
    },
    components: {
        diyPick,
        trems,
        cancel,
    },
    created() {
        // console.log(this.$route.meta);
        // 跳转到这个页面时去拿到用户信息进行渲染
        this.userInfo = { ...this.$store.state.home.userOrderInfo }
        if (this.userInfo.showTime && String(this.userInfo.time).indexOf("点") == -1) {
            this.userInfo.time =
                this.userInfo.showTime.indexOf("A") != -1
                    ? "上午" + this.userInfo.time + "点"
                    : "下午" + this.userInfo.time + "点"
            // console.log(this.userInfo.time);
        }
        // 获取当前游艇详情
        this.$store.dispatch("getYachtsDetail", {
            productId: this.userInfo.productId,
            passengerNum: +this.userInfo.adult + +this.userInfo.child,
            takeOrderType: this.userInfo.takeOrderType,
            rentDate: this.userInfo.rentDate,
        })
        // 计算未选中套餐时的金额
        if (!this.userInfo.time || !this.userInfo.hour) {
            this.$toast(this.$t("bookOrder.enterToast"))
        } else {
            this.timeOptions.value = String(this.userInfo.showTime)
            this.hoursOptions.value = this.userInfo.hour
            this.calcYachtOrderTotal()
        }
    },
    computed: {
        timeColumns() {
            let a = [
                {
                    values: this.timeOptions.list["上午"],
                    className: "column2",
                    defaultIndex: 2,
                },
                {
                    values: Object.keys(this.timeOptions.list),
                    className: "column1",
                },
            ]
            return a
        },
        hoursColumns() {
            let a = [
                {
                    values: this.hoursOptions.list["小时"],
                    className: "column2",
                    defaultIndex: 2,
                },
                {
                    values: Object.keys(this.hoursOptions.list),
                    className: "column1",
                },
            ]
            return a
        },
        countMenusColumns() {
            let a = [
                {
                    values: this.countMenusOptions.list["份"],
                    className: "column2",
                    defaultIndex: 2,
                },
                {
                    values: Object.keys(this.countMenusOptions.list),
                    className: "column1",
                },
            ]
            return a
        },
        countSportsColumns() {
            let a = [
                {
                    values: this.countSportsOptions.list["份"],
                    className: "column2",
                    defaultIndex: 2,
                },
                {
                    values: Object.keys(this.countSportsOptions.list),
                    className: "column1",
                },
            ]
            return a
        },
    },
    methods: {
        serviceShow() {
            this.serviceDialog = true
        },
        cancelShow() {
            this.cancelDialog = true
        },
        closeAgree() {
            this.cancelDialog = false
            this.serviceDialog = false
        },
        // 控件显示操作
        showHours() {
            this.hoursOptions.show = true
        },
        showTime() {
            this.timeOptions.show = true
        },
        showPhoto(url) {
            this.images = [url]
            this.show = true
        },
        // 关闭pick 和 time 控件
        unShow() {
            this.timeOptions.show = false
            this.hoursOptions.show = false
            this.countMenusOptions.show = false
            this.countSportsOptions.show = false
        },
        timeConfirm(value) {
            let text = value[1] + "" + value[0] + "点"
            this.userInfo.showTime = text.indexOf("上午") != -1 ? value[0] + "AM" : value[0] + "PM"
            this.$set(this.timeOptions, "value", String(this.userInfo.showTime))
            this.$store.commit("getUserOrderInfo", {
                ...this.userInfo,
            })
            this.userInfo.time = text
            this.calcYachtOrderTotal()
        },
        hourConfirm(value) {
            // let text = value[0] + ' ' + value[1];
            this.userInfo.hour = value[0]
            this.$set(this.hoursOptions, "value", value[0])
            this.$store.commit("getUserOrderInfo", {
                ...this.userInfo,
            })
            this.calcYachtOrderTotal()
        },
        countMenusConfirm(value) {
            this.$store.state.order.yachtsMenu.forEach(item => {
                if (item.id == this.menusId) {
                    item.number = value[0]
                }
            })
            this.cateringComboPackage.forEach(item => {
                if (item.id == this.menusId) {
                    item.number = value[0]
                }
            })
            this.$set(this.countMenusOptions, "value", value[0])
            this.calcYachtOrderTotal()
        },
        countSportsConfirm(value) {
            this.$store.state.order.waterSports.forEach(item => {
                if (item.id == this.sportsId) {
                    item.number = value[0]
                }
            })
            this.specialServicePackage.forEach(item => {
                if (item.id == this.sportsId) {
                    item.number = value[0]
                }
            })
            this.$set(this.countSportsOptions, "value", value[0])
            this.calcYachtOrderTotal()
        },
        getRentalYachtsList() {
            let passengerNum = this.personOptions.value === "" ? 1 : this.personOptions.value
            let rentDate =
                this.dateOptions.value === ""
                    ? this.dayjs(new Date(rentDate)).format("YYYY-MM-DD")
                    : this.dateOptions.value
        },
        // 选择餐饮套餐发请求
        menusNumChange(checked, minNumber, maxNumber, id) {
            this.countMenusOptions.show = true
            this.menusId = id
            let array = []
            for (let index = 0; index < (minNumber ? maxNumber - minNumber + 1 : maxNumber); index++) {
                array.push(minNumber ? minNumber + index : 1 + index)
            }
            this.countMenusOptions.list["份"] = array
            // 只有当选中的时候才去发请求
            if (checked) {
                // 判断数量变化
                this.cateringComboPackage.forEach(item => {
                    if (item.id == id) {
                        item.number = this.countMenusOptions.value
                    }
                })
                // 调用金额请求函数
                this.calcYachtOrderTotal()
            }
        },
        menusCheckedChange(checked, number, id) {
            if (checked) {
                this.cateringComboPackage.push({ id, number })
            } else {
                this.cateringComboPackage = this.cateringComboPackage.filter(item => item.id !== id)
            }
            this.calcYachtOrderTotal()
        },
        // 选择水上运动套餐发请求
        sportsNumChange(checked, maxNumber, id) {
            this.countSportsOptions.show = true
            this.sportsId = id
            let array = []
            for (let index = 0; index < maxNumber; index++) {
                array.push(index + 1)
            }
            this.countSportsOptions.list["份"] = array
            if (checked) {
                this.specialServicePackage.forEach(item => {
                    if (item.id == id) {
                        item.number = this.countSportsOptions.value
                    }
                })
                this.calcYachtOrderTotal()
            }
        },
        sportsCheckedChange(checked, number, id) {
            if (checked) {
                this.specialServicePackage.push({ id, number })
            } else {
                this.specialServicePackage = this.specialServicePackage.filter(item => item.id !== id)
            }
            this.calcYachtOrderTotal()
        },
        // 选择玩具套餐发请求
        // toysNumChange(checked, number, id) {
        //   if (checked) {
        //     this.specialServicePackage.forEach((item) => {
        //       if (item.id == id) {
        //         item.number = number;
        //       }
        //     });
        //     this.calcYachtOrderTotal();
        //   }
        // },
        toysCheckedChange(checked, number, id) {
            if (checked) {
                this.specialServicePackage.push({ id, number })
            } else {
                this.specialServicePackage = this.specialServicePackage.filter(item => item.id !== id)
            }
            this.calcYachtOrderTotal()
        },
        // 发送计算金额请求
        async calcYachtOrderTotal() {
            this.params = {
                productId: this.userInfo.productId,
                adultNum: +this.userInfo.adult,
                childNum: +this.userInfo.child,
                takeOrderType: this.userInfo.takeOrderType,
                rentType: this.userInfo.rentType,
                rentDate: this.dayjs(this.userInfo.date).format("YYYY-MM-DD"),
            }
            if (this.timeOptions.value.indexOf("AM") != -1) {
                this.params["beginTime"] = `${
                    this.timeOptions.value.split("A")[0] > 9
                        ? this.timeOptions.value.split("A")[0]
                        : "0" + this.timeOptions.value.split("A")[0]
                }:00`
                this.params["endTime"] = `${
                    +this.timeOptions.value.split("A")[0] + +this.hoursOptions.value > 9
                        ? +this.timeOptions.value.split("A")[0] + +this.hoursOptions.value
                        : "0" + (+this.timeOptions.value.split("A")[0] + +this.hoursOptions.value)
                }:00`
            }
            if (this.timeOptions.value.indexOf("PM") != -1) {
                this.params["beginTime"] = `${+this.timeOptions.value.split("P")[0] + 12}:00`
                this.params["endTime"] = `${+this.timeOptions.value.split("P")[0] + 12 + this.hoursOptions.value}:00`
            }
            if (
                +this.timeOptions.value.split("P")[0] + 12 + this.hoursOptions.value >= 24 ||
                +this.timeOptions.value.split("A")[0] + +this.hoursOptions.value >= 24
            ) {
                this.$toast.fail(this.$t("bookOrder.error"))
                return
            }
            if (this.cateringComboPackage.length > 0) {
                this.params["cateringComboPackage"] = JSON.stringify(this.cateringComboPackage)
            }
            if (this.specialServicePackage.length > 0) {
                this.params["specialServicePackage"] = JSON.stringify(this.specialServicePackage)
            }
            //   console.log(this.params);
            try {
                await this.$store.dispatch("calcYachtOrderTotal", this.params)
            } catch (error) {}
            // 每次请求后拿到最新的价钱
            this.payTotal = this.$store.state.order.calcYachtOrderTotal
        },
        // 点击支付时调用
        async payment() {
            // 判断是否勾选了协议
            if (this.serviceChecked == false || this.cancellationChecked == false) {
                this.$toast(this.$t("bookOrder.agree"))
            } else if (this.timeOptions.value == "" || this.hoursOptions.value == "") {
                this.$toast(this.$t("bookOrder.enterToast"))
            } else if (
                +this.timeOptions.value.split("P")[0] + 12 + this.hoursOptions.value >= 24 ||
                +this.timeOptions.value.split("A")[0] + +this.hoursOptions.value >= 24
            ) {
                this.$toast.fail(this.$t("bookOrder.error"))
            } else {
                let data = this.params
                data.realName = this.userInfo.name
                data.mobileNum = this.userInfo.qz + "-" + this.userInfo.phone
                data.email = this.userInfo.email
                let orderMemberInfoCmds = []
                for (let i = 1; i <= +this.userInfo.adult; i++) {
                    orderMemberInfoCmds.push({
                        memberName: "Unknown Name",
                        memberType: 1,
                        memberState: 0,
                    })
                }
                for (let i = 1; i <= +this.userInfo.child; i++) {
                    orderMemberInfoCmds.push({
                        memberName: "Unknown Name",
                        memberType: 2,
                        memberState: 0,
                    })
                }
                data.orderMemberInfoCmds = JSON.stringify(orderMemberInfoCmds)
                if (this.state) {
                    this.state = false
                    try {
                        await this.$store.dispatch("addYachtsOrder", data)
                        this.state = true
                    } catch (error) {
                        this.state = true
                    }
                }
                // this.$toast.success('支付成功');
            }
        },
    },
    destroyed() {
        // localStorage.removeItem('userOrderInfo');
        // this.$store.commit('removeUserInfo');
        // this.timeOptions.value = '';
        // this.hoursOptions.value = '';
        this.$toast.clear()
    },
}
</script>

<style lang="less" scoped>
.headline {
    background-color: #020b3b;
    height: 64px;
    width: 460px;
    margin: 0 auto;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    .left-a {
        width: 64px;
        height: 64px;
        background: linear-gradient(135deg, #fff, #fff 50%, #020b3b 50%, #020b3b 100%);
    }

    .right-a {
        width: 64px;
        height: 64px;
        background: linear-gradient(45deg, #020b3b, #020b3b 50%, #fff 50%, #fff 100%);
    }

    & > p {
        text-align: center;
        line-height: 64px;
        font-family: Arial-BoldMT;
        font-size: 30px;
        color: #e4e4e6;
    }
}

.line {
    width: 710px;
    height: 44px;
    background-color: #020b3b;
    border-radius: 22px 0px 0px 22px;
    margin: 20px auto;
    position: relative;
    padding-left: 60px;
    box-sizing: border-box;

    span {
        line-height: 40px;
        color: #e4e4e6;
        font-size: 26px;
        font-family: Arial-BoldMT;
        font-weight: bold;
        display: 500;
    }
}

.line::before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #ffcc69;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.form {
    width: 710px;
    margin: 0 auto;
    margin-bottom: 20px;

    .img {
        height: 400px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }

    .container {
        background-color: #ffcc69;
        padding: 0 20px;

        .title {
            text-align: center;
            font-family: Arial-BoldMT;
            font-size: 36px;
            color: #333333;
            padding: 16px 0;
            font-weight: bold;
        }

        .explain {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;

            & > div {
                background-color: #020b3b;
                font-family: Arial-BoldMT;
                font-size: 24px;
                color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 80px;
                padding: 10px;
                box-sizing: border-box;
                & > span {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    text-align: center;
                    display: block;
                }
            }
            // & > span {
            //   white-space: nowrap;
            //   text-overflow: ellipsis;
            //   overflow: hidden;
            //   width: 270px;
            //   text-align: center;
            // }

            & > div:nth-child(1) {
                width: 270px;
            }

            & > div:nth-child(2) {
                width: 220px;
            }

            & > div:nth-child(3) {
                width: 170px;
            }
        }

        .inputMsg {
            & > div {
                display: flex;
                margin-bottom: 18px;

                .qz {
                    width: 180px;
                    text-align: center;
                    line-height: 40px;
                    background-color: #020b3b;
                    font-family: Arial-BoldMT;
                    font-size: 22px;
                    color: #fff;
                    padding-right: 14px;
                    box-sizing: border-box;
                    margin-right: 12px;
                }

                & > div:nth-child(2) {
                    flex: 1;

                    input {
                        padding-left: 15px;
                        line-height: 40px;
                        width: 100%;
                    }
                }
            }

            .Adult-Childs {
                & > div:nth-child(2) {
                    display: flex;
                    justify-content: space-between;

                    input {
                        width: 49%;
                    }
                }
            }
        }
    }
}

.menu {
    width: 710px;
    margin: 0 auto;

    .container {
        .title-list {
            border-bottom: 1px solid #ccc;
            margin-bottom: 15px;
            padding: 0 20px;

            ul {
                display: flex;
                justify-content: space-between;

                & > li {
                    font-family: Arial-BoldMT;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 60px;
                    color: #333333;
                }

                & > li:nth-child(1) {
                    width: 350px;
                }

                & > li:nth-child(2) {
                    width: 190px;
                    margin-left: 20px;
                }

                & > li:nth-child(3) {
                    width: 130px;
                }

                & > li:nth-child(4) {
                    text-align: end;
                    width: 135px;
                }
            }
        }

        .msg-list {
            ul {
                & > li {
                    display: flex;
                    justify-content: space-between;

                    & > div {
                        display: flex;
                        align-items: center;
                        height: 50px;

                        .checkbox {
                            .van-icon {
                                border: 1px solid #ffcc69 !important;
                            }
                        }

                        .photo {
                            width: 22px;
                            margin-left: 20px;
                            margin-right: 14px;

                            img {
                                width: 100%;
                            }
                        }

                        .name {
                            display: block;
                            width: 180px;
                            font-family: Arial-ItalicMT;
                            font-size: 22px;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .waterSelect {
                            font-size: 22px;
                            color: #333333;
                        }
                        .toysSelect {
                            font-size: 20px;
                            color: #333333;
                        }
                        .toysDetail {
                            display: block;
                            width: 100px;
                            text-align: center;
                            margin-left: 0px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    & > div:nth-child(1) {
                        width: 305px;
                        font-size: 22px;
                        color: #333333;
                        font-family: Arial-ItalicMT;
                        display: flex;
                        align-items: center;
                        padding-left: 10px;
                        box-sizing: border-box;

                        span {
                            display: block;

                            img {
                                display: block;
                            }
                        }
                    }

                    & > div:nth-child(2) {
                        width: 140px;
                        font-family: ArialMT;
                        font-size: 20px;
                        color: #333333;
                        margin-left: 18px;
                        padding-top: 12px;
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    & > div:nth-child(3) {
                        width: 130px;
                        font-family: Arial-BoldMT;
                        font-size: 20px;
                        color: #333333;
                        font-weight: bold;
                        margin-left: 60px;
                        padding-top: 10px;
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    & > div:nth-child(4) {
                        display: flex;
                        justify-content: flex-end;
                        width: 135px;
                        padding-right: 10px;
                        box-sizing: border-box;

                        .select {
                            display: block;
                            width: 78px;
                            line-height: 28px;
                            background-color: transparent;
                            font-family: ArialMT;
                            font-size: 20px;
                            color: #333333;
                            outline: none;
                        }
                        P {
                            text-align: center;
                            flex: 1;
                            font-family: ArialMT;
                            font-size: 20px;
                            color: #333333;
                        }
                    }
                }
            }
        }
    }
}

.WATER-SPORTS {
    margin-top: 30px;
}

.BOOKING-SUMMARY {
    background-color: #020b3b;
    width: 710px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 10px;
    & > div {
        display: flex;
        margin-bottom: 10px;
        .qz {
            display: block;
            width: 230px;
            height: 41px;
            background-color: #ffcc69;
            line-height: 41px;
            text-align: right;
            padding-right: 10px;
            box-sizing: border-box;
            font-family: Arial-BoldMT;
            font-size: 24px;
            color: #333333;
            margin-right: 10px;
            font-weight: 500;
        }
        & > div {
            display: flex;
            flex: 1%;
            input {
                width: 100%;
                display: block;
                padding-left: 15px;
            }
        }
    }
    .phone {
        & > div {
            & > input:first-child {
                width: 136px;
                margin-right: 10px;
            }
            & > input:last-child {
                flex: 1;
            }
        }
    }
    .Adult-Childs {
        & > div {
            justify-content: space-between;
            input {
                width: 49%;
            }
        }
    }
}

.total {
    width: 710px;
    margin: 0 auto;
    background-color: #020b3b;
    margin-top: 10px;
    padding: 25px 50px;
    box-sizing: border-box;
    ul {
        li {
            display: flex;
            justify-content: space-between;
            height: 45px;
            align-items: center;
            span {
                font-family: Arial-BoldMT;
                font-size: 20px;
                color: #ffffff;
                font-weight: bold;
            }
        }
        & > li:last-child {
            span {
                color: #ffcc69;
            }
        }
    }
    .agree {
        margin-top: 10px;
        input {
            border: 1px solid #ffcc69;
            width: 20px;
            height: 20px;
            background-color: #fff;
        }
        /deep/.text {
            font-family: ArialMT;
            font-size: 20px;
            color: #ffffff;
            display: block;
        }
        /deep/.van-checkbox__label {
            color: #fff;
        }
        /deep/.van-icon {
            background-color: #fff;
            border: 1px solid #fff;
        }
        .agreeColor {
            color: #ffcc69;
        }
    }
    .pay {
        display: flex;
        align-items: center;
        font-family: ArialMT;
        font-size: 20px;
        color: #ffffff;
        margin-top: 20px;
        img {
            width: 171px;
            margin-left: 10px;
        }
    }
}

.bottom-text {
    width: 710px;
    height: 90px;
    background-color: #020b3b;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial-BoldMT;
    font-size: 36px;
    color: #ffcc69;
    img {
        width: 36px;
        margin-right: 10px;
    }
}
.close {
    bottom: -100px;
    display: block;
    width: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
/deep/.van-popup {
    overflow: visible;
}
.selectBox {
    width: 78px;
    height: 34px;
    line-height: 34px;
    font-family: ArialMT;
    font-size: 20px;
    color: #333333;
    outline: none;
    position: relative;
    input {
        width: 78px;
        height: 34px;
        border: solid 1px #ccc;
        padding-left: 8px;
    }
    .arrow-down {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 8px;
        background-color: #fff;
        /deep/.van-icon-arrow-down {
            font-weight: 700;
        }
    }
}
</style>
