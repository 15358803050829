import Vue from "vue"
import VueI18n from "vue-i18n"
import { getLocal } from "@/libs/storage"
import { getSysLang } from "@/libs/wxh_util"
Vue.use(VueI18n)

import en from "./en"
import cn from "./ch"

let defaultLang
if (localStorage.getItem("langType")) {
    defaultLang = localStorage.getItem("langType")
} else {
    defaultLang = getSysLang() === "zh" ? "zh-CH" : "zh-EN"
}

const i18n = new VueI18n({
    locale: defaultLang, // 语言标识,
    messages: {
        "zh-EN": {
            ...en,
        },
        "zh-CH": {
            ...cn,
        },
    },
})

export default i18n
