<template>
    <div>
        <div class="msg-list">
            <ul>
                <li :class="{active: item.show }" v-for="(item,index) in faqlist" :key="index" >
                    <van-popover
                            v-model="item.show"
                            trigger="click"
                            get-container=".msg-list"
                    >
                        <div class="box">
                            {{item.answer}}
                        </div>
                        <template #reference>
                            <div style="width:100%">
                                {{item.question}}
                            </div>
                        </template>
                    </van-popover>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                indexMsg:'',
                faqlist: []
            }
        },
        methods:{
            init() {
                //获取问题列表
                this.getFaqList();
            },
            getFaqList() {
                this.faqlist = [
                    {
                        question: this.$t('waterQuestion.surf_question1'),
                        answer: this.$t('waterQuestion.surf_answer1'),
                    },
                    {
                        question: this.$t('waterQuestion.surf_question2'),
                        answer: this.$t('waterQuestion.surf_answer2'),
                    },
                    {
                        question: this.$t('waterQuestion.surf_question3'),
                        answer: this.$t('waterQuestion.surf_answer3'),
                    },
                    {
                        question: this.$t('waterQuestion.surf_question4'),
                        answer: this.$t('waterQuestion.surf_answer4'),
                    },
                    {
                        question: this.$t('waterQuestion.surf_question5'),
                        answer: this.$t('waterQuestion.surf_answer5'),
                    },
                    {
                        question: this.$t('waterQuestion.surf_question6'),
                        answer: this.$t('waterQuestion.surf_answer6'),
                    },
                    {
                        question: this.$t('waterQuestion.surf_question7'),
                        answer: this.$t('waterQuestion.surf_answer7'),
                    },
                ];
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="less" scoped>
    .msg-list{
        background-color: #020B3B;
        padding: 22px 0 40px 0;
        ul{
            li{
                width: 710px;
                margin: 0 auto;
                background-color: #fff;

                line-height: 38px;
                margin-bottom: 14px;
                padding-left: 20px;
                box-sizing: border-box;
                .van-popover__wrapper{
                    width: 100%;
                }
            }
        }
        .box{
            width: 390px !important;
            height: 171px !important;
            background-color: #FFCC69 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0px 20px;
        }
        .active{
            background-color: #FFCC69;
        }
    }
</style>
