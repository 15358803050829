<template>
  <div>
    <van-popup v-model="$store.state.menu.aboutShow">
      <div class="about-us">
        <div class="title" v-if="$i18n.locale == 'zh-EN'">About us</div>
        <div class="title-chinese title" v-else-if="$i18n.locale == 'zh-CH'">
          关于我们
        </div>
        <p
          class="text"
          style="text-align: center; line-height: 20px"
          v-if="$i18n.locale === 'zh-EN'"
        >
          {{ $store.state.baseConfig.baseConfigInfo.aboutUs }}
        </p>
        <p
          class="text chinese"
          style="text-align: center"
          v-if="$i18n.locale === 'zh-CH'"
        >
          {{ $store.state.baseConfig.baseConfigInfo.aboutUs }}
        </p>
        <div class="img1">
          <img src="./images/tripadviso.png" alt="" />
        </div>
        <div class="img2">
          <img src="./images/trustpilot.png" alt="" />
        </div>
        <img
          src="./images/close.png"
          alt=""
          class="close"
          @click="closeAbout"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  methods: {
    closeAbout() {
      this.$store.commit('ABOUT');
    },
  },
};
</script>

<style lang="less" scoped>
.about-us {
  width: 710px;
  padding-bottom: 110px;
  position: relative;
  .chinese {
    font-family: SourceHanSansCN-Regular;
    font-size: 28px;
    line-height: 50px;
    color: #666666;
    padding: 0 30px !important;
  }
  .title {
    font-family: Bintang;
    font-size: 60px;
    color: #333333;
    padding: 100px 0 50px 0;
    text-align: center;
  }
  .title-chinese {
    font-family: SourceHanSansCN-Bold;
    font-size: 36px;
    color: #333333;
  }
  img {
    width: 100%;
  }
  .img1 {
    width: 110px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .img2 {
    width: 294px;
    margin: 0 auto;
  }

  .close {
    bottom: -100px;
    display: block;
    width: 35px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  .text {
    padding: 0 20px;
    margin: 20px;
    height: 400px;
    overflow-y: scroll;
  }

  .text::-webkit-scrollbar {
    width: 10px;
    height: 13px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .text::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 28px;
  }

  .text::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}
/deep/.van-popup {
  overflow: visible;
}
</style>
