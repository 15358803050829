<template>
    <div>
        <English v-if="$i18n.locale == 'zh-EN'" />
        <Chinese v-if="$i18n.locale == 'zh-CH'" />
    </div>
</template>

<script>
import English from "./English"
import Chinese from "./Chinese"
import { inserSeoElement } from "@/libs/util"
export default {
    data() {
        return {}
    },
    components: { English, Chinese },
    mounted() {
        inserSeoElement(
            "SKY WALKER YACHTS-Yacht Rental Dubai",
            "Yacht Rental Dubai,Charter Yacht Dubai,Luxury Yacht Charter,Yacht Booking Dubai,Rent Yacht Dubai",
            "Experience our luxurious yachts in Dubai. Click here to book now your private yacht rental in Dubai with a professional cabin crew.rent a yacht for cruises, fishing and watersports for best prices.Skywalker yachts offers a luxurious experience on board from our well maintained luxurious fleet. Schedule Your Trip. Book Now. Book Your Yacht Today. Call Us."
        )
    },
}
</script>

<style lang="less" scoped></style>
