import { getBaseConfig } from '@/api';
import { success, fail, failMsg } from '@/libs/util';
const state = {
  baseConfigInfo: {
    serviceMobileList: [],
  },
};

const actions = {
  async getBaseConfigInfo({ commit }, context) {
    try {
      let data = await getBaseConfig(context);
      commit('GETBASECONFIG', data.data);
    } catch (error) {
      failMsg(error);
      return Promise.reject();
    }
  },
};

const mutations = {
  GETBASECONFIG(state, params) {
    state.baseConfigInfo = params;
  },
};

export default {
  state,
  actions,
  mutations,
};
