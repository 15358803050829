<template>
    <div>
        <div v-if="yacthsList.length > 0" class="card-list">
            <div class="card-item" v-for="(item, index) in yacthsList" :key="index">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide class="slide" v-for="(it2, in2) in item.media" :key="in2">
                        <!-- <van-image @click="showImage(item.mediaArr, in2)" :src="it2.mediaUrl">
                            <template v-slot:error>fail to load</template>
                        </van-image> -->
                        <img
                            v-if="in2 === 0"
                            v-lazy="it2.mediaUrl"
                            @click="showImage(item.mediaArr, in2)"
                        />
                        <img
                            v-else
                            :data-src="it2.mediaUrl"
                            class="swiper-lazy"
                            @click="showImage(item.mediaArr, in2)"
                            @error="imgError"
                        />
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                </swiper>
                <div class="description">
                    <p class="title textOhiden">{{ item.productName }}</p>
                    <p class="textOhiden">
                        <span>DEPARTURE：</span>
                        <span>{{ item.berth }}</span>
                    </p>
                    <p>
                        <span>CAPACITY：</span>
                        <span>{{ "UP TO " + item.maxPassenger + " PAX" }}</span>
                    </p>
                    <p>
                        <span>CREW：</span>
                        <span>{{ item.crewNum }}+{{ item.captainNum }}</span>
                    </p>
                </div>
                <div class="bool-btn" @click="bookShow(item)">BOOK</div>
                <div class="price">
                    <p>FROM ONLY</p>
                    <p>{{ "AED " + item.yachtsPrice }}</p>
                </div>
                <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            </div>
        </div>
        <Empty v-else />
        <div class="my-popup">
            <van-popup @close="close" v-model="msgShow">
                <div class="box">
                    <div class="bg">
                        <!-- <img :src="product.pictureUrl" alt="" /> -->
                        <van-image :src="product.pictureUrl">
                            <template v-slot:error>fail to load</template>
                        </van-image>
                        <div class="price">
                            <p>FROM ONLY</p>
                            <p>AED {{ product.yachtsPrice }}</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="title">{{ product.productName }}</div>
                        <div class="tag">
                            <div>
                                <p>LENGTH</p>
                                <p>{{ product.length }} FT</p>
                            </div>
                            <div>
                                <p>CAPACITY</p>
                                <p>UP TO {{ product.maxPassenger }} PAX</p>
                            </div>
                            <div>
                                <p>CREW</p>
                                <p>{{ product.crewNum }}+{{ product.captainNum }}</p>
                            </div>
                        </div>
                        <div class="form">
                            <div class="name">
                                <div class="msg">NAME</div>
                                <div class="input">
                                    <div>
                                        <input
                                            type="text"
                                            v-model="name"
                                            @click="focusM($event)"
                                            ref="aaa"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="phone">
                                <div class="msg">PHONE</div>
                                <div class="input">
                                    <div class="phoneCode" @click="showAreaCode">
                                        <input
                                            type="text"
                                            :value="qz ? '+ ' + qz : ''"
                                            v-number
                                            readonly
                                        />
                                        <i class="arrow-down"><van-icon name="arrow-down" /></i>
                                    </div>
                                    <div>
                                        <input type="text" v-model="phone" v-number />
                                    </div>
                                </div>
                            </div>
                            <div class="e-mail">
                                <div class="msg">E-MAIL</div>
                                <div class="input">
                                    <div>
                                        <input type="text" v-model="email" />
                                    </div>
                                </div>
                            </div>
                            <div class="adult-childs">
                                <div class="msg">ADULT/CHILD</div>
                                <div class="input">
                                    <div>
                                        <input type="text" v-model="adult" v-number />
                                    </div>
                                    <div>
                                        <input type="text" v-model="child" v-number />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn" @click="goOrder">PROCEED</div>
                    </div>
                </div>
            </van-popup>
        </div>
        <areaCode :show="show.areaCode" @unShow="closeAreaCode" @confirm="areaCodeConfirm" />
    </div>
</template>

<script>
import cardList from "@/mixin/home/cardList.js"
export default {
    mixins: [cardList],
    methods: {
        focusM(e) {
            this.$refs.aaa.focus()
        },
    },
}
</script>

<style lang="less" scoped>
.card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 20px;
    margin-top: 16px;

    .card-item {
        width: 345px;
        // height: 460px;
        height: 430px;
        overflow: hidden;
        margin-bottom: 23px;
        position: relative;

        .swiper-slide {
            height: 220px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            /deep/.van-image {
                height: 100%;
                width: 100%;
            }
        }
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
        /deep/.my-bullet {
            width: 10px;
            height: 10px;
            background-color: #fff;
            border-radius: 50%;
            display: block;
            margin: 0 5px;
        }
        /deep/.my-bullet-active {
            background-color: #ffcc69;
        }
    }
}
// 单行文本溢出显示省略号
.textOhiden {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.description {
    background-color: #ffcc69;
    padding-bottom: 10px;
    height: calc(100% - 220px - 53px);
    & > p {
        padding-left: 15px;
        line-height: 30px;
        font-family: Arial-BoldMT;
        font-size: 20px;
        & > span:first-child {
            font-weight: 700;
        }
    }

    .title {
        color: #020b3b;
        padding: 17px 0 12px 15px;
        font-family: Arial-BoldMT;
        font-size: 28px;
        font-weight: 700;
    }
}

.bool-btn {
    height: 53px;
    background-color: #020b3b;
    color: #ffcc69;
    text-align: center;
    line-height: 53px;
    font-family: Arial-BoldMT;
    font-size: 28px;
}

.price {
    position: absolute;
    background-color: #ffcc69;
    right: 0;
    top: 20px;
    z-index: 10;
    width: 120px;
    height: 64px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & > p {
        text-align: center;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
    }

    & > p:last-child {
        font-size: 18px;
        font-weight: bold;
    }
}

.my-popup {
    overflow: hidden;
    .box {
        width: 640px;
        overflow: hidden;
    }

    .bg {
        width: 640px;
        height: 400px;
        border: 8px solid #fff;
        border-bottom: none;
        position: relative;
        /deep/.van-image {
            height: 100%;
            width: 100%;
        }
        .price {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 220px;
            height: 120px;
            background-color: #ffcc69;
            right: 0;
            top: 40px !important;
            p {
                text-align: center;
                font-family: ArialMT;
                font-size: 28px;
                color: #333333;
                line-height: 36px;
            }
        }
        img {
            width: 100%;
        }
    }

    .container {
        background-color: #ffcc69;
        padding-bottom: 14px;
        .title {
            text-align: center;
            font-family: Arial-BoldMT;
            font-size: 36px;
            color: #333333;
            font-weight: 500;
            line-height: 80px;
        }

        .tag {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            height: 80px;

            & > div {
                background-color: #020b3b;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-sizing: border-box;
                p {
                    font-size: 24px;
                    line-height: 30px;
                    color: #ffffff;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                }
            }

            & > div:nth-child(1) {
                width: 240px;
                margin-right: 3px;
            }

            & > div:nth-child(2) {
                width: 220px;
                margin-right: 3px;
            }

            & > div:nth-child(3) {
                width: 124px;
            }
        }

        .form {
            & > div > div:first-child {
                background-color: #020b3b;
                width: 160px;
                height: 50px;
                color: #fff;
                text-align: right;
                padding-right: 15px;
                line-height: 50px;
                font-family: Arial-BoldMT;
                font-size: 20px;
            }

            & > div {
                display: flex;
                margin: 0 20px;
                margin-top: 14px;
                input {
                    display: block;
                    width: 100%;
                    padding-left: 10px;
                }
                .input {
                    flex: 1;
                    display: flex;
                    line-height: 50px;
                }
                & > div:first-child {
                    margin-right: 10px;
                }
            }
            .name {
                .input {
                    display: block;
                }
            }
            .phone {
                display: flex;
                .input {
                    justify-content: space-between;
                    & > div:first-child {
                        width: 150px !important;
                        margin-right: 10px;
                    }
                    & > div:last-child {
                        flex: 1;
                    }
                }
            }
            .e-mail {
                .input {
                    display: block;
                    & > div {
                        input {
                            width: 100%;
                        }
                    }
                }
            }
            .adult-childs {
                .input {
                    justify-content: space-between;
                    & > div {
                        width: 49%;
                    }
                }
            }
            .phoneCode {
                position: relative;
                .arrow-down {
                    position: absolute;
                    top: 0;
                    right: 0px;
                    padding-right: 5px;
                    background-color: #fff;
                }
            }
        }
        border: 8px solid #fff;
        border-top: none;
        .btn {
            margin: 0 20px;
            background-color: #020b3b;
            height: 70px;
            line-height: 70px;
            font-size: 36px;
            font-family: Arial-BoldMT;
            color: #ffcc69;
            text-align: center;
            margin-top: 24px;
        }
    }
}
</style>
