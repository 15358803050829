import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./libs/rem.js"
import "./styles/init.css"
import "@/assets/font/font.css"
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs
import Vant from "vant"
import "vant/lib/index.css"
import "@/api/mockServe"
Vue.use(Vant)
import "@/libs/dircetive/index"
import VueAwesomeSwiper from "vue-awesome-swiper"
import "swiper/css/swiper.css"
import VueWechatTitle from "vue-wechat-title"
// import { isMobile } from '@/libs/util';
import i18n from "@/vueI18n"
import VueClipboard from "vue-clipboard2"
Vue.use(VueClipboard)
// if (!isMobile()) {
//   // location.href = `${location.protocol}//${process.env.VUE_APP_PC_DOMAIN}${this.$route.path}`;
// } else {
Vue.use(VueWechatTitle)
Vue.use(VueAwesomeSwiper)
import VueLazyload from "vue-lazyload"
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require("./assets/lazy-img.png"),
    loading: require("./assets/lazy-img.png"),
    attempt: 1,
})
// document.title = "SKY WALKER"
router.beforeEach((to, from, next) => {
    // 存储当前路由 用于侧边栏查找 添加active样式
    store.state.menu.currentPath = to.path
    // 防止about us 弹窗开启的时候，手机自带的返回导致页面返回，弹窗未关闭
    store.state.menu.aboutShow = false
    if (to.path.indexOf("chinese") != -1) {
        if (i18n.locale !== "zh-CH") i18n.locale = "zh-CH"
    } else if (to.path.indexOf("english") != -1) {
        if (i18n.locale !== "zh-EN") i18n.locale = "zh-EN"
    }

    next()
})

// 跳转后返回顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount("#app")
// }

// 百度统计代码
;(function () {
    var hm = document.createElement("script")
    hm.src = "https://hm.baidu.com/hm.js?78985b7420cd6a0074282152943565cf"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(hm, s)
})()

// 谷歌统计代码
;(function () {
    var google = document.createElement("script")
    // google.src = "https://www.googletagmanager.com/gtag/js?id=G-4FH4S5385H"
    google.src = "https://www.googletagmanager.com/gtag/js?id=AW-332666715"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(google, s)

    window.dataLayer = window.dataLayer || []
    function gtag() {
        dataLayer.push(arguments)
    }
    gtag("js", new Date())

    // gtag("config", "G-4FH4S5385H")
    gtag("config", "AW-332666715")
})()
// 谷歌统计代码
;(function () {
    // <script async src="https://www.googletagmanager.com/gtag/js?id=G-MD86ZCQS4B"></script>
    var google = document.createElement("script")
    google.src = "https://www.googletagmanager.com/gtag/js?id=G-MD86ZCQS4B"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(google, s)
    window.dataLayer = window.dataLayer || []
    function gtag() {
        dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "G-MD86ZCQS4B")
})()
