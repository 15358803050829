const state = {
    show:false, // 侧边栏显示
    aboutShow:false, // 关于我们显示
    currentPath:'/english/home',
    langShow:false, // 语言显示
}

const actions = {
    menuShow({commit}){
        commit('MENUSHOW')
    },
    

}

const mutations = {
    MENUSHOW(state){
        state.show = !state.show
    },
    ABOUT(state){
        state.aboutShow = !state.aboutShow
    },
    SHOWLANG(state){
        state.langShow = true
    },
    HIDDENLANG(state){
        state.langShow = false
    }
}

const getters = {

}

export default {
    state,actions,mutations,getters
}