import axios from "axios"
import md5 from "js-md5"

// /api/v1/yachts/rentalYachtsList
const baseURL = '/mock'
var Params = {
    appId: '504ca34700e711ecb2500af10e715ce6',
    appKey: '61ec617f00e711ecb2500af10e715ce6',
}
const axiosServe = axios.create({
    baseURL,
    timeout: 1000,
})
// 添加请求拦截器
axiosServe.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
axiosServe.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});




    /*
     * 获取随机数32位
     * */
function getrandom(){
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let nums = "";
    for (let i = 0; i < 32; i++) {
        let id = parseInt(Math.random() * 61);
        nums += chars[id];
    }
    return nums;
}
function getStorage (name) {
    if (!name) return;
    return window.localStorage.getItem(name) || ''
}
function setStorage (name, content) {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}



const request = (options={}) =>{
    
    if(!options.data){
        options.data = {}
    }
    var sign = ''
    let requestId = getrandom() // 随机数获取 requestId
    let appId = Params.appId
    let appKey = Params.appKey
    

    getStorage('_udid') ? getStorage('_udid') : setStorage('_udid',getrandom());
    let udid = getStorage('_udid') // 随机数获取 udid
    var str = ''
    options.data.requestId = requestId
    options.data.udid = udid
    options.data.appId = appId
    if (typeof options.data == 'object') {
        var str = '';
        //拼接字符串
        for (var key in options.data) {
            if (options.data[key] != "") {
                str += key + '=' + options.data[key] + '&';
            }
        }
        //md5加密
        if (str !== '') {
            str = str + "appKey=" + appKey;

            //md5加密 获取验签
            sign = md5(str);

        }
        options.data.sign = sign;
    }
    return axiosServe(options)
}




export default request