<template>
    <div>
        <div class="bg">
            <div>
                <p>Luxury Yacht Tours</p>
                <p>
                    No need for huge budgets to hire a luxury Yacht to see the Arabian coastline.
                    Now you can witness all the wonders of Dubai with family and friends and capture
                    unforgettable memories that only dreams are made of
                </p>
            </div>
        </div>
        <headLine>YACHT TOURS OPERATE DAILY</headLine>
        <yachtSelect />
        <cardList></cardList>
        <headLine>FREQUENTLY ASKED QUESTIONS</headLine>
        <questionList></questionList>
    </div>
</template>

<script>
import yachtSelect from "./yachtSelect/yachtSelect"
import cardList from "./cardList/cardList.vue"
import headLine from "@/components/headLine/headLine.vue"
import questionList from "@/components/questionList/questionList.vue"
import { inserSeoElement } from "@/libs/util"
export default {
    data() {
        return {
            showPopover: [
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: false,
                },
            ],
            indexMsg: "",
        }
    },
    components: { yachtSelect, cardList, headLine, questionList },
    methods: {},
    mounted() {
        inserSeoElement(
            this.$t("headSeo.shareTitle"),
            this.$t("headSeo.shareKey"),
            this.$t("headSeo.shareDec")
        )
    },
}
</script>

<style lang="less" scoped>
.bg {
    background-image: url(./images/bg.png);
    background-size: cover;
    height: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > div {
        width: 360px;
        margin-right: 20px;

        & > p:first-child {
            font-family: Bintang;
            font-size: 30px;
            color: #284c77;
            text-align: center;
        }

        & > p:last-child {
            font-family: Arial-ItalicMT;
            font-size: 16px;
            color: #284c77;
            text-align: justify;
            margin-top: 24px;
        }
    }
}
</style>
