import { receptionSave } from "@/api"
import { failMsg } from "@/libs/util"
const state = {
    orderSourceId: sessionStorage.getItem("orderSourceId") ? sessionStorage.getItem("orderSourceId") : "",
}

const actions = {
    async visitsNum({ commit }, context) {
        try {
            let res = await receptionSave(context)
            if (res.result) {
                window.open(context.receptionUrl)
            } else {
                failMsg(res.msg)
            }
        } catch (error) {
            failMsg(error)
            return Promise.reject()
        }
    },
    async visitsNumWechat({ commit }, context) {
        try {
            let res = await receptionSave(context)
            if (!res.result) {
                failMsg(res.msg)
            }
        } catch (error) {
            failMsg(error)
            return Promise.reject()
        }
    },
}

const mutations = {
    getOrderSourceId(state, params) {
        state.orderSourceId = params
    },
}

export default {
    state,
    actions,
    mutations,
}
