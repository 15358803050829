<template>
    <div class="home-banner">
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide class="slide">
                <img @click="show(0)" src="../images/swiper1.png" alt="" />
            </swiper-slide>
            <swiper-slide class="slide">
                <img @click="show(1)" src="../images/swiper2.png" alt="" />
            </swiper-slide>
            <swiper-slide class="slide">
                <img @click="show(2)" src="../images/swiper3.png" alt="" />
            </swiper-slide>
            <swiper-slide class="slide">
                <img @click="show(3)" src="../images/swiper4.png" alt="" />
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="text">
                <p>迪拜游艇租赁 | 迪拜游艇预订 | 迪拜游艇租赁</p>
                <p>以最优惠的价格租用游艇或小船进行巡游、钓鱼和水上运动。</p>
            </div>
        </swiper>
    </div>
</template>

<script>
import { ImagePreview } from "vant"
export default {
    name: "swiper-example-fade-effect",
    title: "Fade effect",
    data() {
        return {
            swiperOption: {
                spaceBetween: 30,
                effect: "fade",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    bulletClass: "my-bullet", //需设置.my-bullet样式
                    bulletActiveClass: "my-bullet-active",
                },
                autoplay: {
                    delay: 3000,
                },
                bulletClass: "my-bullet",
            },
        }
    },
    methods: {
        show(index) {
            ImagePreview({
                images: [
                    require("../images/swiper1.png"),
                    require("../images/swiper2.png"),
                    require("../images/swiper3.png"),
                    require("../images/swiper4.png"),
                ],
                startPosition: index,
            })
        },
    },
}
</script>

<style lang="less" scoped>
.home-banner {
    position: relative;
    .text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: rgba(2, 11, 59, 0.35);
        z-index: 1;
        color: #fff;
        font-size: 20px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 30px;
    }
}
.swiper {
    .slide {
        img {
            width: 100%;
        }
    }
}

.swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    background-color: #ffcc69 !important;
}

.swiper-pagination {
    display: flex;
    justify-content: center;

    /deep/.my-bullet {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        display: block;
        margin: 0 5px;
    }

    /deep/.my-bullet-active {
        background-color: #ffcc69;
    }
}
</style>
