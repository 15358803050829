const bookOrder = {
  data() {
    return {
      serviceChecked: false,
      cancellationChecked: false,
      userInfo: {},
      images: [],
      show: false,
      cateringComboPackage: [],
      specialServicePackage: [],
      payTotal: {},
      // HOURS
      hoursOptions: {
        title: 'Please select your Charter Hours',
        list: {
          HOURS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        show: false,
        value: '',
      },
      // TIME
      timeOptions: {
        title: 'Please select your Charter Time',
        list: {
          AM: [6, 7, 8, 9, 10, 11, 12],
          PM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        show: false,
        value: '',
      },
      // quantity
      countMenusOptions: {
        title: 'Please select your number of board catering',
        list: { PCS: [] },
        show: false,
        value: '',
      },
      countSportsOptions: {
        title: 'Please select your number of water sports',
        list: { PCS: [] },
        show: false,
        value: '',
      },
      params: {},
      weekEn: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      state: true,
      // 用户协议弹窗
      serviceDialog: false,
      // 取消条款弹窗
      cancelDialog: false,
      // 用于改变套餐store中的number
      menusId: '',
      sportsId: '',
    };
  },

  created() {
    // console.log(this.$route.meta);
    // 跳转到这个页面时去拿到用户信息进行渲染
    this.userInfo = { ...this.$store.state.home.userOrderInfo };
    // 获取当前游艇详情
    this.$store.dispatch('getYachtsDetail', {
      productId: this.userInfo.productId,
      passengerNum: +this.userInfo.adult + +this.userInfo.child,
      takeOrderType: this.userInfo.takeOrderType,
      rentDate: this.userInfo.rentDate,
    });
    // 计算未选中套餐时的金额
    if (!this.userInfo.time || !this.userInfo.hour) {
      this.$toast(this.$t('bookOrder.enterToast'));
    } else {
      this.timeOptions.value = String(this.userInfo.showTime);
      this.hoursOptions.value = this.userInfo.hour;
      this.calcYachtOrderTotal();
    }
  },
  computed: {
    timeColumns() {
      let a = [
        {
          values: this.timeOptions.list['AM'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.timeOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
    hoursColumns() {
      let a = [
        {
          values: this.hoursOptions.list['HOURS'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.hoursOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
    countMenusColumns() {
      let a = [
        {
          values: this.countMenusOptions.list['PCS'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.countMenusOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
    countSportsColumns() {
      let a = [
        {
          values: this.countSportsOptions.list['PCS'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.countSportsOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
  },
  methods: {
    serviceShow() {
      this.serviceDialog = true;
    },
    cancelShow() {
      this.cancelDialog = true;
    },
    closeAgree() {
      this.cancelDialog = false;
      this.serviceDialog = false;
    },
    // 控件显示操作
    showHours() {
      this.hoursOptions.show = true;
    },
    showTime() {
      this.timeOptions.show = true;
    },
    showPhoto(url) {
      this.images = [url];
      this.show = true;
    },
    // 关闭pick 和 time 控件
    unShow() {
      this.timeOptions.show = false;
      this.hoursOptions.show = false;
      this.countMenusOptions.show = false;
      this.countSportsOptions.show = false;
    },
    timeConfirm(value) {
      let text = value[0] + '' + value[1];
      this.userInfo.showTime = text;
      this.userInfo.time = value[0];
      this.$set(this.timeOptions, 'value', String(text));
      this.$store.commit('getUserOrderInfo', {
        ...this.userInfo,
      });
      this.calcYachtOrderTotal();
    },
    hourConfirm(value) {
      // let text = value[0] + ' ' + value[1];
      this.userInfo.hour = value[0];
      this.$set(this.hoursOptions, 'value', value[0]);
      this.$store.commit('getUserOrderInfo', {
        ...this.userInfo,
      });
      this.calcYachtOrderTotal();
    },
    countMenusConfirm(value) {
      this.$store.state.order.yachtsMenu.forEach((item) => {
        if (item.id == this.menusId) {
          item.number = value[0];
        }
      });
      this.cateringComboPackage.forEach((item) => {
        if (item.id == this.menusId) {
          item.number = value[0];
        }
      });
      this.$set(this.countMenusOptions, 'value', value[0]);
      this.calcYachtOrderTotal();
    },
    countSportsConfirm(value) {
      this.$store.state.order.waterSports.forEach((item) => {
        if (item.id == this.sportsId) {
          item.number = value[0];
        }
      });
      this.specialServicePackage.forEach((item) => {
        if (item.id == this.sportsId) {
          item.number = value[0];
        }
      });
      this.$set(this.countSportsOptions, 'value', value[0]);
      this.calcYachtOrderTotal();
    },
    getRentalYachtsList() {
      let passengerNum =
        this.personOptions.value === '' ? 1 : this.personOptions.value;
      let rentDate =
        this.dateOptions.value === ''
          ? this.dayjs(new Date(rentDate)).format('YYYY-MM-DD')
          : this.dateOptions.value;
    },
    // 选择餐饮套餐发请求
    menusNumChange(checked, minNumber, maxNumber, id) {
      this.countMenusOptions.show = true;
      this.menusId = id;
      let array = [];
      for (
        let index = 0;
        index < (minNumber ? maxNumber - minNumber + 1 : maxNumber);
        index++
      ) {
        array.push(minNumber ? minNumber + index : 1 + index);
      }
      this.countMenusOptions.list['PCS'] = array;
      // 只有当选中的时候才去发请求
      if (checked) {
        // 判断数量变化
        this.cateringComboPackage.forEach((item) => {
          if (item.id == id) {
            item.number = this.countMenusOptions.value;
          }
        });
        // 调用金额请求函数
        this.calcYachtOrderTotal();
      }
    },
    menusCheckedChange(checked, number, id) {
      if (checked) {
        this.cateringComboPackage.push({ id, number });
      } else {
        this.cateringComboPackage = this.cateringComboPackage.filter(
          (item) => item.id !== id
        );
      }
      this.calcYachtOrderTotal();
    },
    // 选择水上运动套餐发请求
    sportsNumChange(checked, maxNumber, id) {
      this.countSportsOptions.show = true;
      this.sportsId = id;
      let array = [];
      for (let index = 0; index < maxNumber; index++) {
        array.push(index + 1);
      }
      this.countSportsOptions.list['PCS'] = array;
      if (checked) {
        this.specialServicePackage.forEach((item) => {
          if (item.id == id) {
            item.number = this.countSportsOptions.value;
          }
        });
        this.calcYachtOrderTotal();
      }
    },
    sportsCheckedChange(checked, number, id) {
      if (checked) {
        this.specialServicePackage.push({ id, number });
      } else {
        this.specialServicePackage = this.specialServicePackage.filter(
          (item) => item.id !== id
        );
      }
      this.calcYachtOrderTotal();
    },
    // 选择玩具套餐发请求
    // toysNumChange(checked, number, id) {
    //   if (checked) {
    //     this.specialServicePackage.forEach((item) => {
    //       if (item.id == id) {
    //         item.number = number;
    //       }
    //     });
    //     this.calcYachtOrderTotal();
    //   }
    // },
    toysCheckedChange(checked, number, id) {
      if (checked) {
        this.specialServicePackage.push({ id, number });
      } else {
        this.specialServicePackage = this.specialServicePackage.filter(
          (item) => item.id !== id
        );
      }
      this.calcYachtOrderTotal();
    },
    // 发送计算金额请求
    async calcYachtOrderTotal() {
      this.params = {
        productId: this.userInfo.productId,
        adultNum: +this.userInfo.adult,
        childNum: +this.userInfo.child,
        takeOrderType: this.userInfo.takeOrderType,
        rentType: this.userInfo.rentType,
        rentDate: this.dayjs(this.userInfo.date).format('YYYY-MM-DD'),
      };
      if (this.timeOptions.value.indexOf('AM') != -1) {
        this.params['beginTime'] = `${
          this.timeOptions.value.split('A')[0] > 9
            ? this.timeOptions.value.split('A')[0]
            : '0' + this.timeOptions.value.split('A')[0]
        }:00`;
        this.params['endTime'] = `${
          +this.timeOptions.value.split('A')[0] + +this.hoursOptions.value > 9
            ? +this.timeOptions.value.split('A')[0] + +this.hoursOptions.value
            : '0' +
              (+this.timeOptions.value.split('A')[0] + +this.hoursOptions.value)
        }:00`;
      }
      if (this.timeOptions.value.indexOf('PM') != -1) {
        this.params['beginTime'] = `${
          +this.timeOptions.value.split('P')[0] + 12
        }:00`;
        this.params['endTime'] = `${
          +this.timeOptions.value.split('P')[0] + 12 + this.hoursOptions.value
        }:00`;
      }
      if (
        +this.timeOptions.value.split('P')[0] + 12 + this.hoursOptions.value >=
          24 ||
        +this.timeOptions.value.split('A')[0] + +this.hoursOptions.value >= 24
      ) {
        this.$toast.fail(this.$t('bookOrder.error'));
        return;
      }
      if (this.cateringComboPackage.length > 0) {
        this.params['cateringComboPackage'] = JSON.stringify(
          this.cateringComboPackage
        );
      }
      if (this.specialServicePackage.length > 0) {
        this.params['specialServicePackage'] = JSON.stringify(
          this.specialServicePackage
        );
      }
      //   console.log(this.params);
      try {
        await this.$store.dispatch('calcYachtOrderTotal', this.params);
      } catch (error) {}
      // 每次请求后拿到最新的价钱
      this.payTotal = this.$store.state.order.calcYachtOrderTotal;
    },
    // 点击支付时调用
    async payment() {
      // 判断是否勾选了协议
      if (this.serviceChecked == false || this.cancellationChecked == false) {
        this.$toast(this.$t('bookOrder.agree'));
      } else if (
        this.timeOptions.value == '' ||
        this.hoursOptions.value == ''
      ) {
        this.$toast(this.$t('bookOrder.enterToast'));
      } else if (
        +this.timeOptions.value.split('P')[0] + 12 + this.hoursOptions.value >=
          24 ||
        +this.timeOptions.value.split('A')[0] + +this.hoursOptions.value >= 24
      ) {
        this.$toast.fail(this.$t('bookOrder.error'));
      } else {
        let data = this.params;
        data.realName = this.userInfo.name;
        data.mobileNum = data.mobileNum =
          this.userInfo.qz + '-' + this.userInfo.phone;
        data.email = this.userInfo.email;
        let orderMemberInfoCmds = [];
        for (let i = 1; i <= +this.userInfo.adult; i++) {
          orderMemberInfoCmds.push({
            memberName: 'Unknown Name',
            memberType: 1,
            memberState: 0,
          });
        }
        for (let i = 1; i <= +this.userInfo.child; i++) {
          orderMemberInfoCmds.push({
            memberName: 'Unknown Name',
            memberType: 2,
            memberState: 0,
          });
        }
        data.orderMemberInfoCmds = JSON.stringify(orderMemberInfoCmds);
        if (this.state) {
          this.state = false;
          try {
            await this.$store.dispatch('addYachtsOrder', data);
            this.state = true;
          } catch (error) {
            this.state = true;
          }
        }
        // if (this.$store.state.order.payInfo.result) {
        //   location.href = this.$store.state.order.payInfo.data;
        // }
      }
    },
  },
  destroyed() {
    // localStorage.removeItem('userOrderInfo');
    // this.$store.commit('removeUserInfo');
    // this.timeOptions.value = '';
    // this.hoursOptions.value = '';
  },
};
export default bookOrder;
