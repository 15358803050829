import i18n from "@/vueI18n"
import { Toast } from "vant"

export const success = () => {
    Toast.success(i18n.tc("form.success"))
}
export const fail = () => {
    Toast.fail(i18n.tc("form.fail"))
}

export const failMsg = msg => {
    let msgList = msg.split("\n")
    let msgL = ""
    switch (i18n.locale) {
        case "zh-CH":
            msgL = msgList[0]
            break
        case "zh-EN":
            msgL = msgList[1]
            break
        default:
            msgL = msgList[1]
            break
    }
    Toast.fail(msgL)
}

export const getAllParams = geturl => {
    const url = geturl //获取url中"?"符后的字串
    let theRequest = new Object()
    if (url.indexOf("?") != -1) {
        let str = url.substr(1)
        strs = str.split("&")
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1])
        }
    }
    return theRequest
}

export const isMobile = () => {
    if (
        window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true // 移动端
    } else {
        return false // PC端
    }
}

/*
 *  获取url中的某个参数
 *  例如 http://h5.yachts.com/english/home?id=5
 *  getUrlParams(id)  // return '5'
 * */
export const getUrlParams = params => {
    let query = window.location.search.substring(1)
    let vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=")
        if (pair[0] === params) return pair[1]
    }
    return null
}

// 往head前面插入 title 和 meta
export const inserSeoElement = (titleText, key, dec) => {
    const head = document.head || document.getElementsByTagName("head")[0]
    const meta = document.getElementById("tkdKey") || document.createElement("meta")
    const meta2 = document.getElementById("tkdDec") || document.createElement("meta")
    const title = document.getElementById("tdkTitle") || document.createElement("title")
    title.innerText = titleText
    title.setAttribute("id", "tdkTitle")

    meta.setAttribute("name", "keywords")
    meta.setAttribute("content", key)
    meta.setAttribute("id", "tkdKey")

    meta2.setAttribute("name", "description")
    meta2.setAttribute("id", "tkdDec")
    meta2.setAttribute("content", dec)

    if (!document.getElementById("tkdDec")) {
        head.insertBefore(meta2, head.children[0])
    }

    if (!document.getElementById("tkdKey")) {
        head.insertBefore(meta, head.children[0])
    }
    if (!document.getElementById("tdkTitle")) {
        head.insertBefore(title, head.children[0])
    }
}
