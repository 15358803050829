<template>
  <div>
    <div class="select-list">
      <div @click="showDate">
        <p>
          {{ dateOptions.value === "" ? "日期" : dayjs(dateOptions.value).format("YYYY/MM/DD") }}
        </p>
        <p></p>
      </div>
      <div @click="showTime">
        <p>
          {{ timeOptions.value === "" ? "租赁时间" : timeOptions.value }}
        </p>
        <p></p>
      </div>
      <div @click="showPerson">
        <p>
          {{ personOptions.value === "" ? "人数" : personOptions.value + " 人" }}
        </p>
        <p></p>
      </div>
    </div>
    <diyPick
      :list="personOptions.personList"
      :show="personOptions.show"
      :title="personOptions.title"
      :columns="personColumns"
      @unShow="unShow"
      @confirm="personConfirm"
    />
    <diyPick
      :list="timeOptions.list"
      :show="timeOptions.show"
      :title="timeOptions.title"
      :columns="timeColumns"
      @unShow="unShow"
      @confirm="timeConfirm"
    />
    <diyDateTime :title="dateOptions.title" @unShow="unShow" :show="dateOptions.show" @confirm="dateConfirm" />
  </div>
</template>

<script>
import yachtSelect from "@/mixin/yachtTours/yachtSelect.js"
export default {
  mixins: [yachtSelect],
  data() {
    return {
      currentDate: new Date(),
      // PERSON
      personOptions: {
        title: "请选择您的宾客人数",
        personList: {
          人: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31, 32, 33, 34, 35, 36,
          ],
        },
        show: false,
        value: "",
      },

      // TIME
      timeOptions: {
        title: "请选择您的租赁时间",
        list: {
          上午: [6, 7, 8, 9, 10, 11, 12],
          下午: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },
        show: false,
        value: "",
      },

      // HOURS
      hoursOptions: {
        title: "请选择您的租赁时长",
        list: {
          小时: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        show: false,
        value: "",
      },

      //DATE
      dateOptions: {
        title: "请选择您的日期",
        show: false,
        value: "",
        date: new Date(),
      },
    }
  },
  computed: {
    personColumns() {
      let a = [
        {
          values: this.personOptions.personList["人"],
          className: "column2",
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.personOptions.personList),
          className: "column1",
        },
      ]
      return a
    },
    timeColumns() {
      let a = [
        {
          values: this.timeOptions.list["上午"],
          className: "column2",
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.timeOptions.list),
          className: "column1",
        },
      ]
      return a
    },
    hoursColumns() {
      let a = [
        {
          values: this.hoursOptions.list["小时"],
          className: "column2",
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.hoursOptions.list),
          className: "column1",
        },
      ]
      return a
    },
  },
}
</script>

<style lang="less" scoped>
.select-list {
  display: flex;
  justify-content: space-between;
  margin: 0 34px;
  border-bottom: 2px solid #666;

  & > div {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 82px;

    & > p:first-child {
      font-family: ArialMT;
      font-size: 22px;
      color: #666666;
    }

    & > p:last-child {
      width: 12px;
      height: 12px;
      border-bottom: 3px solid #666;
      border-right: 3px solid #666;
      transform: rotate(45deg);
      margin-left: 12px;
      margin-top: -3px;
    }
  }

  & > div:nth-child(1) {
    width: 200px;
  }

  & > div:nth-child(2) {
    width: 150px;
  }

  & > div:nth-child(3) {
    // width: 140px;
    width: 130px;
  }

  & > div:nth-child(4) {
    width: 160px;
  }
}
</style>
