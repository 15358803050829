import { shareYachtsList } from "@/api"
import dayjs from "dayjs"
import { Toast } from "vant"
const state = {
    list: [],
    yachtToursOrderInfo: JSON.parse(localStorage.getItem("yachtToursOrderInfo")) || {},
}

const actions = {
    async getShareYachtsList({ commit }, context) {
        Toast.loading({ forbidClick: true })
        let data = await shareYachtsList(context)
        Toast.clear()
        // 遍历添加timeType时间段参数，判断属于哪个时间段
        // 清晨0-5.59  上午6-11.59 下午12-16.59 傍晚17-19.59 深夜20-23.59
        data.data.forEach(item => {
            item.timeType = ""
            let date = item.rentDate + " " + item.startTime
            let morning = item.rentDate + " " + "05:59:59"
            let riser = item.rentDate + " " + "11:59:59"
            let afternoon = item.rentDate + " " + "16:59:59"
            let sunset = item.rentDate + " " + "19:59:59"
            let night = item.rentDate + " " + "23:59:59"

            if (dayjs(date).isBefore(dayjs(morning))) {
                item.timeType = 0
            } else if (dayjs(date).isBefore(dayjs(riser))) {
                item.timeType = 1
            } else if (dayjs(date).isBefore(dayjs(afternoon))) {
                item.timeType = 2
            } else if (dayjs(date).isBefore(dayjs(sunset))) {
                item.timeType = 3
            } else if (dayjs(date).isBefore(dayjs(night))) {
                item.timeType = 4
            }
        })
        data.data.forEach(item => {
            item.mediaArr = []
            if (item.media instanceof Array) {
                item.media.forEach(citem => {
                    if (citem.mediaType === 1) {
                        item.mediaArr.push(citem.mediaUrl)
                    }
                })
            }
        })
        commit("GETSHAREYACHTSLIST", data.data)
    },
}

const mutations = {
    GETSHAREYACHTSLIST(state, params) {
        state.list = params
    },
    getyachtToursOrderInfo(state, params) {
        state.userOrderInfo = params
        localStorage.setItem("yachtToursOrderInfo", JSON.stringify(params))
    },
}

const getters = {}

export default {
    state,
    actions,
    mutations,
    getters,
}
