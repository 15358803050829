<template>
  <div>
    <div class="select-list">
      <div @click="showDate">
        <p>
          {{ dateOptions.value === "" ? "Select Date" : dayjs(dateOptions.value).format("YYYY/MM/DD") }}
        </p>
        <p></p>
      </div>
      <!-- <div @click="showHours">
                <p>{{hoursOptions.value === '' ? 'Charter Hours' : hoursOptions.value}}</p>
                <p></p>
            </div> -->
      <div @click="showTime">
        <p>
          {{ timeOptions.value === "" ? "Charter Time" : timeOptions.value }}
        </p>
        <p></p>
      </div>
      <div @click="showPerson">
        <p>
          {{ personOptions.value === "" ? "Number Of Guests" : personOptions.value + " PERSON" }}
        </p>
        <p></p>
      </div>
    </div>
    <diyPick
      :list="personOptions.personList"
      :show="personOptions.show"
      :title="personOptions.title"
      :columns="personColumns"
      @unShow="unShow"
      @confirm="personConfirm"
    />
    <diyPick
      :list="timeOptions.list"
      :show="timeOptions.show"
      :title="timeOptions.title"
      :columns="timeColumns"
      @unShow="unShow"
      @confirm="timeConfirm"
    />
    <!-- <diyPick
            :list='hoursOptions.list'
            :show="hoursOptions.show"
            :title="hoursOptions.title"
            :columns = 'hoursColumns'
            @unShow="unShow"
            @confirm="hourConfirm"
        /> -->
    <diyDateTime :title="dateOptions.title" @unShow="unShow" :show="dateOptions.show" @confirm="dateConfirm" />
  </div>
</template>

<script>
import yachtSelect from "@/mixin/yachtTours/yachtSelect.js"
export default {
  mixins: [yachtSelect],
  data() {
    return {
      currentDate: new Date(),
      // PERSON
      personOptions: {
        title: "Please select your Number Of Guests",
        personList: {
          PERSON: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31, 32, 33, 34, 35, 36,
          ],
        },
        show: false,
        value: "",
      },

      // TIME
      timeOptions: {
        title: "Please select your Charter Time",
        list: {
          AM: [6, 7, 8, 9, 10, 11, 12],
          PM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },
        show: false,
        value: "",
      },

      //DATE
      dateOptions: {
        title: "Please select your date",
        show: false,
        value: "",
        date: new Date(),
      },
    }
  },
}
</script>

<style lang="less" scoped>
.select-list {
  display: flex;
  justify-content: space-between;
  margin: 0 34px;
  border-bottom: 2px solid #666;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 82px;

    & > p:first-child {
      font-family: ArialMT;
      font-size: 22px;
      color: #666666;
    }

    & > p:last-child {
      width: 12px;
      height: 12px;
      border-bottom: 3px solid #666;
      border-right: 3px solid #666;
      transform: rotate(45deg);
      margin-top: 6px;
    }
  }

  & > div:nth-child(1) {
    width: 120px;
  }

  & > div:nth-child(2) {
    width: 150px;
  }

  & > div:nth-child(3) {
    // width: 140px;
    width: 200px;
  }

  & > div:nth-child(4) {
    width: 200px;
  }
}
</style>
