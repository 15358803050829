<template>
    <div>
        <van-popup v-model="show">
            <div class="pay-container">
                <div class="type-img">
                    <img src="@/assets/gou.png" alt="" v-if="type === 'success'" />
                    <img src="@/assets/cha.png" alt="" v-else />
                </div>
                <div v-if="type === 'success'" class="success-text">
                    <p>{{ $t("payType.success") }}</p>
                    <h2>{{ (+money)?.toFixed(2) }}AED</h2>
                </div>
                <div v-else class="err-text">
                    <h2>{{ $t("payType.fail") }}</h2>
                </div>
                <button v-if="type === 'success'" class="btn btn3" @click="unShow">
                    {{ $t("payType.ok") }}
                </button>
                <div v-else class="btn btn2">
                    <button @click="unShow">{{ $t("payType.back") }}</button>
                    <!-- <button>{{$t('payType.again')}}</button> -->
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import PayType from "@/mixin/home/PayType.js"
export default {
    mixins: [PayType],
}
</script>

<style lang="less" scoped>
.pay-container {
    height: 520px;
    width: 644px;
    position: relative;
    overflow: hidden;
    .type-img {
        padding-top: 37px;
        margin: 0 auto;
        width: 120px;
        img {
            width: 100%;
        }
    }
    .success-text {
        p {
            text-align: center;
            margin: 44px 0 54px 0;
            font-family: ArialMT;
            font-size: 24px;
            color: #333333;
        }
        h2 {
            font-size: 60px;
            color: #252525;
            text-align: center;
            font-weight: bold;
            font-family: Arial-BoldMT;
        }
    }
    .err-text {
        text-align: center;
        h2 {
            font-family: Arial;
            font-size: 48px;
            color: #333333;
            font-weight: bold;
            margin: 80px 0 36px 0;
        }
        p {
            font-family: Arial;
            font-size: 24px;
            line-height: 27px;
            color: #666666;
        }
    }
}
.btn {
    height: 100px;
    line-height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
button {
    background-color: transparent;
    border: none;
    outline: none;
}
.btn2 {
    display: flex;
    & > button {
        flex: 1;
        display: block;
    }
    & > button:nth-child(1) {
        background-color: #020b3b;
        font-family: Arial-BoldMT;
        font-size: 36px;
        color: #ffcc69;
    }
    & > button:nth-child(2) {
        background-color: #ffcc69;
        font-family: Arial-BoldMT;
        font-size: 36px;
        color: #020b3b;
    }
}

.btn3 {
    background-color: #ffcc69;
    font-family: Arial-BoldMT;
    font-size: 36px;
    color: #020b3b;
    display: block;
    width: 100%;
}
</style>
