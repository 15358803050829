import { teachList, addTeachOrder, calcTeachOrder } from '@/api';
import { success, fail, failMsg } from '@/libs/util';
import { Toast } from 'vant';
const state = {
  teach: [],
  teachOrder: {},
  teachOrderTotal: {},
};

const actions = {
  // 获取教学列表
  async getTeachList({ commit }, context) {
    let data = await teachList();
    data.data.forEach((item) => {
      item.teachName = item.teachName.split(' ');
      item.detailDescribe = item.detailDescribe.split('\n');
    });
    commit('GETTEACHLIST', data.data);
  },
  // 创建教学订单
  async addTeachOrder({ commit }, context) {
    try {
      Toast.loading({duration:0})
      let data = await addTeachOrder(context);     
      commit('ADDTEACHORDER', data);
      if (data.result) {
        location.href = data.data;
        Toast.clear();
        return Promise.resolve()
      }
    } catch (error) {
      failMsg(error);
      return new Promise.reject();
    }
  },
  // 计算教学订单金额
  async calcTeachOrder({ commit }, context) {
    try {
      let data = await calcTeachOrder(context);
      commit('CALCTEACHORDER', data.data);
    } catch (error) {
      return new Promise.reject();
    }
  },
};

const mutations = {
  GETTEACHLIST(state, params) {
    state.teach = params;
  },
  ADDTEACHORDER(state, params) {
    state.teachOrder = params;
  },
  CALCTEACHORDER(state, params) {
    state.teachOrderTotal = params;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
