import { addSuperYachtsOrder, superYachtsList } from "@/api"
import { success, fail, failMsg } from "@/libs/util"
const state = {
    SuperYachtsOrderInfo: {},
    superYachtsList: [],
}

const actions = {
    async addSuperYachtsOrder({ commit }, context) {
        try {
            let data = await addSuperYachtsOrder(context)
            commit("ADDYACHTSORDER", data.data)
            success()
        } catch (error) {
            failMsg(error)
            return new Promise.reject()
        }
    },
    async getSuperYachtsList({ commit }) {
        try {
            let data = await superYachtsList()
            data.data.forEach(item => {
                item.more = false
                item.remark ? item.remark : (item.remark = "")

                // 提取media 组成一个url数组 --- 用于图片预览
                item.mediaArr = []
                if (item.media instanceof Array) {
                    item.media.forEach(citem => {
                        if (citem.mediaType === 1) {
                            item.mediaArr.push(citem.mediaUrl)
                        }
                    })
                }
            })

            commit("GETSUPERYACHTSLIST", data.data)
        } catch (error) {
            failMsg(error)
        }
    },
}

const mutations = {
    ADDSUPERYACHTSORDER(state, params) {
        state.SuperYachtsOrderInfo = params
    },
    GETSUPERYACHTSLIST(state, params) {
        state.superYachtsList = params
    },
}

const getters = {}

export default {
    state,
    actions,
    mutations,
    getters,
}
