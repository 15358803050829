<template>
    <div>
        <div class="bg">
            <p class="title">超级游艇包租</p>
            <p class="text">
                Sky Walker
                超级游艇租赁开启了通往迪拜真正奢华生活的大门。我们能够为您量身定制提出的所有需求，帮助您创造非凡的时刻和一生的回忆。从选择Sky
                Walker开始，对于您每一次豪华游艇之旅的良好体验我们都充满信心！
            </p>
        </div>
        <div v-if="yachtList.length > 0" class="yacht-list">
            <div class="item" v-for="(item, index) in yachtList" :key="index">
                <headLine class="NOTORIOUS">{{ item.productName }}</headLine>
                <div class="container">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide class="slide" v-for="(citem, cindex) in item.media" :key="cindex">
                            <div v-if="citem.mediaType == 1">
                                <img @click="showImage(item.mediaArr, cindex)" :src="citem.mediaUrl" alt="" />
                            </div>
                            <div v-else class="video">
                                <video :src="citem.mediaUrl" :ref="citem.mediaUrl"></video>
                                <img src="@/assets/play.png" class="play" @click="playVideo(citem.mediaUrl)" />
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                    </swiper>
                    <div class="describe">
                        <p v-if="item.remark !== ''">
                            {{
                                item.more || item.remark.length < 200 ? item.remark : item.remark.slice(0, 200) + "..."
                            }}
                            <span @click="describe(index)" v-if="!item.more && !(item.remark.length < 200)">
                                展开
                                <span></span>
                            </span>
                        </p>
                        <p v-else>暂时没有数据</p>
                    </div>
                    <div class="item-list">
                        <div class="left">
                            <div class="row">
                                <div>
                                    <p>建造</p>
                                    <p>{{ item.sourcePlace }}</p>
                                </div>
                                <div>
                                    <p>长度</p>
                                    <p>{{ item.length }}FT</p>
                                </div>
                                <div>
                                    <p>客舱</p>
                                    <p>{{ item.bedroomNum }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div>
                                    <p>容量</p>
                                    <p>{{ item.maxPassenger }}</p>
                                </div>
                                <div>
                                    <p>睡眠舱</p>
                                    <p>{{ item.bedNum }}</p>
                                </div>
                                <div>
                                    <p>泊位</p>
                                    <p>{{ item.berth }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <p>租赁/每小时</p>
                            <p></p>
                            <p>AED {{ item.yachtsPrice }} + VAT</p>
                            <p>24小时</p>
                            <p>AED {{ item.yachtsPriceDaily }} + VAT</p>
                        </div>
                    </div>
                    <div class="enquire" @click="showEnquire(item.productId, index)">立即咨询</div>
                </div>
            </div>
        </div>
        <Empty v-else />
        <van-popup v-model="enquireShow" class="enquire-container">
            <div class="title">超级游艇咨询</div>
            <div class="form">
                <div class="cel">
                    <span class="name">姓名</span>
                    <div>
                        <input type="text" v-model="options.name" />
                    </div>
                </div>
                <div class="cel phone">
                    <span class="name">手机号码</span>
                    <div class="phoneCode">
                        <div class="phone-box">
                            <input
                                type="text"
                                :value="options.qz ? '+ ' + options.qz : ''"
                                readonly
                                @click="showAreaCode"
                            />
                            <i class="arrow-down" @click="showAreaCode"><van-icon name="arrow-down" /></i>
                        </div>
                        <input type="text" v-model="options.phone" v-number />
                    </div>
                </div>
                <div class="cel">
                    <span class="name">电子邮箱</span>
                    <div>
                        <input type="text" v-model="options.email" />
                    </div>
                </div>
                <div class="cel">
                    <span class="name">游艇</span>
                    <div>
                        <input type="text" v-model="options.yacht" readonly />
                    </div>
                </div>
                <div class="cel">
                    <span class="name">成人数量</span>
                    <div>
                        <input type="text" v-model="options.adults" v-number />
                    </div>
                </div>
                <div class="cel">
                    <span class="name">儿童数量</span>
                    <div>
                        <input type="text" v-model="options.children" v-number />
                    </div>
                </div>
                <div class="cel date">
                    <span class="name">旅游日期</span>
                    <div>
                        <input
                            type="text"
                            @click="showBegin"
                            readonly
                            :value="beginOptions.value === '' ? '' : dayjs(beginOptions.value).format('YYYY-MM-DD')"
                        />
                        <input
                            type="text"
                            @click="showEnd"
                            readonly
                            :value="endOptions.value === '' ? '' : dayjs(endOptions.value).format('YYYY-MM-DD')"
                        />
                    </div>
                </div>
            </div>
            <div class="send" @click="sendEnquiry">发送咨询</div>
        </van-popup>
        <diyDateTime title="请选择开始时间" @unShow="unShow" :show="beginOptions.show" @confirm="benginConfirm" />
        <diyDateTime title="请选择结束时间" @unShow="unShow" :show="endOptions.show" @confirm="endConfirm" />
        <areaCode :show="show.areaCode" @unShow="closeAreaCode" @confirm="areaCodeConfirm" />
    </div>
</template>
<script>
import mixin from "@/mixin/SuperYachts"
import videos from "@/mixin/video"
export default {
    mixins: [mixin, videos],
}
</script>

<style lang="less" scoped>
.video {
    position: relative;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .play {
        width: 54px !important;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
}

.bg {
    background-image: url(./images/bg.png);
    height: 385px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;

    .title {
        font-family: "Bintang";
        font-size: 36px;
        color: #ffffff;
        margin-bottom: 20px;
    }

    .text {
        width: 390px;
        font-family: Arial-ItalicMT;
        font-size: 20px;
        color: #ffffff;
        text-align: justify;
    }
}

.yacht-list {
    margin-bottom: 54px;
    .slide {
        height: 366px;
        overflow: hidden;
    }
}

.NOTORIOUS {
    /deep/.headline {
        width: 700px;
    }
}

.container {
    width: 710px;
    margin: 0 auto;
    background-color: #020b3b;

    .swiper {
        img {
            width: 100%;
        }
    }

    .describe {
        margin: 20px;

        p {
            font-family: ArialMT;
            font-size: 20px;
            color: #ffffff;
            line-height: 30px;
        }

        span {
            font-family: ArialMT;
            font-size: 20px;
            letter-spacing: 0px;
            color: #ffcc69;
            position: relative;
            display: inline-block;
            margin-left: 5px;

            & > span {
                position: absolute;
                width: 16px;
                height: 16px;
                border-bottom: 2px solid #ffcc69;
                border-right: 2px solid #ffcc69;
                transform: rotate(45deg);
                top: -5px;
                bottom: 0;
                right: -30px;
                margin: auto;
            }
        }
    }

    .item-list {
        display: flex;
        font-family: ArialMT;
        font-size: 18px;
        color: #ffffff;
        text-align: center;

        & > div {
            border-top: 2px solid #fff;
        }

        .left {
            .row {
                display: flex;
                height: 80px;

                & > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-right: 2px solid #fff;

                    & > p:last-child {
                        color: #ffcc69;
                    }
                }

                & > div:nth-child(1) {
                    width: 138px;
                }

                & > div:nth-child(2) {
                    width: 118px;
                }

                & > div:nth-child(3) {
                    width: 214px;
                }
            }

            .row:first-child {
                border-bottom: 2px solid #fff;
            }
        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & > p:nth-child(3),
            & > p:nth-child(5) {
                color: #ffcc69;
            }

            & > p:nth-child(4) {
                margin-top: 6px;
            }
        }
    }

    .enquire {
        background-color: #ffcc69;
        line-height: 66px;
        font-family: Arial-BoldMT;
        font-size: 32px;
        color: #333333;
        text-align: center;
        font-weight: bold;
    }
}

.enquire-container {
    width: 670px;

    .title {
        height: 100px;
        background-color: #ffcc69;
        line-height: 100px;
        text-align: center;
        font-family: Arial-BoldMT;
        font-size: 36px;
        color: #020b3b;
    }

    .form {
        margin: 40px;

        .name {
            min-width: 140px;
            font-family: Arial-BoldMT;
            font-size: 24px;
            color: #333333;
            text-align: right;
            margin-right: 16px;
            font-weight: bold;
        }

        .cel {
            height: 60px;
            display: flex;
            align-items: center;

            & > div {
                flex: 1;
                display: flex;
            }

            input {
                display: block;
                border: solid 2px #cccccc;
                padding-left: 10px;
                flex: 1;
                line-height: 40px;
            }
        }

        .phone {
            & > div {
                input:first-child {
                    width: 150px;
                    flex: 0;
                }

                input:last-child {
                    width: 100%;
                    display: block;
                }
            }
            .phoneCode {
                position: relative;
                .arrow-down {
                    position: absolute;
                    // left: 120px;
                    right: 0;
                    top: 0;
                    padding-right: 8px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    border: 2px solid #cccccc;
                    border-left: none;
                }
            }
            .phone-box {
                position: relative;
                display: flex;
                width: 150px;
                justify-content: flex-end;
                margin-right: 10px;
            }
        }

        .date {
            input {
                width: 100px;
            }
            & > div {
                & > input:nth-child(1) {
                    margin-right: 10px;
                }
            }
        }
    }

    .send {
        font-family: Arial-BoldMT;
        font-size: 36px;
        color: #020b3b;
        background-color: #ffcc69;
        text-align: center;
        height: 100px;
        line-height: 100px;
    }
}

.swiper-pagination {
    display: flex;
    justify-content: center;

    /deep/.my-bullet {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        display: block;
        margin: 0 5px;
    }

    /deep/.my-bullet-active {
        background-color: #ffcc69;
    }
}
</style>
