// 设置localStroage
export const setLocal = (key, value) => {
  localStorage.setItem(key, value)
}

// 获取localStorage
export const getLocal = key => {
  return localStorage.getItem(key)
}

// 删除localStorage
export const delLocal = key => {
  localStorage.removeItem(key)
}

// 设置sessionStorage
export const setSession = (key, value) => {
  return sessionStorage.setItem(key, value)
}

// 获取sessionStorage
export const getSession = key => {
  return sessionStorage.getItem(key)
}

// 删除sessionStorage
export const delSession = key => {
  return sessionStorage.removeItem(key)
}

// 删除全部sessionStorage
export const delAllSession = () => {
  return sessionStorage.clear()
}
