export const getSysLang = () => {
    const type = window.navigator.appName
    let lang = ""
    if (type === "Netscape") {
        lang = window.navigator.language
    } else {
        lang = window.navigator.userLanguage
    }
    return lang.substr(0, 2)
}
