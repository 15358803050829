import diyPick from '@/components/diyPick/diyPick.vue';
import diyDateTime from '@/components/diyDateTime/diyDateTime.vue';
const yachtSelect = {
  data() {
    return {
      currentDate: new Date(),
      // PERSON
      personOptions: {
        title: 'Please select your Number Of Guests',
        personList: {
          PERSON: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
          ],
        },
        show: false,
        value: '',
      },

      // TIME
      timeOptions: {
        title: 'Please select your Charter Time',
        list: {
          AM: [6, 7, 8, 9, 10, 11, 12],
          PM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },
        show: false,
        value: '',
      },

      // HOURS
      hoursOptions: {
        title: 'Please select your Charter Hours',
        list: {
          HOURS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        show: false,
        value: '',
      },

      //DATE
      dateOptions: {
        title: 'Please select your date',
        show: false,
        value: '',
        date: new Date(),
      },
    };
  },
  methods: {
    // 时间选择器
    timeChange(e) {
      console.log(e);
    },
    // 控件显示操作
    showPerson() {
      this.personOptions.show = true;
    },
    showDate() {
      this.dateOptions.show = true;
    },
    showHours() {
      this.hoursOptions.show = true;
    },
    showTime() {
      this.timeOptions.show = true;
    },
    // 关闭pick 和 time 控件
    unShow() {
      this.personOptions.show = false;
      this.timeOptions.show = false;
      this.hoursOptions.show = false;
      this.dateOptions.show = false;
    },
    // 控件确定操作
    personConfirm(value) {
      // let text = value[0] + ' ' + value[1];
      this.$set(this.personOptions, 'value', value[0]);
      // 本地存储
      this.setLocValue({ person: value[0] });
      // 筛选游艇列表
      let params = {
        passengerNum: value[0],
      };
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.timeOptions.value) {
        if (this.timeOptions.value.indexOf('AM') != -1) {
          params['beginTime'] = `${
            this.timeOptions.value.split('A')[0] > 9
              ? this.timeOptions.value.split('A')[0]
              : '0' + this.timeOptions.value.split('A')[0]
          }:00`;
        }
        if (this.timeOptions.value.indexOf('PM') != -1) {
          params['beginTime'] = `${
            +this.timeOptions.value.split('P')[0] + 12
          }:00`;
        }
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    timeConfirm(value) {
      let text = value[0] + '' + value[1];
      this.$set(this.timeOptions, 'value', text);
      this.setLocValue({ time: value[0], showTime: text });
      // 筛选游艇列表
      let params = {};
      if (this.timeOptions.value.indexOf('AM') != -1) {
        params['beginTime'] = `${
          this.timeOptions.value.split('A')[0] > 9
            ? this.timeOptions.value.split('A')[0]
            : '0' + this.timeOptions.value.split('A')[0]
        }:00`;
      }
      if (this.timeOptions.value.indexOf('PM') != -1) {
        params['beginTime'] = `${
          +this.timeOptions.value.split('P')[0] + 12
        }:00`;
      }
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    hourConfirm(value) {
      let text = value[0] + ' ' + value[1];
      this.$set(this.hoursOptions, 'value', text);
      this.setLocValue({ hour: value[0] });
      // 筛选游艇列表
      let params = {};
      if (this.timeOptions.value) {
        if (this.timeOptions.value.indexOf('AM') != -1) {
          params['beginTime'] = `${
            this.timeOptions.value.split('A')[0] > 9
              ? this.timeOptions.value.split('A')[0]
              : '0' + this.timeOptions.value.split('A')[0]
          }:00`;
        }
        if (this.timeOptions.value.indexOf('PM') != -1) {
          params['beginTime'] = `${
            +this.timeOptions.value.split('P')[0] + 12
          }:00`;
        }
      }
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    dateConfirm(value) {
      this.$set(this.dateOptions, 'value', value);
      this.setLocValue({ date: this.dayjs(value).format('YYYY/MM/DD') });
      // 筛选游艇列表
      let params = {
        rentDate: this.dayjs(value).format('YYYY-MM-DD'),
      };
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      if (this.timeOptions.value) {
        if (this.timeOptions.value.indexOf('AM') != -1) {
          params['beginTime'] = `${
            this.timeOptions.value.split('A')[0] > 9
              ? this.timeOptions.value.split('A')[0]
              : '0' + this.timeOptions.value.split('A')[0]
          }:00`;
        }
        if (this.timeOptions.value.indexOf('PM') != -1) {
          params['beginTime'] = `${
            +this.timeOptions.value.split('P')[0] + 12
          }:00`;
        }
      }
      if (this.hoursOptions.value) {
        params['rentHours'] = this.hoursOptions.value.split(' ')[0];
      }
      this.$store.dispatch('getRentalYachtsList', params);
    },
    getRentalYachtsList() {
      let passengerNum =
        this.personOptions.value === '' ? 1 : this.personOptions.value;
      let rentDate =
        this.dateOptions.value === ''
          ? this.dayjs(new Date(rentDate)).format('YYYY-MM-DD')
          : this.dateOptions.value;
    },
    // 本地存储筛选信息
    setLocValue(params) {
      // 本地存储用户信息
      this.$store.commit('getUserOrderInfo', {
        ...this.$store.state.home.userOrderInfo,
        ...params,
      });
    },
  },
  created() {},
  components: {
    diyPick,
    diyDateTime,
  },
  computed: {
    personColumns() {
      let a = [
        {
          values: this.personOptions.personList['PERSON'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.personOptions.personList),
          className: 'column1',
        },
      ];
      return a;
    },
    timeColumns() {
      let a = [
        {
          values: this.timeOptions.list['AM'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.timeOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
    hoursColumns() {
      let a = [
        {
          values: this.hoursOptions.list['HOURS'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.hoursOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
  },
  mounted() {
    let passengerNum =
      this.personOptions.value === '' ? 1 : this.personOptions.value;
    //明天的时间
    let tomorrow = new Date();
    tomorrow.setTime(tomorrow.getTime() + 24 * 60 * 60 * 1000);
    this.dateOptions.value = this.dayjs(tomorrow).format('YYYY/MM/DD');
    this.setLocValue({ date: this.dayjs(tomorrow).format('YYYY-MM-DD') });
    this.$store.dispatch('getRentalYachtsList', {
      rentDate: this.dayjs(tomorrow).format('YYYY-MM-DD'),
    });
  },
};
export default yachtSelect;
