import { getAllParams } from '@/libs/util'
import i18n from '@/vueI18n'
import router from '@/router'
const PayType = {
    data(){
        return{
            show:false,
            type:'success',
            money:'null',
        }
    },
    methods:{
        unShow(){
            this.show = false
            if(i18n.locale === 'zh-EN'){
                this.$router.push('/english/home')
            }else{
                this.$router.push('/chinese/home')
            }
        }
    },
    created(){
        let href = new URL(window.location.href)
        if(href.searchParams.get('payStatus')){
            this.type = href.searchParams.get('payStatus')
            this.show = true
            this.money = href.searchParams.get('amount') || 0
        }
    }

}
export default PayType