import Vue from "vue"
import VueRouter from "vue-router"

import Home from "@/views/Home"
import yachtTours from "@/views/yachtTours"
import bookOrder from "@/views/bookOrder"
import superYachts from "@/views/superYachts"
import waterSports from "@/views/waterSports"
import news from "@/views/news"

import enRoutes from "./lang/english"
import chRoutes from "./lang/chinese"
// 引入store文件
import store from "@/store"
// 引入i18n文件
import i18n from "@/vueI18n"

const contact = () => import("@/views/contactUs")
const team = () => import("@/views/myTeam")

Vue.use(VueRouter)

const routes = [
    ...enRoutes,
    ...chRoutes,
    // {
    //     path: "*",
    //     redirect: "/english/home",
    // },
    // { path: "/", redirect: "/english/home" },

    // {
    //   path: '/home',
    //   component: Home,
    // },
    // { path: '/yachtTours', component: yachtTours },
    // { path: '/bookOrder', component: bookOrder, meta: { footNoShow: true } },
    // { path: '/superYachts', component: superYachts },
    // { path: '/waterSports', component: waterSports },
    // {
    //   path: '/news',
    //   component: news,
    // },
    // {
    //   path: '/contact',
    //   component: contact,
    // },
    // {
    //   path: '/team',
    //   component: team,
    // },
]

const router = new VueRouter({
    mode: "history",
    routes,
    base: "/",
})
const routerList = [
    {
        path: "*",
        redirect: `/${i18n.locale === "zh-CH" ? "chinese" : "english"}/home`,
    },
    {
        path: "/",
        redirect: `/${i18n.locale === "zh-CH" ? "chinese" : "english"}/home`,
    },
]
router.addRoutes(routerList)

// 导航守卫;
router.beforeEach((to, from, next) => {
    store.dispatch("getBaseConfigInfo")
    next()
})

export default router
