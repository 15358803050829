import Mock from 'mockjs';
import rentalYachtsList from './JSON/rentalYachtsList.json';
import shareYachtsList from './JSON/shareYachtsList.json';
import yachtsDetail from './JSON/yachtsDetail.json';
import addYachtsOrder from './JSON/addYachtsOrder.json';
import teachList from './JSON/teachList.json';
import addTeachOrder from './JSON/addTeachOrder.json';
import addSuperYachtsOrder from './JSON/addSuperYachtsOrder.json';
import calcYachtOrder from './JSON/calcYachtOrder.json';
import calcTeachOrder from './JSON/calcTeachOrder.json';
import superYachtsList from './JSON/superYachtsList'

Mock.mock('/mock/yachts/rentalYachtsList', rentalYachtsList);

Mock.mock('/mock/yachts/shareYachtsList', shareYachtsList);

Mock.mock('/mock/yachts/yachtsDetail', yachtsDetail);

Mock.mock('/mock/yachts/addYachtsOrder', addYachtsOrder);

Mock.mock('/mock/yachts/addTeachOrder', addTeachOrder);

Mock.mock('/mock/yachts/addSuperYachtsOrder', addSuperYachtsOrder);

Mock.mock('/mock/yachts/calcYachtOrder', calcYachtOrder);

Mock.mock('/mock/teach/calcTeachOrder', calcTeachOrder);

Mock.mock('/mock/teach/list', teachList);

Mock.mock('/mock/yachts/superYachtsList',superYachtsList)
