import diyPick from '@/components/diyPick/diyPick.vue';
import diyDateTime from '@/components/diyDateTime/diyDateTime.vue';
const yachtSelect = {
  methods: {
    // 选择人数
    showPerson() {
      this.personOptions.show = true;
    },
    // 选择时间
    showDate() {
      this.dateOptions.show = true;
    },
    showTime() {
      this.timeOptions.show = true;
    },
    unShow() {
      this.personOptions.show = false;
      this.timeOptions.show = false;
      this.dateOptions.show = false;
    },
    personConfirm(value) {
      //   let text = value[0] + ' ' + value[1];
      this.$set(this.personOptions, 'value', value[0]);
      // 筛选游艇列表
      let params = {
        passengerNum: value[0],
      };
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.timeOptions.value) {
        // english
        if (this.timeOptions.value.indexOf('AM') != -1) {
          let time = this.timeOptions.value.split(' ')[0];
          params['beginTime'] = `${time}:00`;
        } else if (this.timeOptions.value.indexOf('PM') != -1) {
          let time = this.timeOptions.value.split(' ')[0];
          params['beginTime'] = `${+time + 12}:00`;
        }
        // chinese
        if (this.timeOptions.value.indexOf('上午') != -1) {
          let time = this.timeOptions.value.split(' ')[1];
          params['beginTime'] = `${time}:00`;
        } else if (this.timeOptions.value.indexOf('下午') != -1) {
          let time = this.timeOptions.value.split(' ')[1];
          params['beginTime'] = `${+time + +12}:00`;
        }
      }
      this.$store.dispatch('getShareYachtsList', params);
    },
    timeConfirm(value) {
      let text = value[0] + ' ' + value[1];
      if (text.indexOf('上午') != -1) {
        this.$set(this.timeOptions, 'value', '上午 ' + value[0] + ' 点');
      } else if (text.indexOf('下午') != -1) {
        this.$set(this.timeOptions, 'value', '下午 ' + value[0] + ' 点');
      } else {
        this.$set(this.timeOptions, 'value', text);
      }

      // 筛选游艇列表
      let params = {};
      if (value[1].indexOf('AM') != -1 || value[1].indexOf('上午') != -1) {
        params['beginTime'] = `${value[0]}:00`;
      } else {
        params['beginTime'] = `${value[0] + +12}:00`;
      }
      if (this.dateOptions.value) {
        params['rentDate'] = this.dayjs(this.dateOptions.value).format(
          'YYYY-MM-DD'
        );
      }
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      this.$store.dispatch('getShareYachtsList', params);
    },
    dateConfirm(value) {
      this.$set(this.dateOptions, 'value', value);
      // 筛选游艇列表
      let params = {
        rentDate: this.dayjs(value).format('YYYY-MM-DD'),
      };
      if (this.personOptions.value) {
        params['passengerNum'] = this.personOptions.value;
      }
      if (this.timeOptions.value) {
        if (
          this.timeOptions.value.indexOf('AM') != -1 ||
          this.timeOptions.value.indexOf('上午') != -1
        ) {
          let time = this.timeOptions.value.replace(/[^\d]/g, '');
          params['beginTime'] = `${time}:00`;
        } else {
          let time = this.timeOptions.value.replace(/[^\d]/g, '');
          params['beginTime'] = `${+time + 12}:00`;
        }
      }
      this.$store.dispatch('getShareYachtsList', params);
    },
  },
  mounted() {
    //明天的时间
    let tomorrow = new Date();
    tomorrow.setTime(tomorrow.getTime() + 24 * 60 * 60 * 1000);
    this.dateOptions.value = tomorrow;
    this.$store.dispatch('getShareYachtsList', {
      rentDate: this.dayjs(tomorrow).format('YYYY-MM-DD'),
    });
  },
  components: {
    diyPick,
    diyDateTime,
  },
  computed: {
    personColumns() {
      let a = [
        {
          values: this.personOptions.personList['PERSON'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.personOptions.personList),
          className: 'column1',
        },
      ];
      return a;
    },
    timeColumns() {
      let a = [
        {
          values: this.timeOptions.list['AM'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.timeOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
    hoursColumns() {
      let a = [
        {
          values: this.hoursOptions.list['HOURS'],
          className: 'column2',
          defaultIndex: 2,
        },
        {
          values: Object.keys(this.hoursOptions.list),
          className: 'column1',
        },
      ];
      return a;
    },
  },
};
export default yachtSelect;
