<template>
    <div>
        <div v-if="teach.length > 0" class="sport-list">
            <div class="item" v-for="(item, index) in teach" :key="index">
                <div class="left">
                    <img :src="item.imageUrl" alt="" />
                </div>
                <div class="right">
                    <div class="title">
                        <p>
                            <!-- {{ pitem }} -->
                            {{ item.teachName.join(" ") }}
                        </p>
                    </div>
                    <div class="box">
                        <p class="detailDescribe" v-for="(pitem, pindex) in item.detailDescribe" :key="pindex">
                            {{ pitem }}
                        </p>
                        <div class="diffculty">
                            <h2>diffculty</h2>
                            <div class="grade">
                                <div
                                    v-for="(v, index) in 5"
                                    :key="index"
                                    :class="{ solid: index < item.difficultyLevel }"
                                ></div>
                            </div>
                        </div>
                        <div class="price">AED {{ item.price }}/HR</div>
                    </div>

                    <div class="btn" @click="showPoup(item.teachId, item.teachName, index)">BOOK INSTANTLY</div>
                </div>
            </div>
        </div>
        <Empty v-else />
        <van-popup @close="back" v-model="poup" class="poup">
            <div class="container">
                <div class="title">WATER SPORTS BOOKING</div>
                <div class="form">
                    <div class="cel">
                        <div class="name">NAME</div>
                        <div class="input">
                            <input
                                type="text"
                                v-model="options.name"
                                oninput="if(value.length > 64) value = value.slice(0,64)"
                            />
                        </div>
                    </div>
                    <div class="cel phone">
                        <div class="name">PHONE</div>
                        <div class="input phoneCode">
                            <div class="phone-box">
                                <input
                                    type="text"
                                    :value="options.qz ? '+ ' + options.qz : ''"
                                    v-number
                                    readonly
                                    @click="showAreaCode"
                                />
                                <i class="arrow-down" @click="showAreaCode"><van-icon name="arrow-down" /></i>
                            </div>
                            <input
                                type="text"
                                v-model="options.phone"
                                v-number
                                oninput="if(value.length > 15) value = value.slice(0,15)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">E-MAIL</div>
                        <div class="input">
                            <input
                                type="text"
                                v-model="options.email"
                                oninput="if(value.length > 64) value = value.slice(0,64)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">ADULTS</div>
                        <div class="input">
                            <input
                                type="text"
                                v-model="options.adults"
                                v-number
                                oninput="if(value.length > 4) value = value.slice(0,4)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">HOUR</div>
                        <div class="input" @click="showHours">
                            <input type="text" :value="hoursOptions.value === '' ? '' : hoursOptions.value" readonly />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">ACTIVITY</div>
                        <div class="input">
                            <input
                                type="text"
                                readonly
                                v-model="product.teachName"
                                oninput="if(value.length > 10) value = value.slice(0,10)"
                            />
                        </div>
                    </div>
                    <div class="cel">
                        <div class="name">DATE</div>
                        <div class="input" @click="showDate">
                            <input
                                type="text"
                                readonly
                                :value="
                                    dateOptions.value === ''
                                        ? ''
                                        : dayjs(dateOptions.value).format('YYYY/MM/DD') + ' ' + ShowTime
                                "
                            />
                        </div>
                    </div>
                    <div class="total">
                        <p>TOTAL COST(INC.5% VAT)</p>
                        <p>
                            <input type="text" readonly v-model="options.total" />
                        </p>
                    </div>
                </div>
                <div class="btn">
                    <div @click="back">BACK</div>
                    <div @click="commit">OK</div>
                </div>
            </div>
        </van-popup>
        <diyDateTime
            :title="dateOptions.title"
            @unShow="unShow"
            :show="dateOptions.show"
            @confirm="dateConfirm"
            dateType="datehour"
        />
        <diyPick
            :list="hoursOptions.list"
            :show="hoursOptions.show"
            :title="hoursOptions.title"
            :columns="hoursColumns"
            @unShow="unShow"
            @confirm="hourConfirm"
        />
        <areaCode :show="show.areaCode" @unShow="closeAreaCode" @confirm="areaCodeConfirm" />
    </div>
</template>

<script>
import sportList from "@/mixin/waterSports/sportList.js"
export default {
    mixins: [sportList],
    data() {
        return {
            // TIME
            hoursOptions: {
                title: "Please select your Charter Hours",
                list: {
                    HOURS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
                },
                show: false,
                value: "",
            },
        }
    },
    computed: {
        hoursColumns() {
            let a = [
                {
                    values: this.hoursOptions.list["HOURS"],
                    className: "column2",
                    defaultIndex: 2,
                },
                {
                    values: Object.keys(this.hoursOptions.list),
                    className: "column1",
                },
            ]
            return a
        },
    },
}
</script>

<style lang="less" scoped>
.sport-list {
    margin: 0 20px;

    .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        overflow: hidden;
        height: 440px;

        .left {
            width: 345px;
            height: 440px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .right {
            width: 345px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            overflow: hidden;

            .title {
                margin-bottom: 20px;
                p {
                    font-family: Arial-BoldMT;
                    font-size: 30px;
                    color: #333333;
                    text-align: center;
                    font-weight: bold;
                }
            }
            .box {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            ul {
                margin-top: 10px;

                li {
                    font-family: ArialMT;
                    font-size: 22px;
                    color: #333333;
                    text-align: center;
                }
            }

            .detailDescribe {
                font-family: ArialMT;
                font-size: 22px;
                color: #333333;
                text-align: center;
                width: 320px;
            }

            .diffculty {
                margin-top: 20px;
                h2 {
                    font-family: Arial-BoldMT;
                    font-size: 24px;
                    color: #333333;
                    font-weight: bold;
                    text-align: center;
                }

                .grade {
                    display: flex;
                    margin-top: 10px;
                    margin-bottom: 20px;

                    div {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin: 0 5px;
                        border: 2px solid #116fbb;
                    }

                    .solid {
                        background-color: #116fbb;
                    }
                }
            }

            .price {
                font-family: Arial-BoldMT;
                font-size: 30px;
                color: #333333;
                font-weight: bold;
                margin-bottom: 80px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 250px;
                text-align: center;
            }

            .btn {
                position: absolute;
                bottom: 0;
                height: 60px;
                background-color: #ffcc69;
                width: 100%;
                text-align: center;
                line-height: 60px;
                font-family: Arial-BoldMT;
                font-size: 30px;
                color: #333333;
                font-weight: bold;
            }
        }
    }

    .item:nth-child(2n) {
        flex-direction: row-reverse;
    }
}

.poup {
    .container {
        width: 670px;

        .title {
            height: 100px;
            text-align: center;
            line-height: 100px;
            font-family: Arial-BoldMT;
            font-size: 36px;
            color: #333333;
            background-color: #ffcc69;
        }

        .form {
            background-color: #fff;
            padding: 0 40px 0 55px;
            padding-top: 46px;

            .cel {
                display: flex;
                margin-bottom: 24px;

                .name {
                    width: 140px;
                    min-width: 140px;
                    text-align: right;
                    padding-right: 10px;
                    box-sizing: border-box;
                    line-height: 50px;
                    font-family: Arial-BoldMT;
                    font-size: 24px;
                    color: #333333;
                    margin-right: 20px;
                }

                .input {
                    flex: 1;
                    display: flex;
                }

                input {
                    height: 50px;
                    line-height: 50px;
                    border: solid 2px #cccccc;
                    flex: 1;
                    padding-left: 10px;
                }
            }

            .phone {
                .input {
                    input:first-child {
                        width: 150px;
                        flex: 0;
                    }

                    input:last-child {
                        flex: 1;
                        width: 238px;
                    }
                }
                .phoneCode {
                    position: relative;
                    .arrow-down {
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding-right: 8px;
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        height: 100%;
                        border: 2px solid #cccccc;
                        border-left: none;
                    }
                }
                .phone-box {
                    position: relative;
                    display: flex;
                    width: 150px;
                    justify-content: flex-end;
                    margin-right: 16px;
                }
            }
        }

        .total {
            & > p:nth-child(1) {
                font-family: Arial-BoldMT;
                font-size: 24px;
                color: #333333;
                margin-bottom: 14px;
            }

            input {
                width: 100%;
                height: 50px;
                border: solid 2px #cccccc;
                padding-left: 10px;
            }
        }

        .btn {
            display: flex;
            margin-top: 60px;

            div {
                flex: 1;
                height: 100px;
                outline: none;
                border: none;
                font-family: Arial-BoldMT;
                font-size: 36px;
                line-height: 100px;
                display: block;
                text-align: center;
            }

            & > div:first-child {
                background-color: #020b3b;
                color: #ffcc69;
            }

            & > div:last-child {
                color: #020b3b;
                background-color: #ffcc69;
            }
        }
    }
}
</style>
