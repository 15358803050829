"use strict"

const config = {
    baseUrl: process.env.VUE_APP_BASEURL || "http://yachts.hub.diyiqiangren.com/api/v1",
    appId: process.env.VUE_APP_APPID || "504ca34700e711ecb2500af10e715ce6",
    appKey: process.env.VUE_APP_APPKEY || "61ec617f00e711ecb2500af10e715ce6",
    timeout: process.env.VUE_APP_TIMEOUT || 30000,
    errMsg: {
        404: "没有找到路径404 \n Page does not exist 404  ",
        500: "服务器内部错误 \n Server internal error 500",
        timeout: "请求超时 \n The request timeout",
        UnKnown: "请求失败，请刷新重试 \n Request failed. Please refresh and try again",
    },
    appVersion: process.env.VUE_APP_VERSION,
    mode: process.env.NODE_ENV,
}

export default config
