import headLine from "@/components/headLine/headLine.vue"
import diyDateTime from "@/components/diyDateTime/diyDateTime.vue"
import { mapState } from "vuex"
import areaCode from "@/components/myPick"
import { check_Number, check_Mail, check_FloatNumber, check_positiveInt } from "@/libs/form"
import Empty from "@/components/Empty"
import { ImagePreview } from "vant"
import { inserSeoElement } from "@/libs/util"
const mixin = {
    data() {
        return {
            swiperOption: {
                spaceBetween: 30,
                effect: "fade",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    bulletActiveClass: "my-bullet-active",
                    bulletClass: "my-bullet", //需设置.my-bullet样式
                },
                autoplay: {
                    delay: 3000,
                },
            },
            //DATE
            beginOptions: {
                title: "Please select a start time",
                show: false,
                value: "",
                date: new Date(),
            },
            endOptions: {
                title: "Please select a end time",
                show: false,
                value: "",
                date: new Date(),
            },
            enquireShow: false,
            options: {
                name: "",
                phone: "",
                qz: "",
                email: "",
                yachit: "",
                adults: "",
                children: "",
                tourdate: "",
                benginDate: "",
                endDate: "",
            },
            productId: null,
            index: 0,
            show: {
                areaCode: false,
            },
        }
    },
    methods: {
        // 选择时间
        showBegin() {
            this.beginOptions.show = true
        },
        showEnd() {
            this.endOptions.show = true
        },
        unShow() {
            this.beginOptions.show = false
            this.endOptions.show = false
        },
        benginConfirm(value) {
            this.$set(this.beginOptions, "value", value)
            this.options.benginDate = this.dayjs(value.setHours(0, 0, 0)).format(
                "YYYY-MM-DD HH:mm:ss"
            )
        },
        endConfirm(value) {
            this.$set(this.endOptions, "value", value)
            this.options.endDate = this.dayjs(value.setHours(23, 59, 59)).format(
                "YYYY-MM-DD HH:mm:ss"
            )
        },
        // 详情展开
        describe(index) {
            this.yachtList[index].more = true
        },
        // 显示咨询窗口
        showEnquire(productId, index) {
            this.productId = productId
            this.index = index
            this.enquireShow = true
            this.$set(this.options, "yacht", this.yachtList[index].productName)
        },
        async sendEnquiry() {
            let { name, phone, email, yacht, adults, children, endDate, benginDate, qz } =
                this.options
            let maxPassenger = this.yachtList[this.index].maxPassenger

            if (name === "") {
                return this.$toast(this.$t("form.nameCheck"))
            }
            if (qz === "") {
                return this.$toast(this.$t("form.qzCheck"))
            }
            if (
                phone == "" ||
                phone.length + qz.length > 20 ||
                phone.length + qz.length < 6 ||
                !check_Number(phone)
            ) {
                return this.$toast(this.$t("form.phoneCheck"))
            }
            if (email === "" || !check_Mail(email)) {
                return this.$toast(this.$t("form.emailCheck"))
            }
            if (adults === "" || adults == 0 || !check_Number(adults)) {
                return this.$toast(this.$t("form.adultsCheck"))
            }
            if (!check_Number(children)) {
                if (children !== "") {
                    return this.$toast(this.$t("form.childrenCheck"))
                }
            }
            if (endDate === "" && benginDate === "") {
                return this.$toast(this.$t("form.dateCheck"))
            }
            if (benginDate === "") {
                return this.$toast(this.$t("form.benginDate"))
            }
            if (endDate === "") {
                return this.$toast(this.$t("form.endDateCheck"))
            }
            if (+adults + +children > maxPassenger) {
                return this.$toast(this.$t("form.maxPeople") + maxPassenger)
            }
            if (this.dayjs(benginDate).isAfter(this.dayjs(endDate))) {
                return this.$toast(this.$t("form.timeBar"))
            }

            let orderMemberInfoCmds = []
            for (let i = 1; i <= +adults; i++) {
                orderMemberInfoCmds.push({
                    memberName: "Unknown Name",
                    memberType: 1,
                    memberState: 0,
                })
            }

            for (let i = 1; i <= +children; i++) {
                orderMemberInfoCmds.push({
                    memberName: "Unknown Name",
                    memberType: 2,
                    memberState: 0,
                })
            }
            // 创建超级游艇订单请求

            let params = {
                productId: this.productId,
                realName: name,
                email,
                mobileNum: qz + "-" + phone,
                adultNum: adults,
                childNum: children ? children : 0,
                takeOrderType: this.yachtList[this.index].takeOrderType,
                rentType: this.yachtList[this.index].rentType,
                // rentDate:tourdate
                beginRentDate: benginDate,
                endRentDate: endDate,
                yacht,
                orderMemberInfoCmds: JSON.stringify(orderMemberInfoCmds),
            }
            try {
                await this.$store.dispatch("addSuperYachtsOrder", params)
                this.resetOptions()
                this.enquireShow = false
            } catch (error) {}
        },
        resetOptions() {
            this.options = {
                name: "",
                phone: "",
                qz: "",
                email: "",
                yachit: "",
                adults: "",
                children: "",
                tourdate: "",
                endDate: "",
                benginDate: "",
            }
            this.$set(this.beginOptions, "value", "")
            this.$set(this.endOptions, "value", "")
        },
        // 国际代码弹窗
        showAreaCode() {
            this.$set(this.show, "areaCode", true)
        },
        // 国际代码关闭
        closeAreaCode() {
            this.$set(this.show, "areaCode", false)
        },
        areaCodeConfirm(value) {
            this.$set(this.options, "qz", value.phone_code)
        },
        showImage(arr, index) {
            ImagePreview({
                images: arr,
                startPosition: index,
            })
        },
    },
    computed: {
        ...mapState({
            // 超级游艇列表
            yachtList: state => state.SuperYachtsOrder.superYachtsList,
        }),
    },
    components: {
        headLine,
        diyDateTime,
        Empty,
        areaCode,
    },
    watch: {
        enquireShow: {
            handler() {
                if (!this.enquireShow) {
                    this.resetOptions()
                }
            },
        },
    },
    mounted() {
        inserSeoElement(
            this.$t("headSeo.superTitle"),
            this.$t("headSeo.superKey"),
            this.$t("headSeo.superDec")
        )
    },
}

export default mixin
