<template>
  <div class="about-us">
    <div class="title">{{ $t('bookOrder.cancelTitle') }}</div>
    <div
      class="text"
      v-html="$store.state.baseConfig.baseConfigInfo.privacyPolicy"
    ></div>
  </div>
</template>

<script>
/*
 *@Date: 2022-09-14 10:37:07
 *@Description: 模块描述
 */
export default {
  name: '',
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.about-us {
  width: 710px;
  padding-bottom: 100px;
  position: relative;
  .title {
    // font-family: Bintang;
    font-size: 36px;
    color: #333333;
    padding: 70px 0 50px 0;
    text-align: center;
    font-weight: 700;
  }
  .title-chinese {
    font-family: SourceHanSansCN-Bold;
    font-size: 36px;
    color: #333333;
  }

  .textTitle {
    font-size: 28px;
    color: #333333;
    font-weight: 600;
    margin: 20px 0;
  }
  .text {
    font-size: 24px;
    padding: 0 40px;
    // margin: 20px;
    height: 800px;
    overflow-y: scroll;
    line-height: 50px;
  }

  .text::-webkit-scrollbar {
    width: 10px;
    height: 13px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .text::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 28px;
  }

  .text::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  /deep/.text {
    img {
      max-width: 815px;
      object-fit: cover;
    }
    video {
      max-width: 815px;
    }
    a {
      color: #020b3b;
      text-decoration: none;
    }
  }
}
</style>
