const lang = {
    header: {
        version: "版本",
        aboutUs: "关于我们",
        englist: "English",
        chinese: "中文",
        callUs: "联系我们:",
    },
    noData: '"暂时没有数据"',
    navList: {
        home: "游艇整租",
        yachtTours: "游艇散拼",
        superYachts: "超级游艇",
        waterSports: "水上运动",
        news: "最新消息",
        contact: "联系我们",
        team: "公司团队",
    },
    form: {
        h: "小时",
        success: "成功",
        fail: "失败",
        ok: "确认",
        back: "取消",
        nameCheck: "用户名不能为空且不能超过64位字符",
        phoneCheck: "区号+手机号6-20位",
        adultsCheck: "成人人数必须是数字且不为空",
        childrenCheck: "儿童人数必须是数字且不为空",
        endDateCheck: "结束时间不能为空",
        benginDate: "开始时间不能为空",
        dateCheck: "日期不能为空",
        timeCheck: "旅游时间不能为空",
        hoursCheck: "时间不能为空",
        qzCheck: "区号必须是数字且不能为空",
        emailCheck: "邮箱格式不正确",
        peoplesCheck: "人数大于游艇的容量",
        maxPeople: "乘客超过最大容量",
        timeBar: "开始时间不能超过结束时间",
        mustAdultCheck: "至少要有一位成人",
        minTime: "最小时间:",
        messageBoard: "留言不能为空",
    },
    bookOrder: {
        enterToast: "出发时间或者持续时间未填写",
        agree: "请同意服务条款和撤销政策",
        error: "结束时间必须在深夜12点之前",
        cancelTitle: "撤销政策",
        serviceTitle: "服务条款",
    },
    team: {
        teams_post1: "总经理",
        teams_post2: "行政经理",
        teams_post3: "销售经理",
        teams_post4: "运营部副理",
        teams_post5: "运营部副理",
        teams_post6: "销售经理",
        teams_post7: "市场部经理",
        teams_post8: "人事专员",
        teams_post9: "行政专员",
        teams_post10: "行销专员",
        teams_post11: "平面设计师",
        teams_post12: "会计专员",
        teams_post13: "会计专员",
        teams_post14: "会计专员",
        teams_post15: "市场部经理",
        teams_post16: "销售专员",
        teams_post17: "销售专员",
        teams_post18: "销售专员",
        teams_post19: "运营部专员",
        teams_post20: "旅游部经理",
        teams_post21: "销售专员",
        teams_post22: "销售专员",
        teams_post23: "销售专员",
        teams_post24: "运营部专员",
        teams_post25: "行政前台",
        teams_post26: "运营部司机",
        teams_post27: "运营部司机",
        teams_post28: "运营部司机",

        captain_post1: "船长",
        captain_post2: "船长",
        captain_post3: "船长",
        captain_post4: "船长",
        captain_post5: "船长",
        captain_post6: "船长",
        captain_post7: "船长",
        captain_post8: "船长",
        captain_post9: "船长",
        captain_post10: "船长",
        captain_post11: "船员",
        captain_post12: "船员",
        captain_post13: "船员",
        captain_post14: "船员",
        captain_post15: "摩托艇教练",
        captain_post16: "船员",
        captain_post17: "船员",
        captain_post18: "船员",
        captain_post19: "船员",
        captain_post20: "船员",
        captain_post21: "船员",
        captain_post22: "船员",
        captain_post23: "喷气滑雪队长",
        captain_post24: "船员",
        captain_post25: "船员",
        captain_post26: "船员",
        captain_post27: "船员",
        captain_post28: "船员",
        captain_post29: "船员",
        captain_post30: "船员",
        captain_post31: "船员",
        captain_post32: "船员",
        captain_post33: "船员",
        repair_post1: "木匠",
        repair_post2: "工程师",
        repair_post3: "电气工程师",
        repair_post4: "油漆工",
        repair_post5: "电气工程师",
        repair_post6: "工程师",
        repair_post7: "行政",
    },
    question: {
        tour_question1: "游艇将从哪里出发呢？",
        tour_question2: "可以下水游泳吗？",
        tour_question3: "有具体的着装要求吗？",
        tour_question4: "我们的行程之旅那天应该什么时候到达？",
        tour_question5: "我们在游艇上可以穿鞋吗？",
        tour_question6: "如果我迟到了怎么办？",
        tour_question7: "取消政策是什么？",
        tour_question8: "游艇旅途中会有停下来拍照的机会吗？",
        tour_question9: "我可以带食物和零食进来吗？",
        tour_question10: "我们可以自带酒水吗？",

        tour_answer1: "我们从迪拜港出发。",
        tour_answer2: "抱歉，不可以",
        tour_answer3: "没有要求，我们建议您穿适合当天天气的衣服。",
        tour_answer4: "请比预订时间提早20~30分钟到达即可。",
        tour_answer5: "为了乘客的安全以及保持游艇干净整洁，我们需要每位成员脱掉鞋子。",
        tour_answer6: "游艇将会在约定的时间准点启航。未能如约而至的成员我们深表歉意，恕不退款。",
        tour_answer7:
            "出发前24小时内免费取消。请注意，退款的时长可能需要多达14天后才能返还到您的帐户。",
        tour_answer8: "船长将允许您拍照并且满足您的要求，无需担心此次行程中无法拍照的遗憾",
        tour_answer9: "游艇上将提供餐食和软饮，烧烤将包括肉类、鸡肉和素食，以及配菜沙拉和面包。",
        tour_answer10: "抱歉，游艇上严禁饮酒。",
    },
    waterQuestion: {
        surf_question1: "我需要自带水上运动的相关设备吗？",
        surf_question2: "尾波冲浪很难吗？",
        surf_question3: "这是我第一次去体验如此酷的水上运动项目， 有人会告诉我怎么做吗？",
        surf_question4: "我是一名初学者。 我应该从哪里开始？ ",
        surf_question5: "尾波滑水比尾波冲浪更好玩吗？",
        surf_question6: "除此之外还包括哪些呢？",
        surf_question7: "我们可以自带冰淇淋、雪糕类的冷零食吗？",
        surf_answer1: "我们有冲浪板和救生夹克，只需要提前告知我们您喜欢的水上运动即可。",
        surf_answer2: "尾波冲浪刚接触时有一定难度，但我们认为您应该会去努力尝试这难忘的冲浪运动！",
        surf_answer3: "一位合格且经验丰富的运动教练将会在整个运动旅途中指导您如何完成整个过程。",
        surf_answer4:
            "这是一个较难回答清楚的问题，我们在想您为何不多花费一点时间去了解并尝试这些运动呢！",
        surf_answer5: "萝卜白菜各有所爱，您是属于哪种水上运动爱好者呢？",
        surf_answer6: "除了软饮料和水，我们将提供所有运动所需的安全设备。",
        surf_answer7: "当然可以",
    },
    title: "天行者",
    payType: {
        success: "支付成功",
        fail: "支付失败",
        ok: "我知道了",
        back: "返回",
        again: "重新支付",
    },
    headSeo: {
        indexTitle: "迪拜游艇租赁_豪华游艇观光_整租散拼轻松预定-天行者游艇",
        indexKey: "迪拜游艇租赁,迪拜豪华游艇出租,迪拜私人游艇包船,迪拜游艇整租散拼,迪拜游艇预订",
        indexDec:
            "天行者游艇是迪拜最受欢迎的游艇租赁公司之一，提供最优惠的价格和豪华游艇租赁服务，拥有迪拜游艇整租、豪华游艇观光、超级游艇包租、游艇包船散拼、水上运动、钓鱼俱乐部等多项服务,为客户提供安全、健康、个性的游艇体验。",
        charterTitle: "迪拜游艇整租_迪拜豪华游艇出租_游艇包船出海价格-天行者游艇",
        charterKey:
            "迪拜游艇整租,迪拜豪华游艇出租,迪拜游艇包船出海,迪拜游艇包船价格,游艇包船观光,迪拜私人游艇预定",
        charterDec:
            "Skywalker天行者游艇提供迪拜游艇整租服务，价格低至500迪拉姆，出租服务覆盖私人豪华游艇包船、迪拜游艇出租观光、游艇包船出海，游艇生日派对等，为客户提供舒适、健康的游艇体验。        ",
        superTitle: "迪拜超级游艇租赁_超级豪华游艇包租_游艇包船定制服务-天行者游艇",
        superKey: "迪拜超级游艇租赁,迪拜超级游艇包租,迪拜豪华游艇整租,游艇包船定制服务",
        superDec:
            "Skywalker天行者游艇是迪拜领先的超级游艇租赁平台，提供迪拜超级豪华游艇包租、整租，同时为尊贵的客户提供超级游艇包船定制服务，满足客户个性化需求，享受非凡的豪华游艇之旅。",
        shareTitle: "迪拜游艇散拼_豪华游艇拼船_私人游艇合租出海-天行者游艇",
        shareKey: "迪拜游艇散拼,豪华游艇拼船,私人游艇合租出海,迪拜游艇拼船价格",
        shareDec:
            "Skywalker天行者游艇提供迪拜游艇散拼、豪华游艇拼船、私人游艇合租出海等服务，每天5个时间段出海，游艇散拼价格175-350迪拉姆不等，配有糕点、果汁、咖啡、烧烤等食品，为您带来非凡的体验。",
        teachTitle: "迪拜水上运动_水上摩托_尾波冲浪_尾波滑水-天行者游艇",
        teachKey: "迪拜水上运动_水上摩托_尾波冲浪_尾波滑水-天行者游艇",
        teachDec:
            "Skywalker天行者游艇为客户提供各式各样的迪拜水上运动，有迪拜水上摩托、迪拜尾波冲浪、迪拜尾波滑水、造浪艇冲浪等多项运动，各项运动都有教练和潜水员陪伴，确保安全，为您带来非凡的水上体验。",
        fishTitle: "迪拜钓鱼俱乐部-迪拜深海钓鱼俱乐部",
        fishKey: "迪拜钓鱼俱乐部,迪拜深海钓鱼俱乐部",
        fishDec:
            "钓鱼有不同的风格，每种风格都使用不同类型的设备，迪拜的钓鱼俱乐部将帮助您学习钓鱼所需的一切。 查看所有钓鱼俱乐部的详细信息，请访问 Sky Walker。",
        teamTitle: "天行者游艇公司是迪拜最受欢迎的游艇租赁公司之一",
        teamKey: "天行者游艇公司,迪拜最受欢迎游艇公司,迪拜好评最多游艇公司",
        teamDec:
            "行者游艇公司是迪拜最受欢迎的游艇租赁公司之一，我们提供最优惠的价格和豪华游艇租赁服务，以日和小时为单位，我们船舶租赁服务专业，团队航海经验丰富，为客户提供最优质的游艇租赁体验及最高安全保障。",
        newsTitle: "迪拜游艇租赁新闻_游艇包租最新消息_游艇活动-天行者游艇",
        newsKey: "迪拜游艇租赁新闻,游艇包租最新消息,游艇活动",
        newsDec:
            "了解天行者游艇公司最新消息和活动，获取最新迪拜游艇租赁价格、预定、航程时间、超级游艇定制服务、水上运动及钓鱼俱乐部信息。",
    },
}
export default lang
