<template>
  <div class="box" v-if="!$store.state.user.cookieTip">
    <div class="title">Cookies on our site</div>
    <p>
      This website uses technical cookies,which are necessary for you to browse
      it and which help us to provide the service.With your consent,we use
      profiling cookies to offer you an increasingly agreeable browsing
      experience,to facilitate interaction with our social-media features,and to
      enable you to receive marketing messages tailored to your browsing habits
      and interests. To accept all profiling cookies,click ACCEPT;to
      decline,click REFUSE.For more information about the cookies we use.
    </p>
    <div class="btn">
      <button @click="REFUSE">REFUSE</button>
      <button @click="ACCEPT">ACCEPT</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    REFUSE() {
      this.$store.commit('user/cookieTip', 'REFUSE');
    },
    ACCEPT() {
      this.$store.commit('user/cookieTip', 'ACCEPT');
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.box {
  background-color: rgb(0, 0, 0, 0.95);
  position: fixed;
  bottom: 0;
  z-index: 999999;
  color: #fff;
  left: 0;
  right: 0;
  width: 100%;
  padding: 40px;
  height: 630px;
  line-height: 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  .title {
    font-size: 48px;
    margin-bottom: 30px;
  }

  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 50px;
    font-size: 33px;
    button {
      margin: 0 6px;
      width: 200px;
      line-height: 50px;
      font-family: Arial, Helvetica, sans-serif;
      cursor: pointer;
      background-color: #000;
      border: 1px solid #fff;
      padding: 5px 0;
    }
    button:hover {
      background-color: #e2dfe0;
    }
  }
}
</style>
