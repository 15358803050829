<template>
  <div>
    <van-popup v-model="show" position="bottom" @click-overlay="unShow" :close-on-click-overlay="false">
      <van-picker
        :columns="columns"
        @confirm="confirm"
        @cancel="cancel"
        :show-toolbar="true"
        :title="title"
        :visible-item-count="5"
        :confirm-button-text="$t('form.ok')"
        :cancel-button-text="$t('form.back')"
        @change="selectChange"
        default-index="0"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: () => {
        return {
          AM: [6, 7, 8, 9, 10, 11, 12],
          PM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        }
      },
    },
    title: {
      type: String,
      default: "title",
    },
    show: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  methods: {
    confirm(value) {
      this.$emit("confirm", value)
      this.unShow()
    },
    cancel() {
      this.unShow()
    },
    unShow() {
      this.$emit("unShow")
    },
    selectChange(e) {
      if (
        Object.keys(this.list)[0] === "AM" ||
        Object.keys(this.list)[0] === "PM" ||
        Object.keys(this.list)[0] === "上午" ||
        Object.keys(this.list)[0] === "下午"
      ) {
        if (e.getIndexes()[1] === 0) {
          e.setColumnValues(0, [6, 7, 8, 9, 10, 11, 12])
        } else {
          e.setColumnValues(0, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.van-popup{
  overflow-y: visible;
}
/deep/.van-picker {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}
/deep/.van-picker__columns {
  justify-content: center;
}
/deep/.van-picker-column {
  min-width: 20vw;
  flex-grow: 0;
  font-family: Arial-BoldMT;
  color: #333333;
}
/deep/.column1 {
  width: 0;
  min-width: 0;
  overflow: visible;
  .van-picker-column__wrapper {
    min-width: 30vw;
    .van-picker-column__item {
      justify-content: flex-start;
    }
  }
}
/deep/.van-picker-column__wrapper {
  min-width: 10vw;
}
/deep/.van-picker__title {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  height: 15vw;
  text-align: center;
  line-height: 10vw;
  min-width: 100vw;
  margin: auto;
  background: #fff;
  z-index: 100;
}
/deep/.van-picker__cancel {
  flex: 1;
  background-color: #020b3b;
  color: #ffcc69;
  font-weight: bold;
}
/deep/.van-picker__confirm {
  flex: 1;
  background-color: #ffcc69;
  color: #333;
  font-weight: bold;
}
/deep/.van-picker__toolbar {
  height: 13vw;
}
</style>
