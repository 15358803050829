<template>
    <div>
        <aboutUs />
        <myHeader />
        <router-view></router-view>
        <myFooter v-if="!footShow" />
        <cookieTip />
    </div>
</template>

<script>
import myHeader from "@/components/myHeader/myHeader.vue"
import myFooter from "@/components/myFooter/myFooter.vue"
import aboutUs from "@/components/aboutUs/aboutUS.vue"
import cookieTip from "@/components/cookieTip/index.vue"
import { getUrlParams } from "@/libs/util"

export default {
    components: {
        myHeader,
        myFooter,
        aboutUs,
        cookieTip,
    },
    data() {
        return {}
    },
    computed: {
        footShow() {
            return this.$route.meta.footNoShow
        },
    },
    created() {
        // if (location.search.split("=")[1] && location.search.indexOf("source") != -1) {
        //     this.$store.state.receptionSave.orderSourceId = location.search.split("=")[1]
        //     sessionStorage.setItem("orderSourceId", location.search.split("=")[1])
        // }

        const query = this.$route.query
        if (query.source) {
            this.$store.state.receptionSave.orderSourceId = query.source
            sessionStorage.setItem("orderSourceId", query.source)
        }
    },
    mounted() {
        //  判断渠道channelId是否存在，存在存入
    },
}
</script>

<style scoped></style>
