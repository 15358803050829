<template>
  <div>
    <div class="headline">
      <div class="left-a"></div>
      <p>NOW ADD THE FUN</p>
      <div class="right-a"></div>
    </div>
    <div class="line">
      <span>YOURS CHARTER</span>
    </div>
    <div class="form">
      <div class="img">
        <img :src="userInfo.pictureUrl" alt="" />
      </div>
      <div class="container">
        <div class="title">{{ userInfo.productName }}</div>
        <div class="explain">
          <div>
            <span>LENGTH</span>
            <span>{{ userInfo.length }} FT</span>
          </div>
          <div>
            <span>CAPACITY</span>
            <span>UP TO {{ userInfo.maxPassenger }} PAX </span>
          </div>
          <div>
            <span>CREW</span>
            <span>{{ userInfo.crewNum }}+{{ userInfo.captainNum }}</span>
          </div>
        </div>
        <div class="inputMsg">
          <div class="date">
            <div class="qz">Departure Date</div>
            <div>
              <input
                type="text"
                readonly
                :value="
                  dayjs(userInfo.date).format('YYYY/MM/DD') +
                  '  ' +
                  this.weekEn[new Date(userInfo.date).getDay()] +
                  '  ' +
                  (userInfo.showTime ? userInfo.showTime : '')
                "
              />
            </div>
          </div>
          <div class="Duration">
            <div class="qz">Duration</div>
            <div>
              <input
                type="text"
                readonly
                :value="userInfo.hour ? userInfo.hour + 'hours' : 'Please select your departure time first'"
              />
            </div>
          </div>
          <div class="Departure-Point">
            <div class="qz">Departure Point</div>
            <div>
              <input type="text" readonly :value="userInfo.berth" />
            </div>
          </div>
          <div class="Adult-Childs">
            <div class="qz">Adult/Childs</div>
            <div>
              <input type="text" readonly v-model="userInfo.adult" />
              <input type="text" readonly v-model="userInfo.child" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line">
      <span>ON BOARD CATERING</span>
    </div>
    <div class="menu">
      <div class="container">
        <div class="title-list">
          <ul>
            <li>MENUS</li>
            <li>DETAILS</li>
            <li>PRICE</li>
            <li>QUANTITY</li>
          </ul>
        </div>
        <div class="msg-list">
          <ul>
            <li
              style="border-bottom: 1px solid #ccc"
              v-for="(item, index) in $store.state.order.yachtsMenu"
              :key="index"
            >
              <div>
                <van-checkbox
                  v-model="item.checked"
                  class="checkbox"
                  checked-color="#ffcc69"
                  icon-size="10px"
                  @change="menusCheckedChange(item.checked, item.number, item.id)"
                ></van-checkbox>
                <span class="photo" @click="showPhoto(item.imageUrl)">
                  <img src="../images/photo.png" alt="" />
                </span>
                <span class="name textHidden">{{ item.menuName }}</span>
              </div>
              <div class="menusDetail">
                {{ item.detailDescribe }}
              </div>
              <div>AED {{ item.price }}<span v-if="item.priceType == 1">pp</span></div>
              <div>
                <div class="selectBox" @click="menusNumChange(item.checked, item.minNumber, item.maxNumber, item.id)">
                  <input type="text" readonly v-model="item.number" />
                  <i class="arrow-down"><van-icon name="arrow-down" /></i>
                </div>
                <!-- <select
                  name=""
                  id=""
                  class="select"
                  style="border: 1px solid #ccc"
                  v-model="item.number"
                  @change="menusNumChange(item.checked, item.number, item.id)"
                >
                  <option
                    :value="i + (item.minNumber ? item.minNumber : 1)"
                    v-for="(v, i) in item.minNumber
                      ? item.maxNumber - item.minNumber + 1
                      : item.maxNumber"
                    :key="i"
                  >
                    {{ item.minNumber ? item.minNumber + i : 1 + i }}
                  </option>
                </select> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="line SPORTS-TOYS">
      <span>WATER SPORTS & YACHT TOYS</span>
    </div>
    <div class="menu">
      <div class="container">
        <div class="title-list">
          <ul>
            <li>WATER SPORTS</li>
            <li>UNIT</li>
            <li>PRICE</li>
            <li>QUANTITY</li>
          </ul>
        </div>
        <div class="msg-list">
          <ul>
            <li
              style="border-bottom: 1px solid #ccc"
              v-for="(item, index) in $store.state.order.waterSports"
              :key="index"
            >
              <div>
                <van-checkbox
                  v-model="item.checked"
                  class="checkbox"
                  checked-color="#ffcc69"
                  icon-size="10px"
                  @change="sportsCheckedChange(item.checked, item.number, item.id)"
                ></van-checkbox>
                <span class="photo" @click="showPhoto(item.imageUrl)">
                  <img src="../images/photo.png" alt="" />
                </span>
                <span class="name textHidden">{{ item.serviceName }}</span>
              </div>
              <div style="padding-left: 0.3rem">{{ item.unit }}</div>
              <div>AED {{ item.price }}</div>
              <div>
                <div
                  class="selectBox"
                  v-if="item.lockNumber == 0"
                  @click="sportsNumChange(item.checked, item.maxNumber, item.id)"
                >
                  <input type="text" readonly v-model="item.number" />
                  <i class="arrow-down"><van-icon name="arrow-down" /></i>
                </div>
                <!-- <select
                  name=""
                  id=""
                  class="select"
                  style="border: 1px solid #ccc"
                  v-model="item.number"
                  @change="sportsNumChange(item.checked, item.number, item.id)"
                  v-if="item.lockNumber == 0"
                >
                  <option
                    :value="index + 1"
                    v-for="(item, index) in item.maxNumber"
                    :key="index"
                  >
                    {{ index + 1 }}
                  </option>
                </select> -->
                <span class="waterSelect" v-else>{{ item.defaultNumber }} Hours</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="menu WATER-SPORTS">
      <div class="container">
        <div class="title-list">
          <ul>
            <li>YACHT TOYS</li>
            <li>UNIT</li>
            <li>PRICE</li>
            <li>DESCRIBE</li>
          </ul>
        </div>
        <div class="msg-list">
          <ul>
            <li
              style="border-bottom: 1px solid #ccc"
              v-for="(item, index) in $store.state.order.yachtsToys"
              :key="index"
            >
              <div>
                <van-checkbox
                  v-model="item.checked"
                  class="checkbox"
                  checked-color="#ffcc69"
                  icon-size="10px"
                  @change="toysCheckedChange(item.checked, item.number, item.id)"
                ></van-checkbox>
                <span class="photo" @click="showPhoto(item.imageUrl)">
                  <img src="../images/photo.png" alt="" />
                </span>
                <span class="name textHidden">{{ item.serviceName }}</span>
              </div>
              <div style="padding-left: 0.3rem">{{ item.unit }}</div>
              <div>AED {{ item.price }}</div>
              <div>
                <!-- <select
                  name=""
                  id=""
                  class="select"
                  style="border: 1px solid #ccc"
                  v-model="item.number"
                  @change="toysNumChange(item.checked, item.number, item.id)"
                  v-if="item.lockNumber == 0"
                >
                  <option
                    :value="index + 1"
                    v-for="(v, index) in item.maxNumber"
                    :key="index"
                  >
                    {{ index + 1 }}
                  </option>
                </select> -->
                <span class="toysSelect toysDetail">{{ item.detailDescribe ? item.detailDescribe : "- - - -" }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="line">
      <span>BOOKING SUMMARY</span>
    </div>
    <div class="BOOKING-SUMMARY">
      <div>
        <span class="qz">NAME</span>
        <div>
          <input type="text" readonly v-model="userInfo.name" />
        </div>
      </div>
      <div class="phone">
        <span class="qz">PHONE</span>
        <div>
          <input type="text" readonly :value="'+ ' + userInfo.qz" />
          <input type="text" readonly v-model="userInfo.phone" />
        </div>
      </div>
      <div>
        <span class="qz">EMAIL</span>
        <div>
          <input type="text" v-model="userInfo.email" />
        </div>
      </div>
      <div class="Adult-Childs">
        <span class="qz">ADULT/CHILDS</span>
        <div>
          <input type="text" readonly v-model="userInfo.adult" />
          <input type="text" readonly v-model="userInfo.child" />
        </div>
      </div>
      <div>
        <span class="qz"> DEPARTURE DATE </span>
        <div>
          <input
            readonly
            type="text"
            :value="dayjs(userInfo.date).format('YYYY/MM/DD') + ' ' + this.weekEn[new Date(userInfo.date).getDay()]"
          />
        </div>
      </div>
      <div>
        <span class="qz"> DEPARTURE TIME </span>
        <div @click="showTime">
          <input
            type="text"
            readonly
            :value="userInfo.showTime ? userInfo.showTime : 'Please select your departure time first'"
          />
        </div>
      </div>
      <div>
        <span class="qz"> DURATION</span>
        <div @click="showHours">
          <input
            type="text"
            readonly
            :value="userInfo.hour ? userInfo.hour + 'hours' : 'Please select the duration first'"
          />
        </div>
      </div>
      <div>
        <span class="qz"> DEPARTURE POINT</span>
        <div>
          <input readonly type="text" :value="userInfo.berth" />
        </div>
      </div>
    </div>
    <div class="total">
      <ul>
        <li>
          <span>Charter</span>
          <span>AED {{ payTotal.charter }}</span>
        </li>
        <li>
          <span>Catering</span>
          <span>AED {{ payTotal.catering }}</span>
        </li>
        <li>
          <span>Extras</span>
          <span>AED {{ payTotal.extras }}</span>
        </li>
        <li>
          <span>TOTAL (inc. 5% VAT)</span>
          <span>AED {{ payTotal.total }}</span>
        </li>
      </ul>
      <div class="agree">
        <div>
          <van-checkbox v-model="serviceChecked" shape="square" icon-size="10px" checked-color="#ffcc69">
            <span class="text"
              >I have read & agree to the
              <span style="color: #ffcc69" @click="serviceShow">Terms of Service and Conditions</span></span
            >
          </van-checkbox>
          <van-checkbox v-model="cancellationChecked" shape="square" icon-size="10px" checked-color="#ffcc69">
            <span class="text"
              >I have read & agree to the
              <span style="color: #ffcc69" @click="cancelShow">Cancellation Policy</span></span
            >
          </van-checkbox>
        </div>
      </div>
      <div class="pay">
        <div>Secure payment powered by</div>
        <div>
          <img src="../images/ssl-cards-telr.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bottom-text" @click="payment">
      <img src="../images/iconB.png" alt="" />
      <p>PROCEED TO CHECKOUT</p>
    </div>
    <van-image-preview v-model="show" :images="images" />
    <!-- 选择持续时间 -->
    <diyPick
      :list="hoursOptions.list"
      :show="hoursOptions.show"
      :title="hoursOptions.title"
      :columns="hoursColumns"
      @unShow="unShow"
      @confirm="hourConfirm"
    />
    <!-- 选择出发时间 -->
    <diyPick
      :list="timeOptions.list"
      :show="timeOptions.show"
      :title="timeOptions.title"
      :columns="timeColumns"
      @unShow="unShow"
      @confirm="timeConfirm"
    />
    <!-- 餐饮选择套餐数量 -->
    <diyPick
      :list="countMenusOptions.list"
      :show="countMenusOptions.show"
      :title="countMenusOptions.title"
      :columns="countMenusColumns"
      @unShow="unShow"
      @confirm="countMenusConfirm"
    />
    <!-- 水上运动选择套餐数量 -->
    <diyPick
      :list="countSportsOptions.list"
      :show="countSportsOptions.show"
      :title="countSportsOptions.title"
      :columns="countSportsColumns"
      @unShow="unShow"
      @confirm="countSportsConfirm"
    />
    <!-- 服务条款 -->
    <van-popup v-model="serviceDialog"
      ><trems></trems> <img src="../images/close.png" alt="" class="close" @click="closeAgree"
    /></van-popup>
    <!-- 取消政策 -->
    <van-popup v-model="cancelDialog">
      <cancel></cancel>
      <img src="../images/close.png" alt="" class="close" @click="closeAgree" />
    </van-popup>
  </div>
</template>

<script>
import bookOrder from "@/mixin/bookOrder/index.js"
import diyPick from "@/components/diyPick/diyPick.vue"
import trems from "../trems.vue"
import cancel from "../cancel.vue"
export default {
  mixins: [bookOrder],
  // destroyed() {
  //   this.$store.commit('removeUserInfo');
  // },
  components: {
    diyPick,
    trems,
    cancel,
  },
}
</script>

<style lang="less" scoped>
.headline {
  background-color: #020b3b;
  height: 64px;
  width: 460px;
  margin: 0 auto;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;

  .left-a {
    width: 64px;
    height: 64px;
    background: linear-gradient(135deg, #fff, #fff 50%, #020b3b 50%, #020b3b 100%);
  }

  .right-a {
    width: 64px;
    height: 64px;
    background: linear-gradient(45deg, #020b3b, #020b3b 50%, #fff 50%, #fff 100%);
  }

  & > p {
    text-align: center;
    line-height: 64px;
    font-family: Arial-BoldMT;
    font-size: 30px;
    color: #e4e4e6;
  }
}

.line {
  width: 710px;
  height: 44px;
  background-color: #020b3b;
  border-radius: 22px 0px 0px 22px;
  margin: 20px auto;
  position: relative;
  padding-left: 60px;
  box-sizing: border-box;

  span {
    line-height: 44px;
    color: #e4e4e6;
    font-size: 26px;
    font-family: Arial-BoldMT;
    font-weight: bold;
    display: 500;
  }
}

.line::before {
  content: "";
  width: 32px;
  height: 32px;
  background-color: #ffcc69;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.form {
  width: 710px;
  margin: 0 auto;
  margin-bottom: 20px;

  .img {
    height: 400px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .container {
    background-color: #ffcc69;
    padding: 0 20px;

    .title {
      text-align: center;
      font-family: Arial-BoldMT;
      font-size: 36px;
      color: #333333;
      padding: 16px 0;
      font-weight: bold;
    }
    .explain {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      & > div {
        background-color: #020b3b;
        font-family: Arial-BoldMT;
        font-size: 24px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80px;
        padding: 10px;
        box-sizing: border-box;
        & > span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          text-align: center;
        }
      }

      & > div:nth-child(1) {
        width: 270px;
      }

      & > div:nth-child(2) {
        width: 220px;
      }

      & > div:nth-child(3) {
        width: 170px;
      }
    }

    .inputMsg {
      & > div {
        display: flex;
        margin-bottom: 18px;

        .qz {
          width: 180px;
          text-align: right;
          line-height: 40px;
          background-color: #020b3b;
          font-family: Arial-BoldMT;
          font-size: 20px;
          color: #fff;
          padding-right: 14px;
          box-sizing: border-box;
          margin-right: 12px;
        }

        & > div:nth-child(2) {
          flex: 1;

          input {
            padding-left: 15px;
            line-height: 40px;
            width: 100%;
          }
        }
      }

      .Adult-Childs {
        & > div:nth-child(2) {
          display: flex;
          justify-content: space-between;

          input {
            width: 49%;
          }
        }
      }
    }
  }
}

.menu {
  width: 710px;
  margin: 0 auto;
  .container {
    .title-list {
      border-bottom: 1px solid #ccc;
      margin-bottom: 15px;
      font-weight: 700;

      ul {
        display: flex;

        & > li {
          font-family: Arial-BoldMT;
          font-size: 24px;
          line-height: 60px;
          color: #333333;
        }

        & > li:nth-child(1) {
          width: 300px;
        }

        & > li:nth-child(2) {
          width: 144px;
        }

        & > li:nth-child(3) {
          width: 130px;
        }

        & > li:nth-child(4) {
          width: 115px;
        }
      }
    }

    .msg-list {
      ul {
        & > li {
          display: flex;

          & > div {
            display: flex;
            align-items: center;
            height: 50px;

            .checkbox {
              .van-icon {
                border: 1px solid #ffcc69 !important;
              }
            }

            .photo {
              width: 22px;
              margin-left: 20px;
              margin-right: 14px;

              img {
                width: 100%;
              }
            }
            .waterSelect {
              font-size: 22px;
              color: #333333;
            }
            .toysSelect {
              font-size: 19px;
              color: #333333;
              white-space: nowrap;
            }
            .toysDetail {
              display: block;
              width: 100px;
              text-align: center;
              margin-left: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .name {
              display: block;
              width: 180px;
              font-family: Arial-ItalicMT;
              font-size: 22px;
              color: #333333;
            }
            .textHidden {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          & > div:nth-child(1) {
            width: 300px;
            font-size: 22px;
            color: #333333;
            font-family: Arial-ItalicMT;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;

            span {
              display: block;

              img {
                display: block;
              }
            }
          }

          & > div:nth-child(2) {
            width: 144px;
            font-family: ArialMT;
            font-size: 20px;
            color: #333333;
            display: block;
            padding-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .menusDetail {
            padding-right: 15px;
          }
          & > div:nth-child(3) {
            width: 130px;
            font-family: Arial-BoldMT;
            font-size: 20px;
            color: #333333;
            font-weight: bold;
            padding-top: 10px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          & > div:nth-child(4) {
            display: flex;
            justify-content: flex-end;
            width: 115px;
            padding-right: 15px;
            box-sizing: border-box;

            .select {
              display: block;
              width: 78px;
              line-height: 28px;
              background-color: transparent;
              font-family: ArialMT;
              font-size: 20px;
              color: #333333;
              outline: none;
            }
            P {
              text-align: center;
              flex: 1;
              font-family: ArialMT;
              font-size: 20px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}

.WATER-SPORTS {
  margin-top: 30px;
}

.BOOKING-SUMMARY {
  background-color: #020b3b;
  width: 710px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 10px;
  & > div {
    display: flex;
    margin-bottom: 10px;
    .qz {
      display: block;
      width: 250px;
      height: 41px;
      background-color: #ffcc69;
      line-height: 41px;
      text-align: right;
      padding-right: 10px;
      box-sizing: border-box;
      font-family: Arial-BoldMT;
      font-size: 24px;
      color: #333333;
      margin-right: 10px;
      font-weight: 500;
    }
    & > div {
      display: flex;
      flex: 1%;
      input {
        width: 100%;
        display: block;
        padding-left: 15px;
      }
    }
  }
  .phone {
    & > div {
      & > input:first-child {
        width: 136px;
        margin-right: 10px;
      }
      & > input:last-child {
        flex: 1;
      }
    }
  }
  .Adult-Childs {
    & > div {
      justify-content: space-between;
      input {
        width: 49%;
      }
    }
  }
}

.total {
  width: 710px;
  margin: 0 auto;
  background-color: #020b3b;
  margin-top: 10px;
  padding: 25px 50px;
  box-sizing: border-box;
  ul {
    li {
      display: flex;
      justify-content: space-between;
      height: 45px;
      align-items: center;
      span {
        font-family: Arial-BoldMT;
        font-size: 20px;
        color: #ffffff;
        font-weight: bold;
      }
    }
    & > li:last-child {
      span {
        color: #ffcc69;
      }
    }
  }
  .agree {
    margin-top: 10px;
    input {
      border: 1px solid #ffcc69;
      width: 20px;
      height: 20px;
      background-color: #fff;
    }
    /deep/.text {
      font-family: ArialMT;
      font-size: 20px;
      color: #ffffff;
      display: block;
    }
    /deep/.van-checkbox__label {
      color: #fff;
    }
    /deep/.van-icon {
      background-color: #fff;
      border: 1px solid #fff;
    }
  }
  .pay {
    display: flex;
    align-items: center;
    font-family: ArialMT;
    font-size: 20px;
    color: #ffffff;
    margin-top: 20px;
    img {
      width: 171px;
      margin-left: 10px;
    }
  }
}

.bottom-text {
  width: 710px;
  height: 90px;
  background-color: #020b3b;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial-BoldMT;
  font-size: 36px;
  color: #ffcc69;
  img {
    width: 36px;
    margin-right: 10px;
  }
}
.close {
  bottom: -100px;
  display: block;
  width: 35px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
/deep/.van-popup {
  overflow: visible;
}
.selectBox {
  width: 78px;
  height: 34px;
  line-height: 34px;
  font-family: ArialMT;
  font-size: 20px;
  color: #333333;
  outline: none;
  position: relative;
  input {
    width: 78px;
    height: 34px;
    border: solid 1px #ccc;
    padding-left: 8px;
  }
  .arrow-down {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 8px;
    background-color: #fff;
    /deep/.van-icon-arrow-down {
      font-weight: 700;
    }
  }
}
</style>
