import { check_userName, check_Mobile, check_Mail, check_FloatNumber, check_Number } from "@/libs/form"
import diyPick from "@/components/diyPick/diyPick.vue"
import diyDateTime from "@/components/diyDateTime/diyDateTime.vue"
import { mapState } from "vuex"
import Empty from "@/components/Empty"
import areaCode from "@/components/myPick"
const sportList = {
    props: ["videos"],
    data() {
        return {
            poup: false,
            //   index2: 3,
            time: "",
            // form
            options: {
                name: "",
                qz: "",
                phone: "",
                adults: "",
                hour: "",
                date: "",
                total: "",
                email: "",
            },
            //DATE
            dateOptions: {
                title: "Please select your date",
                show: false,
                value: "",
                date: new Date(),
            },
            markWord: "",
            product: {},
            ShowTime: "",
            thisTime: "",
            state: true,
            show: {
                areaCode: false,
            },
        }
    },
    created() {
        this.$store.dispatch("getTeachList")
    },
    methods: {
        showPoup(teachId, teachName, index) {
            teachName = teachName.join(" ")
            this.product = { teachId, teachName }
            const minRentTime = this.teach[index].minRentTime
            this.product.minRentTime = minRentTime
            this.poup = true
        },
        back() {
            this.poup = false
            this.options = {
                name: "",
                qz: "",
                phone: "",
                adults: "",
                hour: "",
                date: "",
                total: "",
                email: "",
            }
            this.dateOptions.value = ""
            this.hoursOptions.value = ""
        },
        async commit() {
            let { name, qz, phone, adults, hour, activity, date, total, email } = this.options
            if (name === "" || name.length > 64) {
                return this.$toast(this.$t("form.nameCheck"))
            }
            if (qz === "") {
                return this.$toast(this.$t("form.qzCheck"))
            }
            if (phone == "" || phone.length + qz.length > 20 || phone.length + qz.length < 6 || !check_Number(phone)) {
                return this.$toast(this.$t("form.phoneCheck"))
            }
            if (email === "" || !check_Mail(email)) {
                return this.$toast(this.$t("form.emailCheck"))
            }
            if (adults == "" || !check_FloatNumber(adults)) {
                return this.$toast(this.$t("form.adultsCheck"))
            }
            if (hour === "") {
                return this.$toast(this.$t("form.hoursCheck"))
            }
            if (hour < this.product.minRentTime) {
                return this.$toast(this.$t("form.minTime") + this.product.minRentTime + this.$t("form.h"))
            }
            if (date === "") {
                return this.$toast(this.$t("form.dateCheck"))
            }
            // 创建教学订单
            let params = {
                teachId: this.product.teachId,
                realName: name,
                mobileNum: qz + "-" + phone,
                peoples: adults,
                teachTime: date + ":00:00",
                teachHours: hour,
                email: email,
            }
            if (this.state) {
                this.state = false
                try {
                    await this.$store.dispatch("addTeachOrder", params)
                    this.state = true
                } catch (error) {
                    this.state = true
                }
            }
            //   this.poup = false;
        },
        // 发送计算金额请求
        async teachOrderTotal() {
            try {
                let params = {
                    teachId: this.product.teachId,
                    peoples: this.options.adults ? +this.options.adults : 0,
                    teachHours: this.options.hour ? this.options.hour : this.product.minRentTime,
                    teachTime:
                        this.options.date === "" ? "" : this.dayjs(this.options.date).format("YYYY-MM-DD HH:mm:ss"),
                }
                await this.$store.dispatch("calcTeachOrder", params)
                this.options.total = this.$store.state.teach.teachOrderTotal
            } catch (error) {
                this.options.total = 0
            }
        },
        // 选择时间
        showDate() {
            this.dateOptions.show = true
        },
        showHours() {
            this.hoursOptions.show = true
        },
        unShow() {
            // this.personOptions.show = false
            // this.timeOptions.show = false
            this.hoursOptions.show = false
            this.dateOptions.show = false
        },
        showTime() {
            this.timeOptions.show = true
        },
        dateConfirm(value) {
            if (this.$i18n.locale == "zh-EN") {
                this.thisTime = this.dayjs(value).format("YYYY-MM-DD HH")
                this.ShowTime =
                    +this.thisTime.split(" ")[1] > 12
                        ? +this.thisTime.split(" ")[1] - 12 + "PM"
                        : this.thisTime.split(" ")[1] + "AM"
            } else {
                this.thisTime = this.dayjs(value).format("YYYY-MM-DD HH")
                this.ShowTime =
                    +this.thisTime.split(" ")[1] > 12
                        ? "下午" + (+this.thisTime.split(" ")[1] - 12) + "点"
                        : "上午" + this.thisTime.split(" ")[1] + "点"
            }
            this.$set(this.dateOptions, "value", value)
            this.options.date = this.dayjs(value).format("YYYY-MM-DD HH")
        },
        hourConfirm(value) {
            let text = value[0] + " " + value[1]
            this.$set(this.hoursOptions, "value", text)
            this.options.hour = value[0]
        },
        // 国际代码弹窗
        showAreaCode() {
            this.$set(this.show, "areaCode", true)
        },
        // 国际代码关闭
        closeAreaCode() {
            this.$set(this.show, "areaCode", false)
        },
        areaCodeConfirm(value) {
            this.$set(this.options, "qz", value.phone_code)
        },
    },
    // 侦听人数变化计算金额
    watch: {
        "options.adults": {
            handler() {
                this.teachOrderTotal()
            },
        },
        "options.hour": {
            handler() {
                this.teachOrderTotal()
            },
        },
        "options.date": {
            handler() {
                this.teachOrderTotal()
            },
        },
        poup: {
            handler() {
                this.$emit("poupHidden", this.poup)
            },
        },
    },
    components: {
        diyPick,
        diyDateTime,
        Empty,
        areaCode,
    },
    computed: {
        ...mapState({
            teach: state => state.teach.teach,
        }),
    },
    destroyed() {
        if (this.$store.state.teach.teachOrder.result) {
            this.options = {
                name: "",
                qz: "",
                phone: "",
                adults: "",
                hour: "",
                date: "",
                total: "",
                email: "",
            }
            this.dateOptions.value = ""
            this.hoursOptions.value = ""
            this.$toast.clear()
        }
    },
}
export default sportList
