import axios from "axios"
import md5 from "js-md5"
import i18n from "@/vueI18n"
import Vue from "vue"
import { Toast } from "vant"
import { getLocal } from "@/libs/storage"

import config from "@/api/config"
import { failMsg } from "@/libs/util"

Vue.use(Toast)
// const Toast = Vue.prototype.$toast
// const baseURL = '/api/v1';
// const baseURL = 'http://yachts.hub.diyiqiangren.com/api/v1';
// var Params = {
//   appId: '504ca34700e711ecb2500af10e715ce6',
//   // appId: "cc6e794dba4211ec819906af808612df",
//   appKey: '61ec617f00e711ecb2500af10e715ce6',
// };
const axiosServe = axios.create({
    baseURL: config.baseUrl,
    timeout: config.timeout,
})
// 添加请求拦截器
axiosServe.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
axiosServe.interceptors.response.use(
    // 成功
    function (response) {
        // 对响应数据做点什么
        if (response.data.result) {
            return response.data
        } else {
            return Promise.reject(response.data.msg)
        }
    },
    // 失败
    function (error) {
        const status = (error.response && error.response.status && error.response.status) || ""
        if (error.code == "ECONNABORTED" && error.message.indexOf("timeout") != -1) {
            return Promise.reject(config.errMsg["timeout"])
        }
        if (error.code == "ECONNABORTED" && error.message.indexOf("Request aborted") != -1) {
            return console.log("Request aborted")
        }
        if (status === 404) {
            failMsg(config.errMsg["404"])
            return Promise.reject(config.errMsg["404"])
        }

        if (status === 500) {
            failMsg(config.errMsg["500"])
            return Promise.reject(config.errMsg["500"])
        }
        failMsg(config.errMsg["UnKnown"])
        return new Promise.reject(config.errMsg["UnKnown"])
    }
)

/*
 * 获取随机数32位
 * */
function getrandom() {
    let chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
    ]
    let nums = ""
    for (let i = 0; i < 32; i++) {
        let id = parseInt(Math.random() * 61)
        nums += chars[id]
    }
    return nums
}
function getStorage(name) {
    if (!name) return
    return window.localStorage.getItem(name) || ""
}
function setStorage(name, content) {
    if (!name) return
    if (typeof content !== "string") {
        content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
}

function objKeySort(obj) {
    //排序的函数
    let newkey = Object.keys(obj).sort()
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    let newObj = {} //创建一个新的对象，用于存放排好序的键值对
    for (let i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        newObj[newkey[i]] = obj[newkey[i]] //向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj //返回排好序的新对象
}

const request = options => {
    if (!options.data) {
        options.data = {}
    }

    var sign = ""
    let requestId = getrandom() // 随机数获取 requestId
    let appId = config.appId
    let appKey = config.appKey
    getStorage("_udid") ? getStorage("_udid") : setStorage("_udid", getrandom())
    let udid = getStorage("_udid") // 随机数获取 udid
    options.data.requestId = requestId
    options.data.udid = udid
    options.data.appId = appId
    options.data.userId = getLocal("userId") == null ? "" : getLocal("userId")
    options.data.source = sessionStorage.getItem("orderSourceId") ? sessionStorage.getItem("orderSourceId") : ""
    if (i18n.locale == "zh-CH") {
        options.data.languageType = 1
    } else if (i18n.locale == "zh-EN") {
        options.data.languageType = 2
    }
    // 在生成签名(sign)之前对需要提交的参数进行一次排序
    options.data = objKeySort(options.data)
    if (typeof options.data == "object") {
        var str = ""
        //拼接字符串
        for (var key in options.data) {
            if (options.data[key] !== "") {
                str += key + "=" + options.data[key] + "&"
            }
        }
        //md5加密
        // console.log(str);
        if (str !== "") {
            str = str + "appKey=" + appKey
            //md5加密 获取验签
            sign = md5(str)
        }
        options.data.sign = sign
    }
    return axiosServe(options)
}

export default request
