<template>
    <div>
        <homeBanner />
        <homeSelect />
        <cardList />
    </div>
</template>

<script>
import homeBanner from "./homeBanner/homeBanner.vue"
import homeSelect from "./homeSelect/homeSlect.vue"
import cardList from "./cardList/cardList.vue"
import { inserSeoElement } from "@/libs/util"
export default {
    data() {
        return {}
    },
    components: {
        homeBanner,
        homeSelect,
        cardList,
    },
    mounted() {
        inserSeoElement(
            this.$t("headSeo.charterTitle"),
            this.$t("headSeo.charterKey"),
            this.$t("headSeo.charterDec")
        )
    },
}
</script>

<style lang="less" scoped></style>
