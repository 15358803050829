

/**
 * /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,14}$/ //数字和字母组合
 * 验证用户名是否合法
 * @param val
 * @returns {boolean}
 */
export const check_userName = (val) => {
    let telreg = /^[0-9A-Za-z]{1,64}$/;
    if (!telreg.test(val)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 验证手机号格式
 * @param phone
 * @returns {boolean}
 */
export const check_Mobile = (phone) => {
    let telreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    phone = phone.toString().replace(/\s*/g, "");
    if (!telreg.test(phone)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 验证短信验证码格式
 * @param smsCode
 * @returns {boolean}
 */
export const check_smsCode = (smsCode) => {
    let telreg = /^[0-9]{6}$/;
    if (!telreg.test(smsCode)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 验证中文真实姓名
 * @param val
 * @returns {boolean}
 */
export const check_realName = (val) => {
    let reg = /^[\u4e00-\u9fa5]{2,}$/;
    if (reg.test(val)) {
        return true;
    } else {
        return false;
    }
};

// 最小时间，不能大于，最大时间
export const check_time = (min,max) =>{
    let minDate = new Date(min).getTime()
    let maxDate = new Date(max).getTime()
    console.log('minDate',minDate);
    console.log('maxDate',maxDate);
    console.log(minDate > maxDate);
    if(minDate > maxDate){
        return false
    }else{
        return true
    }
}

/**
 * 验证邮箱格式
 * @param mail
 * @returns {boolean}
 */
export const check_Mail = (mail) => {
    let telreg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    if (!telreg.test(mail)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 判断是否为空
 * @param Content
 * @returns {boolean}
 */
export const checkAllNull = (Content) => {
    let reg = /^[\s]+$/;
    if (reg.test(Content)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 判断是否有空格
 * @param Content
 * @returns {boolean}
 */
export const checkNull = (Content) => {
    let reg = /[\s]+/;
    if (reg.test(Content)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 判断是否是纯数字
 * @param val
 * @returns {boolean}
 */
export const check_Number = (num) => {
    let reg = /^[0-9]+$/;
    if (!reg.test(num)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 判断是否是数字或者带小数的数字
 * @param val
 * @returns {boolean}
 */
 export const check_FloatNumber = (num) => {
    let reg = /^[1-9]\d{0,}\.{0,1}\d{0,}$|^[0]\.\d{0,}[1-9]$/
    if (!reg.test(num)) {
        return false;
    } else {
        return true;
    }
};

/**
 * 验证密码格式 6-12 数字加字母组合
 * @param password
 * @returns {boolean}
 */
export const check_pwd = (password) => {
    let pwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
    if (!pwd.test(password)) {
        return false;
    } else {
        return true;
    }
};

/**
 * 验证输入格式 是否为正整数
 * @param password
 * @returns {boolean}
 */
export const check_positiveInt = (num) => {
    let regu = /^[1-9]\d*$/;
    if (!regu.test(num)) {
        return false;
    } else {
        return true;
    }
};