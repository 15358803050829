import { mapState } from "vuex"
import { inserSeoElement } from "@/libs/util"
const waterIndex = {
    data() {
        return {
            currentDate: new Date(),
            time: "",
            swiperOption: {
                spaceBetween: 30,
                effect: "fade",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    bulletClass: "my-bullet", //需设置.my-bullet样式
                    bulletActiveClass: "my-bullet-active",
                },
                bulletClass: "my-bullet",
            },
            poup: false,
            videos: [
                {
                    path: "@/assets/video1.png",
                    show: false,
                },
            ],
        }
    },
    methods: {
        poupHidden(poup) {
            this.poup = poup
            console.log(this.poup)
        },
        play(index) {
            console.log(index)
            this.videos[index].show = true
            this.$nextTick(() => {
                let videos = this.$refs.videos
                console.log(videos)
                videos[index].play()
                videos.forEach(item => {
                    item.addEventListener("ended", () => {
                        this.videos[index].show = false
                        this.videos[index].load()
                    })
                })
            })
        },
        requirePath() {
            let paths = ["@/assets/video1.png", "@/assets/video2.png", "@/assets/video3.png"]
        },
    },
    computed: {
        ...mapState({
            menuShow: state => state.menu.show,
            langShow: state => state.menu.langShow,
        }),
    },
    watch: {
        menuShow: {
            handler() {
                if (this.$refs.videos) {
                    // 侧边栏展开的时候
                    if (this.menuShow) {
                        this.$refs.videos.forEach((item, index) => {
                            this.videos[index].show = false
                            item.load()
                        })
                    } else {
                        this.$refs.videos.forEach((item, index) => {
                            this.videos[index].show = false
                        })
                    }
                }
            },
        },
        poup: {
            handler() {
                if (this.$refs.videos) {
                    if (this.poup) {
                        this.$refs.videos.forEach((item, index) => {
                            this.videos[index].show = false
                            item.load()
                        })
                    } else {
                        this.$refs.videos.forEach((item, index) => {
                            this.videos[index].show = false
                        })
                    }
                }
            },
        },
        langShow: {
            handler() {
                if (this.$refs.videos) {
                    // 侧边栏展开的时候
                    if (this.langShow) {
                        this.$refs.videos.forEach((item, index) => {
                            this.videos[index].show = false
                            item.load()
                        })
                    } else {
                        this.$refs.videos.forEach((item, index) => {
                            this.videos[index].show = false
                        })
                    }
                }
            },
        },
    },
    mounted() {
        inserSeoElement(
            this.$t("headSeo.teachTitle"),
            this.$t("headSeo.teachKey"),
            this.$t("headSeo.teachDec")
        )
    },
}
export default waterIndex
