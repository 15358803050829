const lang = {
    header: {
        version: "Version",
        aboutUs: "About us",
        englist: "English",
        chinese: "中文",
        callUs: "Call us:",
    },
    noData: '"Temporarily no data"',
    navList: {
        home: "YACHT CHARTER",
        yachtTours: "YACHT TOURS",
        superYachts: "SUPER YACHTS",
        waterSports: "WATER SPORTS",
        news: "NEWS",
        contact: "CONTACT",
        team: "TEAM",
    },
    form: {
        success: "success",
        fail: "fail",
        h: "hours",
        ok: "OK",
        back: "BACK",
        nameCheck: "The user name is empty or contains more than 64 characters",
        phoneCheck: "Area code + mobile phone number 6-20 digits",
        adultsCheck: "The number of adults must be numeric and not blank",
        childrenCheck: "The number of children must be numeric and not blank",
        endDateCheck: "The end time cannot be empty",
        benginDate: "The start time cannot be empty",
        dateCheck: "The date cannot be empty",
        timeCheck: "Travel time cannot be empty",
        hoursCheck: "Time cannot be empty",
        qzCheck: "The area code must be numeric and cannot be empty",
        emailCheck: "The email format is incorrect",
        peoplesCheck: "More people than the yacht can hold",
        maxPeople: "Passengers exceed the maximum capacity",
        timeBar: "The start time cannot exceed the end time",
        minTime: "minimum time:",
        mustAdultCheck: "There must be at least one adult",
        messageBoard: "The message cannot be empty",
    },
    bookOrder: {
        enterToast: "The departure time or duration is not specified",
        agree: "Please agree to the Terms of Service and Revocation policy",
        error: "The end time must be before 12 PM",
        cancelTitle: "Cancellation Policy",
        serviceTitle: "Terms of Service and Conditions",
    },
    team: {
        teams_post1: "Managing Director",
        teams_post2: "Office Manager",
        teams_post3: "Sales Manager",
        teams_post4: "Assistant Manager - Operation",
        teams_post5: "Assistant Manager - Maintenance",
        teams_post6: "Sales Manager",
        teams_post7: "B. D. Manager",
        teams_post8: "H. R. Officer",
        teams_post9: "Admin Officer",
        teams_post10: "Digital Marketing Specialist",
        teams_post11: "Graphic Designer",
        teams_post12: "Accountant",
        teams_post13: "Accountant",
        teams_post14: "Accountant",
        teams_post15: "B. D. Executive",
        teams_post16: "Sales Executive",
        teams_post17: "Sales Executive",
        teams_post18: "Sales Executive",
        teams_post19: "Sales Executive",
        teams_post20: "Tourism Manager",
        teams_post21: "Sales Executive",
        teams_post22: "Sales Executive",
        teams_post23: "Customer Service Representative",
        teams_post24: "Jetty Coordinator",
        teams_post25: "Front Desk Support",
        teams_post26: "Company Driver",
        teams_post27: "Company Driver",
        teams_post28: "Company Driver",

        captain_post1: "Yacht Captain",
        captain_post2: "Yacht Captain",
        captain_post3: "Yacht Captain",
        captain_post4: "Yacht Captain",
        captain_post5: "Yacht Captain",
        captain_post6: "Yacht Captain",
        captain_post7: "Yacht Captain",
        captain_post8: "Yacht Captain",
        captain_post9: "Yacht Captain",
        captain_post10: "Yacht Captain",
        captain_post11: "Yacht Steward",
        captain_post12: "Yacht Steward",
        captain_post13: "Yacht Steward",
        captain_post14: "Yacht Steward",
        captain_post15: "Motor Boat Instructor",
        captain_post16: "Yacht Steward",
        captain_post17: "Yacht Steward",
        captain_post18: "Yacht Steward",
        captain_post19: "Yacht Steward",
        captain_post20: "Yacht Steward",
        captain_post21: "Yacht Steward",
        captain_post22: "Yacht Steward",
        captain_post23: "Jet Ski Captain",
        captain_post24: "Yacht Steward",
        captain_post25: "Yacht Steward",
        captain_post26: "Yacht Steward",
        captain_post27: "Yacht Steward",
        captain_post28: "Yacht Steward",
        captain_post29: "Yacht Steward",
        captain_post30: "Yacht Steward",
        captain_post31: "Yacht Steward",
        captain_post32: "Yacht Steward",
        captain_post33: "Yacht Steward",
        repair_post1: "Carpenter",
        repair_post2: "The Engineer",
        repair_post3: "Electrical Engineer",
        repair_post4: "Painter",
        repair_post5: "Electrical Engineer",
        repair_post6: "The Engineer",
        repair_post7: "Kitchen Helper",
    },
    question: {
        tour_question1: "Where does the yacht depart from ?",
        tour_question2: "Is swimming allowed ?",
        tour_question3: "Is there a specific dress code ?",
        tour_question4: "What time should we arrive on the day of the cruise ?",
        tour_question5: "Can we wear shoes on board ?",
        tour_question6: "What if I am late ?",
        tour_question7: "What is the cancellation policy ?",
        tour_question8: "Will there be options to stop the cruise for pictures ?",
        tour_question9: "Can I bring in food & snacks ?",
        tour_question10: "Can we bring our own alcohol ?",

        tour_answer1: "We depart from Dubai Harbour.",
        tour_answer2: "No.",
        tour_answer3: "No,there is not.We suggest you wear something that suits the weather.",
        tour_answer4: "Please arrive 20-30 mins before the departure time.",
        tour_answer5:
            "For the safety of the passengers and to keep the yacht in pristine conditions we require everyone to take their shoes off.",
        tour_answer6: "The boat will depart at the departure time.No refunds will be provided.",
        tour_answer7:
            "Free cancellation upto 24 hours before departure.kindly note refunds can take up to 14 days to be reflected in your account.",
        tour_answer8:
            "The Captain will allow you to take pictures and entertain your requests without having to compromise on the cruise schedule.",
        tour_answer9:
            "Food and beverages will be served on board.The BBQ will include meat,chicken and vegetarian options with side salad dishes and bread.",
        tour_answer10: "Alcohol is strictly prohibited on board.",
    },
    waterQuestion: {
        surf_question1: "Do I need to bring equipment ?",
        surf_question2: "Is wake surfing hard ?",
        surf_question3: "It's my first time. Will someone show me how to do it ?",
        surf_question4: "I am a beginner at both. What should I start with ?",
        surf_question5: "Is wake boarding better than wake surfing ?",
        surf_question6: "What else is included ?",
        surf_question7: "Can we bring cold snacks ?",
        surf_answer1:
            "We have the wake/surf boards and liffe jackets,just let us know in advance your preferred activity.",
        surf_answer2: "If you find it hard,you'll still have a blast trying!",
        surf_answer3:
            "A qualified instructor will be on the trip to guide you through the process.",
        surf_answer4:
            "A tough one to answer,why not extend your time and try both to see what fits!",
        surf_answer5: "Surfers like surfing.Boarders like boarding.What are you?",
        surf_answer6:
            "We will provide all sporting and safety equipments in addition to soft drinks and water.",
        surf_answer7: "Sure.",
    },
    title: "SKY WALKER",
    payType: {
        success: "Payment successful!",
        fail: "Payment failed!",
        ok: "OK",
        back: "BACK",
        again: "PAY AGAIN",
    },
    headSeo: {
        indexTitle: "Yacht Rental Dubai - Luxury Yacht Charter Dubai - Sky Walker",
        indexKey:
            "yacht rental Dubai,luxury yacht charter Dubai,Yacht Dubai,boat hire in Dubai,Dubai yacht charter,Dubai yacht rental,yacht charters in Dubai,yacht rental dubai party,Dubai yacht rent,yacht charter dubai price,Dubai yacht tour",
        indexDec:
            "Book the most popular yachts & boats for rent in Dubai with sky walker. The cheapest price and the best safety guarantee! More enjoyable Dubai yacht tour.",
        charterTitle: "Yacht Charter Dubai - Luxury Yacht Rental Dubai",
        charterKey:
            "yacht charter dubai,luxury yacht rental dubai,best yacht rental dubai,boat hire dubai,yacht charter in dubai,dubai cruise,cruise in dubai,cruising in dubai,sport boat hire",
        charterDec:
            "The most popular yacht and boat rental company in Dubai.We renting services with different capacity from 8 till 50 people.A variety of yachts to choose from.Enjoy a day at sea with friends and family.",
        superTitle: "Super Yacht Rental Dubai - Bespoke Superyacht Charter Dubai - Sky Walker",
        superKey:
            "super yacht rental dubai,bespoke superyacht charter Dubai,custom yacht charter,superyacht charter dubai price,",
        superDec:
            "Bespoke Superyacht Charters can provide the experience of a private luxury yacht charter tailored to suit your needs and wants. Sky Walker custom luxury yacht charters.",
        shareTitle: "Yacht Share Tours in Dubai,Dubai Marina Yacht Shared Tour",
        shareKey:
            "Yacht Share Tours in Dubai,shared yacht dubai,yacht share tour dubai,sharing yacht dubai,Dubai Marina Yacht Shared Tour",
        shareDec:
            "See Dubai's brightest sights from the water and meet like-minded people on this luxury shared tour.We offer pastries, juices or BBQ.Daily cruise timings from 8:30AM-7:30PM.",
        teachTitle: " Best Water Sports In Dubai:Wake Boarding&surfing,Jet Ski Rental",
        teachKey:
            " best water sports in dubai,wake Boarding dubai,wake surfing dubai,jet ski rental dubai,water sports dubai,water sports in dubai",
        teachDec:
            "Enjoy the best watersports in Dubai ,Jetski,wake Boarding,wake surfing,Waterskiing,luxury yacht tour,wave boat and much more,Open every day.We offer best prices, great towboats, best service.",
        fishTitle: "Dubai Fishing Clubs  | Fishing Charter Dubai - Sky Walker",
        fishKey:
            "fishing clubs dubai, fishing charter dubai, fishing in dubai, bass fishing clubs, fishing in the uae, deep sea fishing dubai, fishing spots in dubai, fishing tours dubai, fishing trips dubai",
        fishDec:
            "There are different styles of fishing and each one uses a different type of equipment and fishing clubs in Dubai will help you learn everything you need to catch a fish. View all Fishing Clubs with details at Sky Walker.",
        teamTitle: "Dubai's favourite yacht charter company - Sky Walker",
        teamKey:
            "Dubai's favourite yacht charter company,The most popular yacht charter company in Dubai",
        teamDec:
            "Skywalker Yachts is one of the most popular yacht charter companies in Dubai. We offer the best rates and luxury yacht charter services on a daily and hourly basis.",
        newsTitle: "Yacht charter Dubai news and events",
        newsKey: "yacht charter dubai news, yacht rental dubai news, yacht events dubai",
        newsDec:
            "Check out the latest news and events at Sky Walker. With our own diving school, boat club, private and group tours, there is something for everyone.",
    },
}

export default lang
