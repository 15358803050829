import { check_userName, check_Mobile, check_Mail, check_FloatNumber, check_Number } from "@/libs/form"
import diyPick from "@/components/diyPick/diyPick.vue"
import diyDateTime from "@/components/diyDateTime/diyDateTime.vue"
import areaCode from "@/components/myPick"
import { mapState } from "vuex"
import Empty from "@/components/Empty"
import { ImagePreview } from "vant"

const cardList = {
    name: "swiper-example-fade-effect",
    title: "Fade effect",
    data() {
        return {
            // swiperOption: {
            //     spaceBetween: 30,
            //     effect: "fade",
            //     pagination: {
            //         el: ".swiper-pagination",
            //         clickable: true,
            //     },
            // },
            swiperOption: {
                spaceBetween: 30,
                effect: "fade",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    bulletActiveClass: "my-bullet-active",
                    bulletClass: "my-bullet", //需设置.my-bullet样式
                },
                autoplay: {
                    delay: 3000,
                },
            },
            poup: false,
            currentDate: new Date(),
            time: "",
            // from
            qz: "",
            date: "",
            time: "",
            adults: "",
            children: "",
            name: "",
            phone: "",
            email: "",
            total: 0,
            show: true,
            product: {},
            date: "",
            startTime: "",
            endTime: "",
            payTotal: {},
            maxNum: "",
            state: true,
            show: {
                areaCode: false,
            },
        }
    },
    methods: {
        showPoup(product) {
            this.product = product
            this.startTime = product.startTime
            this.endTime = product.endTime
            this.date = product.rentDate
            this.poup = true
            this.maxNum = product.maxPassenger
        },
        back() {
            this.poup = false
            this.qz = this.adults = this.children = this.name = this.phone = this.email = this.total = ""
        },
        async commit() {
            let { adults, children, name, phone, email, qz, beginTime, endTime } = this
            if (adults === "" || adults == 0 || !check_Number(adults)) {
                return this.$toast(this.$t("form.adultsCheck"))
            }
            if (children === "") {
                children = 0
                this.children = 0
            }
            if (+adults + +children > this.maxNum) {
                console.log("------", this.maxNum)
                return this.$toast(this.$t("form.peoplesCheck") + " " + this.maxNum)
            }
            if (!check_Number(children)) {
                return this.$toast(this.$t("form.childrenCheck"))
            }
            if (name === "" || name.length > 64) {
                return this.$toast(this.$t("form.nameCheck"))
            }
            if (qz === "") {
                return this.$toast(this.$t("form.qzCheck"))
            }
            if (phone == "" || phone.length + qz.length > 20 || phone.length + qz.length < 6 || !check_Number(phone)) {
                return this.$toast(this.$t("form.phoneCheck"))
            }
            if (email === "" || !check_Mail(email)) {
                return this.$toast(this.$t("form.emailCheck"))
            }
            // 乘客名字（memberName）：Unknown Name
            // 状态（memberState）：0
            // 类型（memberType）：根据生成的数据的类型决定

            // 发送创建订单请求
            let orderMemberInfoCmds = []
            for (let i = 1; i <= +adults; i++) {
                orderMemberInfoCmds.push({
                    memberName: "Unknown Name",
                    memberType: 1,
                    memberState: 0,
                })
            }

            for (let i = 1; i <= +children; i++) {
                orderMemberInfoCmds.push({
                    memberName: "Unknown Name",
                    memberType: 2,
                    memberState: 0,
                })
            }

            let params = {
                productId: this.product.productId,
                realName: this.name,
                mobileNum: this.qz + "-" + this.phone,
                email: this.email,
                adultNum: +this.adults,
                childNum: +this.children,
                takeOrderType: this.product.takeOrderType,
                rentType: this.product.rentType,
                rentDate: this.date,
                rentTimeDetailId: this.product.rentTimeDetailId,
                orderMemberInfoCmds: JSON.stringify(orderMemberInfoCmds),
            }
            try {
                if (this.state) {
                    this.state = false
                    try {
                        await this.$store.dispatch("addYachtsOrder", params)
                        this.state = true
                    } catch (error) {
                        this.state = true
                    }
                }
            } catch (error) {}
        },
        // 发送计算金额请求
        async calcYachtOrderTotal() {
            let { beginTime, endTime } = this
            let params = {
                productId: this.product.productId,
                adultNum: this.adults ? +this.adults : 0,
                childNum: this.children ? +this.children : 0,
                takeOrderType: this.product.takeOrderType,
                rentType: this.product.rentType,
                rentDate: this.date,
                rentTimeDetailId: this.product.rentTimeDetailId,
            }
            await this.$store.dispatch("calcYachtOrderTotal", params)
            this.total = this.$store.state.order.calcYachtOrderTotal.total
        },
        // 国际代码弹窗
        showAreaCode() {
            this.$set(this.show, "areaCode", true)
        },
        // 国际代码关闭
        closeAreaCode() {
            this.$set(this.show, "areaCode", false)
        },
        areaCodeConfirm(value) {
            this.qz = value.phone_code
        },
        showImage(arr, index) {
            ImagePreview({
                images: arr,
                startPosition: index,
            })
        },
    },

    // 选择时间
    // showDate() {
    //     this.dateOptions.show = true;
    // },
    // unShow() {
    //     // this.personOptions.show = false
    //     this.timeOptions.show = false;
    //     // this.hoursOptions.show = false
    //     this.dateOptions.show = false;
    // },
    // showTime() {
    //     this.timeOptions.show = true;
    // },
    // dateConfirm(value) {
    //     this.$set(this.dateOptions, 'value', value);
    //     this.$store.commit('getUserOrderInfo', {
    //         ...this.$store.state.home.userOrderInfo,
    //         date: this.dayjs(value).format('YYYY/MM/DD'),
    //     });
    //     this.date = this.dayjs(value).format('YYYY/MM/DD');
    // },
    // timeConfirm(value) {
    //     let text = value[0] + ' ' + value[1];
    //     this.$set(this.timeOptions, 'value', text);
    //     this.$store.commit('getUserOrderInfo', {
    //         ...this.$store.state.home.userOrderInfo,
    //         time: value[0],
    //     });
    //     this.time = text;
    // },
    watch: {
        adults: {
            handler() {
                this.calcYachtOrderTotal()
            },
        },
        children: {
            handler() {
                this.calcYachtOrderTotal()
            },
        },
    },
    computed: {
        ...mapState({
            list: state => state.yachtTours.list,
        }),
    },
    components: {
        diyPick,
        diyDateTime,
        Empty,
        areaCode,
    },
    destoryed() {
        this.qz = this.adults = this.children = this.name = this.phone = this.email = this.total = ""
    },
}

// 侦听人数变化计算金额

export default cardList
