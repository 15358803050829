<template>
  <div>
    <div class="select-list">
      <div @click="showDate">
        <p>
          {{
            dateOptions.value === ''
              ? 'Select Date'
              : dayjs(dateOptions.value).format('YYYY/MM/DD')
          }}
        </p>
        <p></p>
      </div>
      <div @click="showHours">
        <p>
          {{ hoursOptions.value === '' ? 'Charter Hours' : hoursOptions.value }}
        </p>
        <p></p>
      </div>
      <div @click="showTime">
        <p>
          {{ timeOptions.value === '' ? 'Charter Time' : timeOptions.value }}
        </p>
        <p></p>
      </div>
      <div @click="showPerson">
        <p>
          {{
            personOptions.value === ''
              ? 'Number Of Guests'
              : personOptions.value + ' PERSON'
          }}
        </p>
        <p></p>
      </div>
    </div>
    <diyPick
      :list="personOptions.personList"
      :show="personOptions.show"
      :title="personOptions.title"
      :columns="personColumns"
      @unShow="unShow"
      @confirm="personConfirm"
    />
    <diyPick
      :list="timeOptions.list"
      :show="timeOptions.show"
      :title="timeOptions.title"
      :columns="timeColumns"
      @timeChange="timeChange"
      @unShow="unShow"
      @confirm="timeConfirm"
    />
    <diyPick
      :list="hoursOptions.list"
      :show="hoursOptions.show"
      :title="hoursOptions.title"
      :columns="hoursColumns"
      @unShow="unShow"
      @confirm="hourConfirm"
    />
    <diyDateTime
      :title="dateOptions.title"
      @unShow="unShow"
      :show="dateOptions.show"
      @confirm="dateConfirm"
    />
  </div>
</template>

<script>
import yachtSelect from '@/mixin/home/yachtSelect.js';
export default {
  mixins: [yachtSelect],
};
</script>

<style lang="less" scoped>
.select-list {
  display: flex;
  justify-content: space-between;
  margin: 0 34px;
  border-bottom: 2px solid #666;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 82px;

    & > p:first-child {
      font-family: ArialMT;
      font-size: 22px;
      color: #666666;
    }

    & > p:last-child {
      width: 12px;
      height: 12px;
      border-bottom: 3px solid #666;
      border-right: 3px solid #666;
      transform: rotate(45deg);
      margin-top: 6px;
    }
  }
  & > div:nth-child(1) {
    width: 120px;
  }
  & > div:nth-child(2) {
    width: 150px;
  }
  & > div:nth-child(3) {
    width: 140px;
  }
  & > div:nth-child(4) {
    width: 200px;
  }
}
</style>
