<template>
    <div>
        <div class="headline">
            <div class="left-a"></div>
            <p>  <slot></slot>  </p>
            <div class="right-a"></div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less">
    .headline{
        background-color: #020B3B;
        height: 64px;
        width: 700px;
        margin: 0 auto;
        margin-bottom: 22px;
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        .left-a{
            width: 64px;
            height: 64px;
            background: linear-gradient(135deg, #fff, #fff 50%, #020B3B 50%, #020B3B 100%);
        }
        .right-a{
            width: 64px;
            height: 64px;
            background: linear-gradient(45deg, #020B3B, #020B3B 50%, #fff 50%, #fff 100%);
        }
        &>p{
            text-align: center;
            line-height: 64px;
            font-family: Arial-BoldMT;
            font-size: 30px;
            color: #e4e4e6;
            font-weight: bold;
        }
    }
</style>